import { useGLTF } from "@react-three/drei";
import { assets } from "./Assets";
import * as THREE from "three";
import { Mesh, ShaderMaterial, Vector2 } from "three";
import { GroupProps, useFrame } from "@react-three/fiber";
import useLoadTexture from "./UseLoadTexture";
import { useMemo, useRef } from "react";
import useDarkMode from "../stores/useDarkMode";
import scrollFrag from "../shaders/scroll-frag";
import scrollVert from "../shaders/scroll-vert";
import { useControls } from "leva";

type CampGroundProps = GroupProps & {
    immobile?: boolean;
}

export function CampsGround({ immobile, ...props }: CampGroundProps) {
    const { nodes, materials } = useGLTF(assets.campGround.model);

    const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);

    const textureGrass = useLoadTexture(assets.campGround.textures.grass);
    const textureDirt = useLoadTexture(assets.campGround.textures.dirt);
    const textureMask = useLoadTexture(assets.campGround.textures.mask);
    const textureGround = useLoadTexture(darkModeEnabled ? assets.paddock.textures.groundNight : assets.paddock.textures.ground);

    const scrollShader = useRef<ShaderMaterial>(null!);
    
    const uniforms = useMemo(
        () => {
          let obj = THREE.UniformsLib.fog;
          obj = Object.assign(
            {
                uTime: { value: 0 },
                uMap: { value: textureGrass },
                uMapDirt: { value: textureDirt },
                uMapMask: { value: textureMask },
                uLight: { value: 0.5 },
                uSpeed: { value: new Vector2(0, -0.04867) },
                uScale: { value: 2 },
                uRange: { value: immobile ? 20 : 8.25 },
                uSoftRange: { value: 0.4 },
            }, obj);
          return obj;
        },
        [textureGrass, textureDirt, textureMask]
      );

    useFrame((state, delta) => {
        if (darkModeEnabled) {
            scrollShader.current.uniforms.uLight.value = 0.1;
        } else {

            if (!immobile) {
                scrollShader.current.uniforms.uTime.value += delta;
            }
            scrollShader.current.uniforms.uLight.value = 1;
        }
        // scrollShader.current.uniforms.uMap.value = darkModeEnabled ? tWaterNight : tWater;

    });

    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={(nodes.Frame as Mesh).geometry}
                material={(nodes.Frame as Mesh).material}
            >
                <meshBasicMaterial map={textureGround} />
            </mesh>
            <mesh
                castShadow
                receiveShadow
                geometry={(nodes.Ground as Mesh).geometry}
                material={(nodes.Ground as Mesh).material}
            >
                <shaderMaterial
                    fog
                    ref={scrollShader}
                    vertexShader={scrollVert}
                    fragmentShader={scrollFrag}
                    toneMapped={false}
                    uniforms={uniforms}
                />
            </mesh>
        </group>
    );
}

useGLTF.preload(assets.campGround.model);