type Props = {
    title: React.ReactNode;
    price: React.ReactNode;
    access: React.ReactNode;
}

export default function PensionBox({title, price, access} : Props) {
    return (
        <div className=" p-5 rounded bg-stone-800 shadow-lg flex flex-col items-center justify-start h-full">
            <h4 className=" text-md landscape:text-xl mb-8 text-center  decoration-orange-500 uppercase font-extrabold">{title}</h4>
            <ul className="w-full text-center font-light">
                <li className="p-2 w-full mb-2">{price}</li>
                <li className="p-2  w-full ">{access}</li>
            </ul>
        </div>
    )
}