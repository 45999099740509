import { Center, Html, Text3D, Text } from "@react-three/drei";
import { GroupProps, useFrame } from "@react-three/fiber";
import { useState } from "react";
import { MathUtils } from "three";
import useStopwatch from "../stores/useStopwatch";

export default function Stopwatch(prop: GroupProps) {

    const time = useStopwatch(s => s.time);
    const update = useStopwatch(s => s.update);

    useFrame((s, delta) => {
        update(delta * 2);
    });

    return <>

        <Text position={[0, 6.35, -19.7]}
            scale={8}
            color="red"
            font="./fonts/alarm clock.ttf">
            {(time <= 9.99 ? '0' : '') + time.toFixed(2)}
        </Text>
    </>
}