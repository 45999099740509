import { ArcballControls, DeviceOrientationControls, FirstPersonControls, FlyControls, OrbitControls, OrbitControlsProps, PointerLockControls, TrackballControls, useProgress } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Euler, Vector3 } from "three";
import useCameraTarget from "../stores/useCameraTarget";
import useExploreMode, { ExploreModeState } from "../stores/useExploreMode";
import ExploreModeControls from "./ExploreModeControls";
import { Transform } from "./Transform";

type CameraManagerProps = OrbitControlsProps & {
    original: Transform;
    portrait?: Transform;
    landscape?: Transform;
    explore?: Transform;
    transition?: Transform;

    // default?: CameraParameters;
    // portrait?: CameraParameters;
    // explore?: CameraParameters;
    // landscape?: CameraParameters;
}

export default function CameraManager({ transition, original, portrait, landscape, explore, ...props }: CameraManagerProps) {

    const exploreModeState = useExploreMode(s => s.exploreModeState);

    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    const lg = useMediaQuery({ query: '(min-width: 1024px)' });
    const setCameraTarget = useCameraTarget(s => s.setTarget);
    const [ctrlEnabled, setCtrlEnabled] = useState<boolean>(false);

    const setCameraTargetDefault = (duration: number) => {

        if (isPortrait) {
            setCameraTarget((portrait || original), duration)
        } else if (lg) {
            setCameraTarget(original, duration);
        } else {
            setCameraTarget((landscape || original), duration);
        }
    }

    const getCameraTargetDefault = (): Transform => {
        if (isPortrait) {
            return portrait || original;
        } else if (lg) {
            return original;
        } else {
            return landscape || original;
        }
    }

    // useFrame(() => {
    //     if (exploreModeState === ExploreModeState.Enabled) {
    //         const dir = new Vector3();
    //         camera.getWorldDirection(dir);
    //         // dir.y = 0;
    //         dir.normalize();
    //         dir.multiplyScalar(1);
    //         controlTarget.copy(camera.position.clone().add(dir))
    //         setControlTarget(controlTarget.clone())
    //         // props.target = controlTarget;

    //         if (camera.position.y < 2){
    //             camera.position.y = 2;
    //         }
    //     }
    // })

    useEffect(() => {

        switch (exploreModeState) {
            case ExploreModeState.Disabled:
                setCtrlEnabled(false);
                break;
            case ExploreModeState.TransitionToDisable:
                {
                    setCtrlEnabled(false);
                    setCameraTargetDefault(2);
                    break;
                }
            case ExploreModeState.Enabled:
                setCtrlEnabled(true);
                break;
            case ExploreModeState.TransitionToEnable:
                {
                    setCtrlEnabled(false);
                    setCameraTarget(explore || original, 2);
                    break;
                }
        }

    }, [exploreModeState])

    useEffect(() => {

        if (exploreModeState !== ExploreModeState.Disabled) {
            return;
        }

        setCameraTargetDefault(2);

    }, [isPortrait, 
         //original, portrait, landscape
    ])

    return <>
        {
            ctrlEnabled &&
            <OrbitControls
                // target={controlTarget}
                {...props}
            />
            // <ExploreModeControls/>
            // <FirstPersonControls/>
            // <PointerLockControls/>
        }
    </>
}