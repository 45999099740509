// eslint-disable-next-line import/no-anonymous-default-export
export default /* glsl */`
#include <fog_pars_vertex>

varying vec2 vUv;

void main()
{
    #include <begin_vertex>
    #include <project_vertex>
    #include <fog_vertex>
    
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    gl_Position = projectedPosition;

    vUv = uv;
}`
