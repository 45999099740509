import { useFrame } from "@react-three/fiber";
import gsap from "gsap";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation } from "react-router-dom";
import useMenuState, { MenuStates } from "../stores/useMenuState";
import { MathUtils2 } from "../three/MathUtils2";

export default function NavItem({ url, label, index, total }: { url: string, label: string, index: number, total: number }) {
  const div = useRef<HTMLDivElement>(null!);
  const div2 = useRef<HTMLDivElement>(null!);

  let location = useLocation();

  const updateClass = useMenuState(s => s.updateClass);
  const menuState = useMenuState(s => s.menuState);
  const completeTransition = useMenuState(s => s.completeTransition);

  const lg = useMediaQuery({ query: '(max-width: 1024px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  const [tween, settween] = useState<any>(null!);

  useEffect(() => {
    if (div2 && div2.current) {
      updateClass(div2.current, "active-link", location.pathname === url);
    }
  }, [location, updateClass, url]);

  useEffect(() => {
    if (!lg) {
      div.current.style.transform = "scale(1,1)";
      return;
    }
    if (div2 && div2.current) {
      updateClass(div2.current, "active-link", location.pathname === url);
    }

    const delayDuration = 0.03;

    if (tween) {
      tween.kill();
    }

    switch (menuState) {

      case MenuStates.Opening: {
        const localTween = gsap.to(div.current, {
          delay: index * delayDuration,
          duration: 0.6,
          ease: "power2.out",
          scale: 1,
        });
        settween(localTween);
        localTween.then(tryCompleteTransition);
        break;
      }
      case MenuStates.Closing: {
        const localTween = gsap.to(div.current, {
          delay: (total - 1 - index) * delayDuration,
          duration: 0.6,
          ease: "power2.out",
          scale: 0,
        });
        settween(localTween);
        localTween.then(tryCompleteTransition);
        break;
      }
      case MenuStates.Closed:
        div.current.style.transform = "scale(0,0)";
        completeTransition();
        break;
    }
  }, [menuState, lg]);

  const tryCompleteTransition = () => {
    if ((index === total - 1 && menuState === MenuStates.Opening)
      || (index === 0 && menuState === MenuStates.Closing)) {
      completeTransition();
    }
  }

  return (
    <>
      <div ref={div}>
        {
          (menuState !== MenuStates.Closed || !lg) &&
          <NavLink to={url}
            className="select-none highlight-transparent z-50">
            <div ref={div2}
              className="
            bg-white
            dark:bg-stone-700
            text-stone-700 
            dark:active-link:text-stone-700 
            dark:text-stone-100 
            active-link:text-stone-100 
            active-link:bg-stone-700
            dark:active-link:bg-stone-100
            dark:hover:bg-stone-600
              border
              border-transparent
            dark:border-stone-400
              shadow-md
              font-normal
              active-link:decoration-transparent
              text-md 
              mx-1
              portrait:mx-20
              lg:mx-2 
              my-1 
              px-4
              py-2 
              transition-all 
              duration-300 
              ease-out
              text-center
              lg:rounded-full
              lg:w-auto
              w-56
              "
            >
              {label}
            </div>
          </NavLink>
        }
      </div>
    </>
  );

}
