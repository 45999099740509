import { useGLTF, useTexture } from "@react-three/drei";
import { assets } from "./Assets";

export default function StableScenePreload(){
    // Stable
    useGLTF.preload(assets.stable.model);
    useTexture.preload( assets.stable.textures.room);
    useTexture.preload(assets.stable.textures.props);

    return <></>
}