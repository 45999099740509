import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Euler, MathUtils, Vector3 } from "three";
import useCameraTarget from "../stores/useCameraTarget";
import { assets } from "./Assets";
import { Horse, HorseHair } from "./Horse";
import { Human, HumanTypes } from "./Human";
import MovementCircular from "./MovementCircular";
import { Paddock } from "./Paddock";
import Tree from "./Tree";
import { Gear } from "./Gear";
import CameraManager from "./CameraManager";
import { Transform } from "./Transform";
import Grass from "./Grass";
import FootSteps from "./FootSteps";
import useFootSteps from "../stores/useFootSteps";
import DustParticle from "./DustParticle";
import useScrollCameraEffect from "./UseScrollCameraEffect";
import useDarkMode from "../stores/useDarkMode";
import useScene from "./UseScene";

export default function PaddockScene() {

  useScene("/cours");

  useScrollCameraEffect();

  const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);

  const clearSteps = useFootSteps(s => s.clear);

  useEffect(() => {
    clearSteps();
  }, [])


  return (
    <>
      {!darkModeEnabled && <fog attach="fog" args={[0xfffded, 15, 90]} />}
      {darkModeEnabled && <fog attach="fog" args={[0x474c54, 15, 80]} />}
      <DustParticle />
      <FootSteps />
      <Paddock />
      <Grass model={assets.paddock.grassModel} wind={3} />
      <CameraManager
        original={new Transform(20.82, 10.66, 16.46, 0, 4, 0)}
        // original={new Transform(4.93, 2.74, 3.30, 0, 0, 0)}
        portrait={new Transform(23.82, 8.66, 18.46, 0, 7.5, 1)}
        landscape={new Transform(10.82, 3.66, 8.46, 0, 5, -2.5)}
        explore={new Transform(18, 4, 11, 0, 0, 0)}
        // orbitCtrl
        makeDefault
        minPolarAngle={Math.PI * 0.1}
        maxPolarAngle={Math.PI * 0.49}
        minAzimuthAngle={Math.PI * 0.1}
        maxAzimuthAngle={Math.PI * 0.4}
        maxDistance={50}
        minDistance={10}
        maxZoom={10}
        minZoom={10}
        panSpeed={0.3}
        zoomSpeed={0.3}
        rotateSpeed={0.4}
        enableDamping
        enablePan={false}
        // enablePan
        dampingFactor={0.05}
      />

      <MovementCircular dustFactor={0.4} idx={0} anglePerSec={-24} radius={5.5} position={new Vector3(0, 0.53, 0)}>
        <Horse
          animTimeScale={1.3}
          idx={0}
          animation={assets.horse.animations.TrotCycle}
          hair={HorseHair.long}
          bodyTexture={assets.horse.textures.pitaBody}
          hairTexture={assets.horse.textures.pitaHair}
        />
        <Gear
          animTimeScale={1.3}
          idx={0}
          animation={assets.gear.animations.TrotCycle}
        />
        <Human
          animTimeScale={1.3}
          humanType={HumanTypes.youngWoman}
          animation={assets.human.animations.TrotCycle}
          idx={0}
          position={[0, 0, 0]} />
      </MovementCircular>

      <Human
        showFork
        animTimeScale={0.5}
        humanType={HumanTypes.farmer}
        animation={assets.human.animations.IdleFence}
        idx={1}
        position={[6, .6, 7.5]}
        rotation={[0, MathUtils.DEG2RAD * 180, 0]}
      />

      <MovementCircular idx={2} dustFactor={0.2} anglePerSec={-12} radius={4} position={new Vector3(0, 0.53, 0)}>
        <Horse
          scale={.9}
          animTimeScale={1.2}
          idx={2}
          animation={assets.horse.animations.WalkCycle}
          hair={HorseHair.double}
          bodyTexture={assets.horse.textures.mooglyBody}
          hairTexture={assets.horse.textures.mooglyHair}
        />
        <Gear
          position={[0, 0, 0]}
          scale={.9}
          animTimeScale={1.2}
          idx={2}
          animation={assets.gear.animations.WalkCycle}
        />
        <Human
          animTimeScale={1.2}
          humanType={HumanTypes.youngMan}
          animation={assets.human.animations.WalkCycle}
          idx={2}
          position={[0, -.2, 0]} />
      </MovementCircular>

      <Tree position={[-4.58, 0.52, 9.51]} scale={1} />
      <Tree
        position={[11.69, 0.52, 4.28]}
        rotation={[0, -0.523, 0]}
        scale={0.82}
      />
      <Tree
        position={[11.43, 0.52, -2.3]}
        rotation={[0, 1.047, 0]}
        scale={0.95}
      />
    </>
  );
}