// eslint-disable-next-line import/no-anonymous-default-export
export default /* glsl */`
#include <tonemapping_fragment>
#include <fog_pars_fragment>

uniform sampler2D uMap;
uniform float uLight;
uniform float uRange;
uniform float uSoftRange;

varying vec2 vUv;
varying vec4 vPos;

void main() {
    float y = vUv.y - floor(vUv.y / 1.0);
    float x = vUv.x - floor(vUv.x / 1.0);
    vec2 repeatUv = vec2(x, y);

    float a =  1.0 - abs(vPos.z / uRange);
    float len = sqrt(vPos.z * vPos.z + vPos.x * vPos.x);
    float soft = (uRange - abs(len))  / uSoftRange;
   
    vec4 color = texture2D(uMap, repeatUv);
    color.xyz *= uLight;
    color.w = soft;
    // color = LinearTosRGB(color);
    gl_FragColor = color;
    #include <fog_fragment>
}
`