import { useGLTF, useTexture } from "@react-three/drei";
import { assets } from "./Assets";

export default function PaddockScenePreload(){
    // Paddock
    useGLTF.preload(assets.paddock.model);
    useTexture.preload( assets.paddock.textures.ground);
    useTexture.preload(assets.paddock.textures.props);

    // Grass
    useGLTF.preload(assets.paddock.grassModel);
    useTexture.preload(assets.grass.textures.grass);
    useTexture.preload(assets.grass.textures.grassAlpha);

    // Horse and stuff
    useGLTF.preload(assets.gear.model);
    useGLTF.preload(assets.human.model);

    useTexture.preload(assets.human.textures.womanCloths);
    useTexture.preload(assets.horse.textures.dust);
    useTexture.preload(assets.gear.textures.gear);

    return <></>
}