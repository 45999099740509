import { useEffect, useRef } from "react";
import useMenuState from "../stores/useMenuState";

type Props = {
    additionalClasses?: string
    onClick?: Function;
    children: React.ReactNode,
    active?: boolean
    noWhiteBg?: boolean;
}

export default function CommonButtonOutline({ additionalClasses, onClick, children, active, noWhiteBg }: Props) {

    const updateClass = useMenuState(s => s.updateClass);

    const buttonRef = useRef<HTMLButtonElement>(null!);

    const callback = () => {
        if (onClick) {
            onClick();
        }
    }

    useEffect(() => {
        if (buttonRef.current && active !== undefined) {
            updateClass(buttonRef.current, "opened", active);
        }
    }, [active, updateClass])

    return (
        <>
            <button className={
                ""
                + "px-4 "
                + "py-2 "
                + "transition-all "
                + "duration-200 "
                + "active:scale-90 "
                + "select-none "
                + "highlight-transparent "
                + "shadow-xl "
                + "rounded-full "
                + "border "
                + "border-stone-600 "
                + "dark:border-stone-400 "
                + (!noWhiteBg && "bg-white/30 ")
                + (!noWhiteBg && "hover:bg-white/80 ")
                + "opened:bg-stone-700 "
                + "dark:bg-stone-700/30 "
                + "group-forced-dark:bg-stone-700/90 "
                + "dark:hover:bg-stone-500/30 "
                + "group-forced-dark:hover:bg-stone-500/90 "
                + "text-stone-700 "
                + "opened:text-stone-100 "
                + "dark:text-stone-100 "
                + "hover:scale-105 "
                + additionalClasses
            }
                onClick={callback}
                ref={buttonRef}>
                {children}
            </button>
        </>
    );
}