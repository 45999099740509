
// vertex shader
export const dustParticleVertexShader = /* glsl */`
#include <fog_pars_vertex>

uniform float uTime;
uniform float uDeltaTime;
uniform float uPixelRatio;
uniform float uSize;
uniform vec3 uLight;
uniform float uDarkMode;

attribute float aScale;
attribute float aLifetime;
attribute vec3 aVelocity;
attribute float aStartime;

varying vec2 vUv;
varying float vLifetime;
varying float vStarttime;
varying float vScale;
varying float vLightDistance;

void main()
{
    #include <begin_vertex>
    #include <project_vertex>
    #include <fog_vertex>

    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    float t = (uTime - aStartime) / aLifetime;
    // t = 1.0;
    float s = (0.1 + pow(t,0.3)) * aScale;
    modelPosition += vec4(
        aVelocity.x * (1.0 - t),
        aVelocity.y,
        aVelocity.z,
        0.0
    ) * t;

    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;

    gl_Position = projectionPosition;
    
    gl_PointSize = uSize * s * uPixelRatio;
    gl_PointSize *= (1.0 / - viewPosition.z);
    
    vLightDistance = distance(vec3(modelPosition), uLight);
    vLifetime = aLifetime;
    vStarttime = aStartime;
    vScale = aScale;
    vUv = uv;
}
`;

// fragment shader
export const dustPrticleFragmentShader = /* glsl */`
#include <fog_pars_fragment>

uniform float uTime;
uniform sampler2D uMap;
uniform float uDarkMode;

varying vec2 vUv;
varying float vLifetime;
varying float vStarttime;
varying float vScale;
varying float vLightDistance;

void main()
{
    vec4 color = texture2D(uMap, gl_PointCoord);

    // float distanceToCenter = distance(gl_PointCoord, vec2(0.5));
    // float strength = 0.05 / distanceToCenter - 0.1;

    float t = 1.0 - ((uTime - vStarttime) / vLifetime);
    // t = 1.0;
    float r = ((vScale - 5.0) / 60.0) * 0.6;
    r = 1.0 - (r*r*r);
    vec3 brown = vec3(0.796, 0.721, 0.564) * r;

    float light = (vLightDistance / 8.0);
    float lightFactor = mix(1.0, 1.0-(light * light), uDarkMode);
    lightFactor = clamp(lightFactor, 0.1, 1.0);

    color.x *= brown.x * lightFactor;
    color.y *= brown.y * lightFactor;
    color.z *= brown.z * lightFactor;
    color.w *= 0.1 * t * lightFactor;

    float f = (vLightDistance / 8.0);
    // gl_FragColor = vec4(1.0-(r*r), 0.0,0.0,1.0);
    // gl_FragColor = vec4(1.0 - (f*f), 0.0,0.0,0.5);
    gl_FragColor = color;
    #include <fog_fragment>
    // gl_FragColor = vec4(1.0,0.0,0.0,1.0);
}
`