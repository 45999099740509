/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useState } from "react";
import { Line, useGLTF } from "@react-three/drei";
import { assets } from "./Assets";
import { Euler, Mesh, Vector3 } from "three";
import { GroupProps, useFrame } from "@react-three/fiber";
import useDarkMode from "../stores/useDarkMode";
import useLoadTexture from "./UseLoadTexture";
import useLightSource, { FakeLight } from "../stores/useLightSource";
import useFakeLight from "./UseFakeLight";

export function Paddock(props: GroupProps) {
  const { nodes } = useGLTF(assets.paddock.model);

  const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);

  const textureGround = useLoadTexture(darkModeEnabled ? assets.paddock.textures.groundNight : assets.paddock.textures.ground);
  const textureProps = useLoadTexture(darkModeEnabled ? assets.paddock.textures.propsNight : assets.paddock.textures.props);

  useFakeLight(new Vector3(-0.25, 0.00, 2.25), 14, "paddockLight");

  return (
    <>
      <group {...props} dispose={null}>
        <mesh
          geometry={(nodes.Room as Mesh).geometry}
          material={(nodes.Room as Mesh).material}
          position={[11.43, 0.53, -2.3]}
        >
          <meshBasicMaterial map={textureGround} />
        </mesh>
        <mesh
          geometry={(nodes.Props as Mesh).geometry}
          material={(nodes.Props as Mesh).material}
          position={[0, 0.53, -0.81]}
        >
          <meshBasicMaterial map={textureProps} />
        </mesh>
      </group>
    </>
  );
}

useGLTF.preload(assets.paddock.model);
