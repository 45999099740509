
// vertex shader
export const lightRayVertexShader = /* glsl */`
#include <fog_pars_vertex>

varying vec2 vUv;
varying vec3 vPosition;

void main()
{
    #include <begin_vertex>
    #include <project_vertex>
    #include <fog_vertex>

    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    gl_Position = projectedPosition;

    vPosition = vec3(modelPosition.x, modelPosition.y, modelPosition.z);

    vUv = uv;
}
`

// fragment shader
export const lightRayFragmentShader = /* glsl */`

#include <fog_pars_fragment>

varying vec2 vUv;
varying vec3 vPosition;
uniform vec3 lightPosition;
uniform float uTime;

// Hash function for pseudo-random values
float hash(vec3 p) {
    return fract(sin(dot(p, vec3(12.9898, 78.233, 45.543))) * 43758.5453);
}

// Interpolation function (in this case, cubic Hermite interpolation)
float interpolate(float a, float b, float t) {
    float ft = t * 3.1415927;
    float f = (1.0 - cos(ft)) * 0.5;
    return a * (1.0 - f) + b * f;
}

// 3D Perlin noise function
float perlinNoise(vec3 p) {
    vec3 i = floor(p);
    vec3 f = fract(p);

    // Smoothly interpolate between grid points
    vec3 u = f * f * (3.0 - 2.0 * f);

    return interpolate(
        interpolate(
            interpolate(hash(i + vec3(0.0, 0.0, 0.0)), hash(i + vec3(1.0, 0.0, 0.0)), u.x),
            interpolate(hash(i + vec3(0.0, 1.0, 0.0)), hash(i + vec3(1.0, 1.0, 0.0)), u.x),
            u.y
        ),
        interpolate(
            interpolate(hash(i + vec3(0.0, 0.0, 1.0)), hash(i + vec3(1.0, 0.0, 1.0)), u.x),
            interpolate(hash(i + vec3(0.0, 1.0, 1.0)), hash(i + vec3(1.0, 1.0, 1.0)), u.x),
            u.y
        ),
        u.z
    );
}

vec2 rotate(vec2 uv, float rotation, vec2 mid)
{
    return vec2(
      cos(rotation) * (uv.x - mid.x) + sin(rotation) * (uv.y - mid.y) + mid.x,
      cos(rotation) * (uv.y - mid.y) - sin(rotation) * (uv.x - mid.x) + mid.y
    );
}

void main()
{
    vec4 color = vec4(1.0, 1.0, 1.0, 1.0);
    float deg2Rad = 3.1415926535897932384626433832795 / 180.0;

    vec3 offset = vec3(uTime * 0.5);
    float noiseValue2 = clamp(perlinNoise((vPosition + offset)* 15.5) - 0.0, 0.5, 1.0);
    float t = 0.1;
    float angleNoise = clamp(perlinNoise((vPosition + offset) * 40.0), 0.6, 1.0);
    vec2 uvRot = rotate(vUv, 45.0 * deg2Rad, vec2(0.5,0.5)) - vec2(t);
    float linePattern = clamp(pow(sin(atan(uvRot.y, uvRot.x)  * 45.0 ) + noiseValue2 * 1.5, 2.0) + angleNoise , 0.0, 1.0) * noiseValue2;

    float y = 1.0 - vUv.y;
    float yStrenghFar = pow(y + 0.1, 2.0);
    float yStrenghClose =  vUv.y - 0.01;

    float sideOffset = 0.15;
    vec2 leftSideFadingUV = rotate(vUv, 0.0 * deg2Rad, vec2(0.5,0.5));

    float opacity =  (0.15 / (distance(vec2(vUv.y, (vUv.x - 0.5) * 5.0 + 0.5), vec2(0.0, 0.5)))) * yStrenghFar * linePattern * yStrenghClose ;
    
    vec3 offset2 = vec3(uTime * 0.5, uTime * -0.2, uTime * 0.3);
    float noiseValue = perlinNoise((vPosition + offset2)* 0.45);
    gl_FragColor = vec4(1.0,1.0,1.0,opacity * noiseValue);
    #include <fog_fragment>
}
`