import { Effects, Loader, PresentationControls, ScrollControls, useProgress, useScroll } from "@react-three/drei";
import { extend, useFrame, useThree } from "@react-three/fiber";
import gsap from "gsap";
import { Suspense, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Group, LoadingManager } from "three";
import useExperienceState, { ExperienceStates } from "../stores/useExperienceState";
import ArScene from "./ArScene";
import CampScene from "./CampScene";
import CampScenePreload from "./CampScenePreload";
import FarmScene from "./FarmScene";
import HorseScene from "./HorseScene";
import LivingRoomScene from "./LivingRoomScene";
import PaddockScene from "./PaddockScene";
import PaddockScenePreload from "./PaddockScenePreload";
import StableScene from "./StableScene";
import StableScenePreload from "./StableScenePreload";
import PedagoScene from "./PedagoScene";
import useFootSteps from "../stores/useFootSteps";
import useDustSteps from "../stores/useDustSteps";
import PedagoScenePreload from "./PedagoScenePreload";
import useDebugMode from "../stores/useDebugMode";
import { FarmerDance } from "./FarmerDance";
import DanceScene from "./DanceScene";
import CompetitionScene from "./CompetitionScene";

export default function SceneManager() {

    const location = useLocation();

    const [sceneLocation, setscene] = useState<string>();
    // const { progress } = useProgress();

    const experienceState = useExperienceState(s => s.experienceState);
    const progressCompleted = useExperienceState(s => s.progressCompleted);

    const changeExperienceState = useExperienceState(s => s.changeExperienceState);

    const clearSteps = useFootSteps(s => s.clear);
    const clearDust = useDustSteps(s => s.clear);

    const setDebugClock = useDebugMode(s => s.setClock);

    const clock = useThree(s => s.clock);
    setDebugClock(clock);

    useEffect(() => {
        if (experienceState === ExperienceStates.Loading ||
            experienceState === ExperienceStates.LoadScene) {
            setscene(location.pathname);
            clearSteps();
            clearDust();
        }

        if (experienceState === ExperienceStates.LoadScene && progressCompleted) {
            changeExperienceState(ExperienceStates.TransitionOut);
        }

    }, [location, experienceState]);

    useEffect(() => {
        if (experienceState === ExperienceStates.LoadScene) {
            changeExperienceState(ExperienceStates.TransitionOut);
        }
    }, [progressCompleted])

    // useEffect(() => {
    //     if (experienceState === ExperienceStates.LoadScene && progress >= 100) {
    //         changeExperienceState(ExperienceStates.TransitionOut);
    //     }
    // }, [progress])

    return <>
        <Suspense fallback={null}>
            <PaddockScenePreload />
            <StableScenePreload />
            <CampScenePreload />
            <PedagoScenePreload />
            {sceneLocation === "/" && <FarmScene />}
            {/* {sceneLocation === "/" && <DanceScene />} */}
            {sceneLocation === "/cours" && <PaddockScene />}
            {sceneLocation === "/camps-de-jour" && <CampScene />}
            {sceneLocation === "/demi-pension" && <StableScene />}
            {sceneLocation === "/chevaux" && <HorseScene />}
            {sceneLocation === "/pedagogique" && <PedagoScene />}
            {sceneLocation === "/contacts" && <LivingRoomScene />}
            {sceneLocation === "/dance" && <DanceScene />}
            {sceneLocation === "/competition" && <CompetitionScene />}
        </Suspense>

        {/* {sceneLocation === "/" && <Suspense fallback={null}><FarmScene /></Suspense>}
        {sceneLocation === "/cours" && <Suspense fallback={null}><PaddockScene /></Suspense>}
        {sceneLocation === "/camps-de-jour" && <Suspense fallback={null}><CampScene /></Suspense>} */}

        {/* {
            experienceState === ExperienceStates.Ready &&
            <>
                {interval >= 4 && <PaddockScenePreload />}
                {interval >= 5 && <StableScenePreload />}
                {interval >= 6 && <CampScenePreload />}
            </>
        } */}
    </>
}