import { Html } from "@react-three/drei";
import { useEffect } from "react";
import { Euler, MathUtils, Vector3 } from "three";
import { images } from "../components/Images";
import useExploreMode, { ExploreModeState } from "../stores/useExploreMode";
import useFootSteps from "../stores/useFootSteps";
import { assets } from "./Assets";
import CameraManager from "./CameraManager";
import { CampsGround } from "./CampsGround";
import DustParticle from "./DustParticle";
import Farm from "./Farm";
import FootSteps from "./FootSteps";
import Grass from "./Grass";
import { Horse, HorseHair } from "./Horse";
import LeafParticle from "./LeafParticle";
import MovementCircular from "./MovementCircular";
import ParticlesSystem from "./ParticlesSystem";
import { Transform } from "./Transform";
import Tree, { TreeTypes } from "./Tree";

export default function HorseScene() {
    const exploreModeState = useExploreMode(s => s.exploreModeState)
    const clear = useFootSteps(s => s.clear);

    useEffect(() => {
        clear();
    }, [])

    // return (
    //     <>
    //         <gridHelper />
    //         <DustParticle />
    //         <FootSteps />
    //         <MovementCircular anglePerSec={-24} radius={5} position={new Vector3(0, 0, 0)}>
    //             <Horse
    //                 animTimeScale={1.3}
    //                 idx={0}
    //                 animation="Horse_TrotCycle"
    //                 hair={HorseHair.long}
    //                 bodyTexture={assets.horse.textures.pitaBody}
    //                 hairTexture={assets.horse.textures.pitaHair}
    //             />
    //         </MovementCircular>
    //         <MovementCircular anglePerSec={-14} radius={3} position={new Vector3(0, 0, 0)}>
    //             <Horse
    //                 animTimeScale={1.3}
    //                 idx={2}
    //                 animation="Horse_HorseWalk"
    //                 hair={HorseHair.double}
    //                 bodyTexture={assets.horse.textures.okelaBody}
    //                 hairTexture={assets.horse.textures.okelaHair}
    //             />
    //         </MovementCircular>
    //         <Horse
    //             position={new Vector3(0, 0, 0)}
    //             rotation={new Euler(0, MathUtils.DEG2RAD * -40, 0)}

    //             animTimeScale={0.2}
    //             // scale={1}
    //             idx={1}
    //             animation="Horse_HorseWalk"
    //             hair={HorseHair.double}
    //             bodyTexture={assets.horse.textures.mooglyBody}
    //             hairTexture={assets.horse.textures.mooglyHair}
    //         />
    //         <CameraManager
    //             original={new Transform(2.20, 1.90, 3.66, 0, 0, 0)}
    //             explore={new Transform(0, 10, 0, 0, 0, 0)}
    //             makeDefault
    //         />
    //     </>
    // )

    return (
        <>

            <Tree position={[3, -2, -3]} treeTypes={TreeTypes.Cedar}  wind={0.5}/>
            <CameraManager
                original={new Transform(1.17, 3.30, 8.58, -0.11, 2.28, 0.03)}
                explore={new Transform(3.43, 4.27, 13.71, -0.11, 0.28, 0.03)}
                // orbitCtrl
                makeDefault
            // minPolarAngle={Math.PI * 0.1}
            // maxPolarAngle={Math.PI * 0.49}
            // maxDistance={50}
            // minDistance={10}
            // maxZoom={10}
            // minZoom={10}
            // enableDamping
            // enablePan
            // dampingFactor={0.05}
            />
            <Horse
                position={new Vector3(-4, 0.00, 0.25)}
                rotation={new Euler(0, MathUtils.DEG2RAD * -40, 0)}
                // scale={1}
                idx={0}
                animation="Horse_IdleEat"
                hair={HorseHair.long}
                bodyTexture={assets.horse.textures.pitaBody}
                hairTexture={assets.horse.textures.pitaHair}
            />
            {
                exploreModeState === ExploreModeState.Disabled &&
                < Html position={[-4.5, 4.5, 0.25]}>
                    {/* <div className="w-36 h-36 bg-red-500"></div> */}
                    <div className="w-56 p-1 bg-white rounded shadow-sm">
                        <img src={images.camps_01.src} alt={images.camps_01.alt} className="w-56" />
                    </div>
                </Html>
            }
            <Horse
                position={new Vector3(-2, -0.00, 0)}
                rotation={new Euler(0, MathUtils.DEG2RAD * -40, 0)}
                // scale={1}
                idx={1}
                animation="Horse_IdleEat"
                hair={HorseHair.double}
                bodyTexture={assets.horse.textures.mooglyBody}
                hairTexture={assets.horse.textures.mooglyHair}
            />
            <Horse
                position={new Vector3(0, 0.00, -0.25)}
                rotation={new Euler(0, MathUtils.DEG2RAD * -40, 0)}
                // scale={1}
                idx={2}
                animation="Horse_IdleEat"
                hair={HorseHair.short}
                bodyTexture={assets.horse.textures.hopeBody}
                hairTexture={assets.horse.textures.hopeHair}
            />
            {
                exploreModeState === ExploreModeState.Disabled &&
                <Html position={[-0.5, 4.5, -0.25]}>
                    {/* <div className="w-36 h-36 bg-red-500"></div> */}
                    <div className="w-56 p-1 bg-white rounded shadow-sm">
                        <img src="./img/h4.jpg" alt={images.camps_01.alt} className="w-56" />
                    </div>
                </Html>
            }
            <Horse
                position={new Vector3(2, 0.00, -0.5)}
                rotation={new Euler(0, MathUtils.DEG2RAD * -40, 0)}
                // scale={1}
                idx={3}
                animation="Horse_IdleEat"
                hair={HorseHair.double}
                bodyTexture={assets.horse.textures.okelaBody}
                hairTexture={assets.horse.textures.okelaHair}
            />


            {/* <Tree position={[5,0,0]} />
            <Tree position={[5,0,-3]} />
            <Tree position={[-10, 0,-3]} treeTypes={TreeTypes.Pine} />
            <CampsGround immobile scale={2}/> */}
            {/* <FootSteps/> */}
            {/* <LeafParticle /> */}
        </>
    );
}