import React, { useMemo, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GroupProps, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import grassVert from "../shaders/grass-vert";
import grassFrag from "../shaders/grass-frag";
import scrollClipFrag from "../shaders/scroll-clip-frag";
import scrollClipVert from "../shaders/scroll-clip-vert";
import scrollGrassVert from "../shaders/scroll-grass-vert";
import scrollGrassFrag from "../shaders/scroll-grass-frag";
import { assets } from "./Assets";
import useDarkMode from "../stores/useDarkMode";
import { Color, Mesh, ShaderMaterial, Vector2, Vector3 } from "three";
import useLoadTexture from "./UseLoadTexture";
import useLoadingManager from "../stores/useLoadingManager";
import { DEG2RAD } from "three/src/math/MathUtils";

export enum TreeTypes {
  Leaf,
  Pine,
  PineWinter,
  LeafAutumn,
  LeafWinter,
  Cedar
}


type TreeProps = GroupProps & {
  treeTypes?: TreeTypes;
  scrolling?: boolean;
  noShadow?: boolean;
  wind?: number;
  scrollRange?: number;
  shaderYOffset?: number;
}

export default function Tree({ wind, noShadow, scrolling, treeTypes, scrollRange, shaderYOffset, ...props }: TreeProps) {

  if (!treeTypes) {
    treeTypes = TreeTypes.Leaf;
  }

  if (!scrollRange) {
    scrollRange = 7.5;
  }

  let branch = "";
  let branchAlpha = "";
  let trunk = "";
  let model = "";
  let height = 0;

  switch (treeTypes) {
    case TreeTypes.Leaf:
      branch = assets.tree.textures.branch;
      branchAlpha = assets.tree.textures.branchAlpha;
      trunk = assets.tree.textures.trunk;
      model = assets.tree.model;
      height = 7;
      break;
    case TreeTypes.Pine:
      branch = assets.pine.textures.branch;
      branchAlpha = assets.pine.textures.branchAlpha;
      trunk = assets.pine.textures.trunk;
      model = assets.pine.model;
      height = 6;
      break;
    case TreeTypes.PineWinter:
      branch = assets.pine.textures.branchWinter;
      branchAlpha = assets.pine.textures.branchAlpha;
      trunk = assets.pine.textures.trunk;
      model = assets.pine.model;
      height = 6;
      break;
    case TreeTypes.LeafWinter:
      branch = assets.tree.textures.branchWinter;
      branchAlpha = assets.tree.textures.branchAlphaWinter;
      trunk = assets.tree.textures.trunk;
      model = assets.tree.model;
      height = 7;
      break;
    case TreeTypes.LeafAutumn:
      branch = assets.tree.textures.branchAutumn;
      branchAlpha = assets.tree.textures.branchAlpha;
      trunk = assets.tree.textures.trunk;
      model = assets.tree.model;
      height = 7;
      break;
    case TreeTypes.Cedar:
      branch = assets.cedar.textures.branch;
      branchAlpha = assets.cedar.textures.branchAlpha;
      trunk = assets.tree.textures.trunk;
      model = assets.cedar.model;
      height = 7;
      break;
  }

  const { nodes } = useGLTF(model);
  const tBranch = useLoadTexture(branch);
  const tBranchAlpha = useLoadTexture(branchAlpha);
  const tTrunk = useLoadTexture(trunk);

  const branchShader = useRef<ShaderMaterial>(null!);
  const trunkShader = useRef<ShaderMaterial>(null!);

  const darkModeEnabled = useDarkMode((s) => s.darkModeEnabled);

  const trunkUniforms = useMemo(
    () => {
      let obj = THREE.UniformsLib.fog;
      obj = Object.assign(
        {
          uMap: { value: tTrunk },
          uLight: { value: 0.5 },
          uRange: { value: scrollRange },
          uSoftRange: { value: 0.25 },
          uScale: { value: 2 },
        }, obj);
      return obj;
    },
    [tTrunk]
  );

  let scale = 1;

  if (props.scale instanceof Vector3) {
    scale = props.scale.y;
  } else if (Array.isArray(props.scale)) {
    scale = props.scale[1];
  } else if (props.scale as number) {
    scale = props.scale as number;
  }

  const uniforms = useMemo(
    () => {
      let obj = THREE.UniformsLib.fog;
      obj = Object.assign(
        {
          uFrequency: { value: new Vector2(50, 30) },
          uTime: { value: Math.random() * 10 },
          uColor: { value: new Color(0xff0000) },
          uMap: { value: tBranch },
          uAlphaMap: { value: tBranchAlpha },
          uHeight: { value: 10 },
          uLight: { value: 1 },
          uBoundingHeight: { value: height * scale },
          uBranchBackdrop: { value: 1 },
          uWind: { value: wind || 15 },
          uRange: { value: scrollRange },
          uSoftRange: { value: 0.25 },
          uYOffset: {value: shaderYOffset || 0}
        }, obj);
      return obj;
    },
    [tBranch, tBranchAlpha]
  );

  useFrame((state, delta) => {
    if (branchShader?.current) {
      branchShader.current.uniforms.uTime.value += delta * 0.5;
      branchShader.current.uniforms.uLight.value = darkModeEnabled ? 0.15 : 1;
    }
    if (trunkShader?.current) {
      trunkShader.current.uniforms.uLight.value = darkModeEnabled ? 0.15 : 1;
    }
  });

  return (

    <group {...props} dispose={null}>
      {
        treeTypes !== TreeTypes.Cedar &&
        <mesh geometry={(nodes.Tronk as Mesh).geometry} material={(nodes.Tronk as Mesh).material}>
          {scrolling
            ? <shaderMaterial
              fog
              ref={trunkShader}
              vertexShader={scrollClipVert}
              fragmentShader={scrollClipFrag}
              toneMapped={false}
              uniforms={trunkUniforms}
              transparent={true}
            />
            : <meshBasicMaterial map={tTrunk} color={darkModeEnabled ? new THREE.Color(0x555555) : new THREE.Color(0xffffff)} />
          }
        </mesh>
      }
      <mesh
        geometry={(nodes.Branches as Mesh).geometry}
        material={(nodes.Branches as Mesh).material}
      >
        {scrolling
          ?
          <shaderMaterial
            fog
            ref={branchShader}
            vertexShader={scrollGrassVert}
            fragmentShader={scrollGrassFrag}
            transparent
            side={THREE.DoubleSide}
            depthWrite={false}
            toneMapped={false}
            uniforms={uniforms}
          />
          : <shaderMaterial
            fog
            ref={branchShader}
            vertexShader={grassVert}
            fragmentShader={grassFrag}
            transparent
            side={THREE.DoubleSide}
            depthWrite={false}
            // depthTest={false}
            toneMapped={false}
            uniforms={uniforms}
          />}
      </mesh>

    </group>
  );
}

useGLTF.preload(assets.tree.model);
