import { useFrame } from "@react-three/fiber";
import { MathUtils, Vector3 } from "three";
import useCameraTarget from "../stores/useCameraTarget";

export default function useScrollCameraEffect(camScrollTarget?: Vector3){

    const getCamTargetPosition = useCameraTarget(s => s.getTarget);
    camScrollTarget = camScrollTarget || new Vector3(6.81, 0, 12.45);
    
    let t = 0;

    useFrame((state, delta) => {
    
        if (!camScrollTarget) {
            return;
        }

        const pos = state.camera.position;
        let t1 = MathUtils.clamp(window.scrollY / window.screen.availHeight, 0, 1);

        if (t === t1) {
            return;
        }

        t = MathUtils.damp(t, t1, 5, delta);
        pos.lerpVectors(getCamTargetPosition(), camScrollTarget, Math.sqrt(t));
    })
}