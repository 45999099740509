import { useEffect, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { useFrame, useLoader } from "@react-three/fiber";
import { AnimationAction, AnimationMixer, LoadingManager, Object3D, SkinnedMesh } from "three";
import useLoadedSkinnedMeshes from "../stores/useLoadedSkinnedMeshes";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import useLoadingManager from "../stores/useLoadingManager";

export default function useSkinnedMesh(url: string, customId: string): LoadedSkinnedMesh {
  const [skinnedMesh, setSkinnedMesh] = useState<LoadedSkinnedMesh>(null!);
  const [animationMixer, setAnimationMixer] = useState<AnimationMixer>(null!);

  const push = useLoadedSkinnedMeshes(s => s.push);
  const pop = useLoadedSkinnedMeshes(s => s.pop);

  const loadingManager = useLoadingManager(s => s.loadingManager);

  useEffect(() => {
    if (skinnedMesh) {
      console.log("cleanup")
      Object.keys(skinnedMesh.actions).forEach(k => {
        skinnedMesh.actions[k].reset();
        skinnedMesh.actions[k].stop();
      });
    }
  }, [skinnedMesh]);


  useFrame((state, delta) => {
    if (animationMixer) {
      animationMixer.update(delta)
    }
  });

  useEffect(() => {
    if (skinnedMesh && animationMixer) {
      skinnedMesh.animationMixer = animationMixer;
    }
  }, [animationMixer, skinnedMesh])

  useEffect(() => {
    const available = pop(customId);

    if (!available) {
      const gltfLoader = new GLTFLoader(loadingManager);
      const dracoLoader = new DRACOLoader(loadingManager);

      dracoLoader.setDecoderPath('./draco/');
      gltfLoader.setDRACOLoader(dracoLoader);

      gltfLoader.load(url, (gltf) => {
        const mixer = new AnimationMixer(gltf.scene);

        const loadedSkinnedMesh = Object.assign(gltf, new LoadedSkinnedMesh());
        loadedSkinnedMesh.animationMixer = animationMixer;
        loadedSkinnedMesh.url = url;
        gltf.animations.forEach(a => loadedSkinnedMesh.actions[a.name] = mixer.clipAction(a, gltf.scene))
        loadedSkinnedMesh.customId = customId;

        gltf.scene.traverse((obj) => {
          if (obj.name) loadedSkinnedMesh.objMap[obj.name] = obj;
          if (obj instanceof SkinnedMesh) loadedSkinnedMesh.skinnedMeshMap[obj.name] = obj;
        });

        setAnimationMixer(mixer)
        setSkinnedMesh(loadedSkinnedMesh);

        push(loadedSkinnedMesh);
      });
    }
    else {
      setAnimationMixer(available.animationMixer);
      setSkinnedMesh(available);
    }

  }, [url, customId]);

  return skinnedMesh;
}

export class LoadedSkinnedMesh {
  url: string = "";
  actions: { [name: string]: AnimationAction } = {};
  objMap: { [name: string]: Object3D } = {};
  skinnedMeshMap: { [name: string]: SkinnedMesh } = {};
  animationMixer!: AnimationMixer;
  customId!: string;
}