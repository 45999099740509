import { useGLTF, useTexture } from "@react-three/drei";
import { assets } from "./Assets";

export default function PedagoScenePreload() {
    // Predago
    useGLTF.preload(assets.miniScenes.model);
    useTexture.preload(assets.miniScenes.textures.miniscenes);
    useTexture.preload(assets.miniScenes.textures.scrolling);
    useTexture.preload(assets.miniScenes.textures.snow);

    // Pine
    useGLTF.preload(assets.pine.model);
    useTexture.preload(assets.pine.textures.branchWinter);
    useTexture.preload(assets.pine.textures.branchAlpha);
    useTexture.preload(assets.pine.textures.trunk);

    // Tree
    useGLTF.preload(assets.tree.model);
    useTexture.preload(assets.tree.textures.branch);
    useTexture.preload(assets.tree.textures.branchAutumn);
    useTexture.preload(assets.tree.textures.branchWinter);
    useTexture.preload(assets.pine.textures.branchAlpha);
    useTexture.preload(assets.pine.textures.trunk);

    // Leaf
    useTexture.preload(assets.leaf.textures.leaf);


    return <></>
}