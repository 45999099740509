import { useFrame } from "@react-three/fiber";
import React, { ReactChild, ReactChildren, ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { Group, MathUtils, Quaternion, Vector3 } from "three";
import { assets } from "./Assets";
import { MathUtils2 } from "./MathUtils2";
import useSyncLocomotion, { LocomotionData } from "../stores/useSyncLocomotion";
import useDebugMode from "../stores/useDebugMode";
import Target from "./Target";
import { Html, Line, PivotControls, Sphere } from "@react-three/drei";
import useStopwatch from "../stores/useStopwatch";
import useDustSteps from "../stores/useDustSteps";
import useEventDispatcher from "../stores/useEventDispatcher";

type PathTarget = {
  speed: number;
  pos: number[];
  evt?: string;
}

type Props = {
  path: PathTarget[];
  idx: number;
  children: React.ReactNode;
  speed?: number;
  steering?: number;
  hasStopwatch?: boolean;
  dustFactor?: number;
}

export default function MovementPath({ speed, dustFactor, steering, path, idx, children, hasStopwatch }: Props) {
  const groupRef = useRef<Group>(null!);

  const [source] = useState(new Vector3());
  const [target] = useState(new Vector3());
  const [direction] = useState(new Vector3());
  const [velocity] = useState(new Vector3());
  const [currentSpeed] = useState({ value: speed || 1.2 });
  const [targetIndex, setTargetIndex] = useState(-1);
  const [speedOffset] = useState({value: 0});
  const [debugChangeCounter, setDebugChangeCounter] = useState(-1);

  const directionSpeed = 0.05 * (speed || 1.2);

  const syncLocomotion = useSyncLocomotion(s => s.syncLocomotion);
  const restartStopwatch = useStopwatch(s => s.restart);
  const stopStopwatch = useStopwatch(s => s.stop);
  const fireEvent = useEventDispatcher(s => s.fireEvent);

  const movementPathEnabled = useDebugMode(s => s.showMovementPathEnabled);

  const syncDustFactor = useDustSteps(s => s.syncDustFactor);

  useEffect(() => {
    syncDustFactor(idx, dustFactor || 1);
  }, [idx, dustFactor]);

  useEffect(() => {
    changeTarget();

    source.copy(target);
    groupRef.current.position.copy(target);
  }, []);

  useFrame((state, delta) => {
    if (targetIndex === -1){
      return;
    }
    //  MathUtils2.moveTowards(source, target, delta * (speed || 1.2));
    // setSource(source);
    const dir = target.clone().sub(groupRef.current.position);
    let forward = new Vector3();
    forward = groupRef.current.getWorldDirection(forward);

    if (source.distanceTo(target) < 2) {
      changeTarget();
    }

    // const dir = target.clone().sub(source.clone()).normalize();
    // MathUtils2.moveTowards(direction, dir, directionSpeed);
    // groupRef.current.lookAt(source.clone().add(direction));

    const desiredVelocity = target.clone().sub(groupRef.current.position).normalize().multiplyScalar(speed || 1.2);

    let forwardAxis = new Vector3();
    forwardAxis = groupRef.current.getWorldDirection(forwardAxis);

    const currentDirection = velocity.length() > 0 ? velocity.normalize() : forwardAxis;
    const desiredDirection = desiredVelocity.length() > 0 ? desiredVelocity.normalize() : currentDirection;

    const deltaDirection = desiredDirection.clone().sub(currentDirection);
    const directionTorque = Math.min(deltaDirection.length(), (steering || 5) * delta);
    const newDirection = currentDirection.add(deltaDirection.multiplyScalar(directionTorque));

    const angle = newDirection.angleTo(desiredDirection);
    const cross = newDirection.clone().cross(desiredDirection);
    const sign = Math.sign(cross.y);
    const signedAngle = angle * sign;
    const signedAngleDeg = MathUtils.RAD2DEG * signedAngle;
    const direction = MathUtils.clamp(signedAngleDeg / 45, -1, 1);
    // const correctedSpeedTarget = (speed || 1.2) * 1.0 - Math.abs(direction * (speed || 1.2) * 0.2);

    const targetSpeed = path[targetIndex].speed + speedOffset.value;
    const variationFactor = targetSpeed < currentSpeed.value ? 8 : 8;
    currentSpeed.value = MathUtils2.moveNumberToward(currentSpeed.value, targetSpeed, delta * variationFactor);
    const speedFactor = currentSpeed.value / (speed || 1.2);

    const locomotionData: LocomotionData = {
      direction: direction,
      speedFactor: speedFactor
    }

    syncLocomotion(idx, locomotionData);

    velocity.copy(newDirection.multiplyScalar(currentSpeed.value * delta));

    groupRef.current.lookAt(source.clone().add(velocity));
    groupRef.current.position.copy(source.add(velocity));

    forwardAxis = groupRef.current.getWorldDirection(forwardAxis);

    if (hasStopwatch && forwardAxis.z > 0 && groupRef.current.position.z > -13) {
      restartStopwatch();
    }

    if (hasStopwatch && forwardAxis.z < 0 && groupRef.current.position.z < -13) {
      stopStopwatch();
    }
  });

  const changeTarget = () => {

    let t = targetIndex + 1;
    if (t >= path.length) t = 0;

    setTargetIndex(t);
    target.set(path[t].pos[0], path[t].pos[1], path[t].pos[2]);

    if (path[t].evt){
      fireEvent(path[t].evt || '');
    }

    // const randomDist = 1.5;

    // target.x += -randomDist * 0.5 + Math.random() * randomDist * 0.5;
    // target.z += -randomDist * 0.5 + Math.random() * randomDist * 0.5;

    //  speedOffset.value = -1 + Math.random() * 2;

  };

  const onDrag = (mat: THREE.Matrix4, i: number) => {
    let pos = new Vector3();
    mat.decompose(pos, new Quaternion(), new Vector3());

    path[i].pos[0] = pos.x;
    path[i].pos[1] = pos.y;
    path[i].pos[2] = pos.z;

    setDebugChangeCounter(debugChangeCounter + 1);
  };

  const onSpeedChange = (evt: React.ChangeEvent<HTMLInputElement>, i: number) => {
    path[i].speed = parseInt(evt.target.value);
    setDebugChangeCounter(debugChangeCounter + 1);
  }

  const copyPathToClipboard = () => {
    let pathStr = '';
    path.forEach((p, i) => pathStr += `{ speed: 1.2, pos: [${p.pos[0].toFixed(2)}, 0, ${p.pos[2].toFixed(2)}] }, // ${i}\n`);
    navigator.clipboard.writeText(pathStr);
  }
  // { speed: superfast, pos: [0.00, 0.00, -25.00] }
  return <>
    {movementPathEnabled &&
      <Line
        points={path.map(p => new Vector3(p.pos[0], p.pos[1] + 0.1, p.pos[2]))}       // Array of points, Array<Vector3 | Vector2 | [number, number, number] | [number, number] | number>
        color="black"
        lineWidth={4}
        dashed={true}
        dashScale={5}
      />
    }
    {movementPathEnabled && path.map((p: PathTarget, i: number) =>
      <>
        <PivotControls
          lineWidth={4}
          scale={40}
          fixed={true}
          matrix={new THREE.Matrix4().setPosition(new Vector3(p.pos[0], p.pos[1] + 0.1, p.pos[2]))}
          onDrag={(m) => onDrag(m, i)}
          activeAxes={[true, false, true]}
        >
          <mesh />
        </PivotControls>
        <Html position={[p.pos[0], p.pos[1], p.pos[2] + 1]}>
          <div className="  w-72 text-xs ">
            <span className=" bg-black text-white font-bold">{i.toString()}</span><br />
            {/* <div className=" text-black">
              <span>Speed:</span>
              <input type="number" className="w-10" value={p.speed} onChange={(evt) => onSpeedChange(evt, i)} />
            </div> */}
          </div>
        </Html>
        <Html position={[0, 0, 0]}>
          <div className=" text-black-500 font-bold w-72 text-xs ">
            <button className=" bg-slate-300" onClick={copyPathToClipboard}>Copy Path to Clipboard</button>
          </div>
        </Html>
      </>
    )}
    <group ref={groupRef}>{children}</group>
  </>
}
