import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import useDebugMode, { Log, StaticLog } from "../stores/useDebugMode";

export default function PrintLog() {

    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    
    const logs: Log[] = useDebugMode(s => s.logs);
    const staticLogs: StaticLog[] = useDebugMode(s => s.staticLogs);
    const logIdx = useDebugMode(s => s.logIdx);

    useEffect(() => {

    }, [logIdx])

    return (
        <>
            <div className="absolute top-0 left-0 w-screen flex flex-col items-start justify-start grow pointer-events-none touch-none text-outline p-8 portrait:px-2 pt-24 font-bold portrait:text-xs overflow-clip"
            style={{height: isPortrait ? "50vh": "100vh"}}>
                {logs.map((log, idx) =>
                    <div className="w-screen flex flex-row" key={"log_" + idx} style={{ color: log.color || "yellow" }}>
                        <div className="w-48 portrait:w-12">{log.time.toFixed(2)}</div>
                        <div className="w-48">{log.message}</div>
                    </div>
                )}
            </div>

            <div className="absolute top-0 portrait:top-1/2 left-0 w-screen h-screen flex flex-col  grow pointer-events-none touch-none text-outline p-8 pt-24 portrait:px-2  font-bold portrait:text-xs">
                {staticLogs.map((log, idx) =>
                    <div className="w-screen flex flex-row" key={"log_" + idx} style={{ color: log.color || "yellow" }}>
                        {!isPortrait && <div className="flex grow"></div>}
                        <div className="w-24 portrait:w-16">{log.time.toFixed(2)}</div>
                        <div className="w-48 portrait:w-32">{log.key}</div>
                        <div className="w-48 portrait:w-32">{log.value}</div>
                    </div>
                )}
            </div>
        </>
    );
}