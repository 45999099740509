import * as THREE from 'three';
import { MathUtils } from 'three';

export class MathUtils2 {
    public static moveTowards(current: THREE.Vector3, target: THREE.Vector3, maxDistanceDelta: number){
        const delta = target.clone().sub(current);
        const magnitude = delta.length();
        
        if (magnitude <= maxDistanceDelta || magnitude === 0){
            current.copy(target);
            return target;
        }

        current.add(delta.normalize().multiplyScalar(maxDistanceDelta));

        return current;
    }

    public static moveNumberToward(current: number, target: number, maxDelta: number){
        if (Math.abs(target - current) <= maxDelta){
            return target;
        }

        return current + Math.sign(target - current) * maxDelta;
    }

    public static toEuler(radianRotation: THREE.Euler): THREE.Euler{
        return new THREE.Euler(
            radianRotation.x * MathUtils.RAD2DEG,
            radianRotation.y * MathUtils.RAD2DEG,
            radianRotation.z * MathUtils.RAD2DEG,
        )
    }

    public static toRadian(eulerRotation: THREE.Euler): THREE.Euler{
        return new THREE.Euler(
            eulerRotation.x * MathUtils.DEG2RAD,
            eulerRotation.y * MathUtils.DEG2RAD,
            eulerRotation.z * MathUtils.DEG2RAD,
        )
    }
}