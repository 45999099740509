import {
  Box,
  Html,
  OrbitControls,
  PivotControls,
} from "@react-three/drei";
import { useRef, useState } from "react";
import { Quaternion, Vector3 } from "three";

export default function Target(props: any) {
  const box = useRef();

  let [pos, setpos] = useState("");
  let [rot, setrot] = useState("");

  const position = new Vector3();
  const scale = new Vector3();
  const rotation = new Quaternion();

  const onDrag = (mat: any) => {
    mat.decompose(position, rotation, scale);

    const x = position.x.toFixed(2).toString();
    const y = position.y.toFixed(2).toString();
    const z = position.z.toFixed(2).toString();

    const roll = rotation.x.toFixed(2).toString();
    const pitch = rotation.y.toFixed(2).toString();
    const yaw = rotation.z.toFixed(2).toString();
   
    setpos(`[${x}, ${y}, ${z}]`);
    setrot(`[${roll}, ${pitch}, ${yaw}]`);
  };


  return (
    <>
    {/* <OrbitControls makeDefault/> */}
      <PivotControls
        anchor={[0, -1, 0]}
        depthTest={false}
        lineWidth={4}
        scale={80}
        fixed={true}
        onDrag={onDrag}
        activeAxes={[true, true, true]}
        annotationsClass="invisible"
      >
        <Box {...props} ref={box}>
          <meshBasicMaterial color="black"></meshBasicMaterial>
          <Html position={[0, 8, 0]} wrapperClass="label" center>
            <button style={{width: "250px"}} onClick={() => navigator.clipboard.writeText(pos)}>Position: {pos}</button>
            <button style={{width: "250px"}} onClick={() => navigator.clipboard.writeText(rot)}>Rotation: {rot}</button>
          </Html>
        </Box>
      </PivotControls>
    </>
  );
}
