import { useEffect } from "react";
import useExperienceState from "../stores/useExperienceState";

export default function useScene(location: string) {
    const registerLoadedScene = useExperienceState(s => s.registerLoadedScene);

    useEffect(() => {
        registerLoadedScene(location, true);

        return () => {
            registerLoadedScene(location, false);
        }
    }, []);
}