import { useEffect, useRef, useState } from "react";
import { images } from "../components/Images";
import useExperienceState, { ExperienceStates } from "../stores/useExperienceState";
import gsap from "gsap";
import { useLocation } from "react-router-dom";
import useDarkMode from "../stores/useDarkMode";
import { useMediaQuery } from "react-responsive";

class Tweens {
    div: any;
}

export default function SceneTransition() {

    const lg = useMediaQuery({ query: '(max-width: 1024px)' });
    const [tweens] = useState<Tweens>(new Tweens());

    const divRef = useRef<HTMLDivElement>(null!);

    const experienceState = useExperienceState(s => s.experienceState);
    const changeExperienceState = useExperienceState(s => s.changeExperienceState);
    const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);

    const location = useLocation();

    useEffect(() => {
        if (experienceState === ExperienceStates.Ready
            || experienceState === ExperienceStates.LoadScene
            || experienceState === ExperienceStates.TransitionOut) {
            changeExperienceState(ExperienceStates.TransitionIn);
        }
    }, [location]);

    useEffect(() => {
        if (tweens.div) {
            tweens.div.kill();
        }

        switch (experienceState) {
            case ExperienceStates.Ready:
            case ExperienceStates.Loading:
                divRef.current.style.opacity = "0";
                break;
            case ExperienceStates.TransitionIn:
                tweens.div = gsap.to(divRef.current, {
                    duration: 0.6,
                    opacity: 1,
                    ease: "power2.in"
                });
                tweens.div.then(() => {
                    changeExperienceState(ExperienceStates.LoadScene);
                })
                break;
            case ExperienceStates.TransitionOut:
                tweens.div = gsap.to(divRef.current, {
                    duration: 1.2,
                    opacity: 0,
                    ease: "power2.in"
                });
                tweens.div.then(() => {
                    changeExperienceState(ExperienceStates.Ready);
                })
                break;
        }
    }, [experienceState]);

    return <>
        <div ref={divRef} className={(darkModeEnabled ? "bg-stone-800 " : "bg-stone-200 ") + " fixed top-0 left-0 w-full h-screen  pointer-events-none"}>
            <div className={(lg
                ? " items-end justify-end pb-8 pr-8"
                : " items-center justify-center ")
                + " w-screen flex flex-row "}
                style={{height: `${window.innerHeight}px`}}>
                <img src={images.logo.src} className={lg ? "w-32" : "w-60"} alt={images.logo.alt} />
            </div>
        </div>
    </>
}