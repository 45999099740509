import useExploreMode, { ExploreModeState } from "../stores/useExploreMode";
import { MdExplore, MdExploreOff } from "react-icons/md";
import CommonButton from "./CommonButton";
import React, { useEffect, useState } from "react";

export default function ExploreModeSwitch() {
  const toggleExploreMode = useExploreMode((s) => s.toggle);
  const exploreModeState = useExploreMode((s) => s.exploreModeState);

  const [enabled, setenabled] = useState<boolean>(false);

  useEffect(() => {
    setenabled(exploreModeState === ExploreModeState.Enabled || exploreModeState === ExploreModeState.TransitionToEnable);
  }, [exploreModeState])

  return (
    <>
      <CommonButton onClick={toggleExploreMode} active={false} noWhiteBg={enabled} additionalClasses={(enabled ? " bg-amber-600 pulse" : "")}>
        {!enabled && <MdExplore className="text-2xl" />}
        {enabled && <MdExploreOff className={"text-2xl " + (enabled && " text-stone-50")} />}
      </CommonButton>
    </>
  );
}
