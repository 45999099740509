import { useEffect } from "react";
import { Vector3 } from "three";
import useDarkMode from "../stores/useDarkMode";
import useLightSource, { FakeLight } from "../stores/useLightSource";

export default function useFakeLight(pos: Vector3, range: number, id: string){
    const addLight = useLightSource(s => s.add);
    const removeLight = useLightSource(s => s.remove);

    const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);
  
    const fakeLight: FakeLight = {
      pos: pos,
      range: range,
      id: id
    };
  
    useEffect(() => {
      if (darkModeEnabled) {
        addLight(fakeLight);
      } else {
        removeLight(fakeLight);
      }
      return () => {
        removeLight(fakeLight);
      }
    }, [darkModeEnabled])
}