import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export type LocomotionData = {
    // left = -1, right = 1
    direction: number;
    speedFactor: number;
}

export default create(subscribeWithSelector((set: Function) => {
    return {
        locomotionsSync: {},

        syncLocomotion(key: number, data: LocomotionData) {
            set((state: any) => {
                state.locomotionsSync[key.toString()] = data;

                const tmp = state.locomotionsSync;
                state.locomotionsSync = {};
                Object.keys(tmp).forEach(k => state.locomotionsSync[k] = tmp[k]);

                return {};
            });
        },
    };
}));
