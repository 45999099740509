/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations, Plane } from "@react-three/drei";
import useSkinnedMesh from "./UseSkinnedMesh";
import { GroupProps } from "@react-three/fiber";
import { assets } from "./Assets";
import { Color, Group, SkinnedMesh } from "three";
import useLoadTexture from "./UseLoadTexture";
import useGetLighting from "./UseGetLighting";
import useSyncTime from "../stores/useSyncTime";

type SleightProps = GroupProps & {
    animation: string;
    idx: number;
    animTimeScale?: number;
}

export function Sleigh({ animation, idx, animTimeScale, ...props }: SleightProps) {
    const group = useRef<Group>(null!);

    const skinnedMeshMap = useSkinnedMesh(assets.sleigh.model, `Sleigh_${idx}`);

    const textureSleigh = useLoadTexture(assets.sleigh.textures.sleigh);
    const textureHitch = useLoadTexture(assets.sleigh.textures.hitch);

    const light = useGetLighting(group);

    const syncAnimation = useSyncTime(s => s.syncAnimation);
    const animSync: any = useSyncTime(s => s.animationsSync);

    useEffect(() => {
        if (skinnedMeshMap) {
            skinnedMeshMap.actions[animation].time = animSync[`Anim_${idx}`];
            skinnedMeshMap.actions[animation].timeScale = animTimeScale || 1;
        }

    }, [animSync, animTimeScale])

    useEffect(() => {
        if (skinnedMeshMap) {

            skinnedMeshMap.actions[animation].play();
            syncAnimation(`Anim_${idx}`, skinnedMeshMap.actions[animation].getClip().duration);
        }
        return () => {
            if (skinnedMeshMap) {
                skinnedMeshMap.actions[animation].stop();
            }
        };
    }, [animation, skinnedMeshMap, skinnedMeshMap?.animationMixer]);

    return (
        <>
            {!skinnedMeshMap ? (
                <Plane position={[0, 0.025, 0]} rotation={[0, 0, 0]} scale={2.5}>
                    <meshBasicMaterial map={textureHitch} opacity={0} transparent />
                    <meshBasicMaterial map={textureSleigh} opacity={0} transparent />
                </Plane>
            ) : (
                <group ref={group} {...props} dispose={null}>
                    <group name="Scene">
                        <group name="SleighArmature">
                            <primitive object={skinnedMeshMap.objMap["def-head"]} />
                            <primitive object={skinnedMeshMap.objMap["def-spine"]} />
                            <primitive object={skinnedMeshMap.objMap["def-back"]} />
                            <primitive object={skinnedMeshMap.objMap["def-front"]} />
                            <primitive object={skinnedMeshMap.objMap["def-sleigh"]} />
                            <primitive object={skinnedMeshMap.objMap["def-backl"]} />
                            <primitive object={skinnedMeshMap.objMap["def-backr"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-anchor-01l"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-anchor-02l"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-anchor-03l"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-anchor-04l"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-01l"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-02l"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-03l"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-anchor-01r"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-anchor-02r"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-anchor-03r"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-anchor-04r"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-01r"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-02r"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-03r"]} />
                            <skinnedMesh
                                name="Hitch"
                                geometry={(skinnedMeshMap.skinnedMeshMap["Hitch"] as SkinnedMesh).geometry}
                                material={(skinnedMeshMap.skinnedMeshMap["Hitch"] as SkinnedMesh).material}
                                skeleton={(skinnedMeshMap.skinnedMeshMap["Hitch"] as SkinnedMesh).skeleton}
                            >
                                <meshBasicMaterial map={textureHitch} color={new Color(light, light, light)} />
                            </skinnedMesh>
                            <skinnedMesh
                                name="Sleigh"
                                geometry={(skinnedMeshMap.skinnedMeshMap["Sleigh"] as SkinnedMesh).geometry}
                                material={(skinnedMeshMap.skinnedMeshMap["Sleigh"] as SkinnedMesh).material}
                                skeleton={(skinnedMeshMap.skinnedMeshMap["Sleigh"] as SkinnedMesh).skeleton}
                            >
                                <meshBasicMaterial map={textureSleigh} color={new Color(light, light, light)} />
                            </skinnedMesh>
                        </group>
                    </group>
                </group>
            )}
        </>
    )
}

useGLTF.preload(assets.sleigh.model);
