/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect, useState } from "react";
import { useGLTF, useAnimations, Plane, Html } from "@react-three/drei";
import { assets } from "./Assets";
import { Color, Group, Object3D, SkinnedMesh } from "three";
import useLoadTexture from "./UseLoadTexture";
import { GroupProps, useFrame, useThree } from "@react-three/fiber";
import useSkinnedMesh from "./UseSkinnedMesh";
import useGetLighting from "./UseGetLighting";
import useSyncTime from "../stores/useSyncTime";
import { useLocation, useNavigate } from "react-router-dom";
import useExploreMode, { ExploreModeState } from "../stores/useExploreMode";
import useSong from "../stores/useSong";
import useDarkMode from "../stores/useDarkMode";
import { take, timer } from "rxjs";
import { computeBlendTree, initBlendTree } from "./ComputeBlendTree";
import useSyncLocomotion from "../stores/useSyncLocomotion";
import useAnimatedSkinnedMesh from "./UseAnimatedSkinnedMesh";
import useEventDispatcher from "../stores/useEventDispatcher";

export enum HumanTypes {
  farmer,
  youngMan,
  youngWoman,
}

type HumanProps = GroupProps & {
  humanType: HumanTypes;
  animation?: string;
  idx: number;
  animTimeScale?: number;
  showFork?: boolean;
}

class CameraShake {
  startTime!: number;
}

export function Human({ showFork, humanType, animation, idx, animTimeScale, ...props }: HumanProps) {

  const navigate = useNavigate();
  const exploreModeState = useExploreMode(s => s.exploreModeState);
  const setDark = useDarkMode(s => s.setDark);

  const [cameraShake] = useState(new CameraShake());
  const [pendingAnimation] = useState({ from: '', to: '', time: 0, current: '' });

  let texturePath = "";

  switch (humanType) {
    case HumanTypes.farmer:
      texturePath = assets.human.textures.farmer;
      break;
    case HumanTypes.youngMan:
      texturePath = assets.human.textures.manCloths;
      break;
    case HumanTypes.youngWoman:
      texturePath = assets.human.textures.womanCloths;
      break;
  }

  const group = useRef<Group>(null!);
  const neck = useRef<Object3D>(null!);
  const head = useRef<Object3D>(null!);
  const playSong = useSong(s => s.play);

  const skinnedMeshMap = useAnimatedSkinnedMesh(assets.human.model, "Human", idx, animTimeScale, animation, assets.human.locomotion, false);
  const texture = useLoadTexture(texturePath);

  const tFork = useLoadTexture(assets.human.textures.fork);
  const tShadow = useLoadTexture(assets.human.textures.shadow);

  const light = useGetLighting(group);

  const evtBarrel = useEventDispatcher(s => s.evtBarrel);
  const evtDone = useEventDispatcher(s => s.evtDone);

  useEffect(() => {
    if (!skinnedMeshMap || animation !== assets.human.animations.sit) {
      return;
    }

    const duration = 0.2 + Math.random() * 0.4;
    const anim = Math.random() > 0.2 ? assets.human.animations.sitClap : assets.human.animations.sitCheers;

    skinnedMeshMap.actions[anim].play();
    skinnedMeshMap.actions[anim].reset();

    skinnedMeshMap.actions[assets.human.animations.sit].fadeOut(duration);
    skinnedMeshMap.actions[anim].fadeIn(duration);

    pendingAnimation.from = anim;
    pendingAnimation.to = assets.human.animations.sit;

    pendingAnimation.time = clock.elapsedTime + 0.5 + Math.random();
  }, [evtBarrel])

  useEffect(() => {
    if (!skinnedMeshMap || animation !== assets.human.animations.sit) {
      return;
    }
    const anim = assets.human.animations.standCheers;

    skinnedMeshMap.actions[anim].play();
    skinnedMeshMap.actions[anim].reset();

    const delay = 0.2 + Math.random() * 0.5;
    skinnedMeshMap.actions[assets.human.animations.sit].fadeOut(delay);
    skinnedMeshMap.actions[anim].fadeIn(delay);

    pendingAnimation.from = anim;
    pendingAnimation.to = assets.human.animations.sit;

    pendingAnimation.time = clock.elapsedTime + 2.5 + Math.random() * 0.3;
  }, [evtDone])

  useFrame((s, d) => {
    if (clock.elapsedTime > pendingAnimation.time
      && pendingAnimation.to
      && pendingAnimation.from !== pendingAnimation.to) {

      const duration = 0.2 + Math.random() * 0.4;

      skinnedMeshMap.actions[pendingAnimation.to].reset();
      skinnedMeshMap.actions[pendingAnimation.to].play();
      skinnedMeshMap.actions[pendingAnimation.from].fadeOut(duration);
      skinnedMeshMap.actions[pendingAnimation.to].fadeIn(duration);

      pendingAnimation.from = pendingAnimation.to;
    }
  })

  const { camera, clock } = useThree();

  const dance = () => {
    navigate('/dance');
    timer(800).pipe(take(1)).subscribe(() => {
      playSong();
      setDark(true);
    });
    cameraShake.startTime = clock.elapsedTime;
    if ('vibrate' in navigator) {
      // Trigger a haptic feedback vibration with a duration of 200 milliseconds
      navigator.vibrate(200);
    };
  }

  // useFrame((s, d) => {
  //   if (animation === assets.human.animations.sit && clock.elapsedTime > 5 && !blendingSitAnim.value) {
  //     skinnedMeshMap.actions[assets.human.animations.sitClap].play();
  //     skinnedMeshMap.actions[assets.human.animations.sit].crossFadeTo(skinnedMeshMap.actions[assets.human.animations.sitClap], 0.4, false);
  //     blendingSitAnim.value = true;
  //   }
  // })

  useFrame((s, d) => {

    if (cameraShake.startTime > 0 && clock.elapsedTime - cameraShake.startTime < 0.3) {

      var shakeIntensity = 0.01;
      var offsetX = Math.random() * shakeIntensity * 2 - shakeIntensity;
      var offsetY = Math.random() * shakeIntensity * 2 - shakeIntensity;
      var offsetZ = Math.random() * shakeIntensity * 2 - shakeIntensity;
      var rotationX = Math.random() * shakeIntensity * 2 - shakeIntensity;
      var rotationY = Math.random() * shakeIntensity * 2 - shakeIntensity;
      var rotationZ = Math.random() * shakeIntensity * 2 - shakeIntensity;

      // Apply the offset to the camera's position and rotation
      camera.position.set(camera.position.x + offsetX, camera.position.y + offsetY, camera.position.z + offsetZ);
      camera.rotation.set(camera.rotation.x + rotationX, camera.rotation.y + rotationY, camera.rotation.z + rotationZ);
    }

  })

  return (
    <>
      {!skinnedMeshMap ? (
        <Plane position={[0, 0.025, 0]} rotation={[0, 0, 0]} scale={2.5}>
          <meshBasicMaterial map={texture} opacity={0} transparent />
          <meshBasicMaterial map={tFork} opacity={0} transparent />
        </Plane>
      ) : (
        <group ref={group} {...props} dispose={null}>
          <group name="Scene">
            <group name="HumanArmature">
              <primitive object={skinnedMeshMap.objMap["def-human-hips"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-spine"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-chest"]} />
              <primitive ref={neck} object={skinnedMeshMap.objMap["def-human-neck"]} />
              <primitive ref={head} object={skinnedMeshMap.objMap["def-human-head"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-shoulderl"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-arml"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-forearml"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-handl"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-thumbl"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-legl"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-shinl"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-footl"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-toel"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-legr"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-shinr"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-footr"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-toer"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-shoulderr"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-armr"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-forearmr"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-handr"]} />
              <primitive object={skinnedMeshMap.objMap["def-human-thumbr"]} />
              <primitive object={skinnedMeshMap.objMap["def-prop"]} />
              <primitive object={skinnedMeshMap.objMap["def-hair-01"]} />
              <primitive object={skinnedMeshMap.objMap["def-hair-02"]} />
              {
                humanType === HumanTypes.farmer &&
                <skinnedMesh
                  name="Farmer"
                  geometry={(skinnedMeshMap.skinnedMeshMap["Farmer"] as SkinnedMesh).geometry}
                  material={(skinnedMeshMap.skinnedMeshMap["Farmer"] as SkinnedMesh).material}
                  skeleton={(skinnedMeshMap.skinnedMeshMap["Farmer"] as SkinnedMesh).skeleton}
                >
                  <meshBasicMaterial map={texture} color={new Color(light, light, light)} />
                </skinnedMesh>
              }
              {
                humanType === HumanTypes.youngMan &&
                <skinnedMesh
                  name="ManCloths"
                  geometry={(skinnedMeshMap.skinnedMeshMap["ManCloths"] as SkinnedMesh).geometry}
                  material={(skinnedMeshMap.skinnedMeshMap["ManCloths"] as SkinnedMesh).material}
                  skeleton={(skinnedMeshMap.skinnedMeshMap["ManCloths"] as SkinnedMesh).skeleton}
                >
                  <meshBasicMaterial map={texture} color={new Color(light, light, light)} />
                </skinnedMesh>
              }
              {
                humanType === HumanTypes.youngWoman &&
                <skinnedMesh
                  name="WomanCloths"
                  geometry={(skinnedMeshMap.skinnedMeshMap["WomanCloths"] as SkinnedMesh).geometry}
                  material={(skinnedMeshMap.skinnedMeshMap["WomanCloths"] as SkinnedMesh).material}
                  skeleton={(skinnedMeshMap.skinnedMeshMap["WomanCloths"] as SkinnedMesh).skeleton}
                >
                  <meshBasicMaterial map={texture} color={new Color(light, light, light)} />
                </skinnedMesh>
              }
              {
                showFork &&
                <skinnedMesh
                  name="Fork"
                  geometry={(skinnedMeshMap.skinnedMeshMap["Fork"] as SkinnedMesh).geometry}
                  material={(skinnedMeshMap.skinnedMeshMap["Fork"] as SkinnedMesh).material}
                  skeleton={(skinnedMeshMap.skinnedMeshMap["Fork"] as SkinnedMesh).skeleton}
                >
                  <meshBasicMaterial map={tFork} color={new Color(light, light, light)} />
                </skinnedMesh>
              }
              {
                animation !== assets.human.animations.WalkCycle && animation !== assets.human.animations.TrotCycle &&
                <Plane
                  position={[0, 0.001, 0]}
                  rotation={[Math.PI * -0.5, 0, 0]}
                  scale={1.8}
                >
                  <meshBasicMaterial
                    color="black"
                    transparent
                    alphaMap={tShadow}
                    opacity={0.4}
                    depthWrite={false}
                  />
                </Plane>
              }
              {
                humanType === HumanTypes.farmer && exploreModeState === ExploreModeState.Enabled && head.current &&
                <Html distanceFactor={1} position={head.current.position} center >
                  <div id="farmerdance" className="w-72 h-96 bg-white/0 cursor-pointer absolute" onClick={dance} style={{ width: "500px", height: "1000px" }}></div>
                </Html>
              }
            </group>
          </group>
        </group>
      )}
    </>

    // <group ref={group} {...props} dispose={null}>
    //   <group name="Scene">
    //     <group name="HumanArmature">
    //       <primitive object={nodes["root-human"]} />
    //       <skinnedMesh
    //         name="Fork"
    //         geometry={(nodes.Fork as SkinnedMesh).geometry}
    //         skeleton={(nodes.Fork as SkinnedMesh).skeleton}
    //       >
    //         <meshBasicMaterial map={tFork} color={new Color(light, light, light)}/>
    //       </skinnedMesh>
    //       <skinnedMesh
    //         name="Farmer"
    //         geometry={(nodes.Farmer as SkinnedMesh).geometry}
    //         material={(nodes.Farmer as SkinnedMesh).material}
    //         skeleton={(nodes.Farmer as SkinnedMesh).skeleton}
    //       >
    //         <meshBasicMaterial map={tMan} color={new Color(light, light, light)} />
    //       </skinnedMesh>
    //       <Plane
    //         position={[0, 0.001, 0]}
    //         rotation={[Math.PI * -0.5, 0, 0]}
    //         scale={1.8}
    //       >
    //         <meshBasicMaterial
    //           color="black"
    //           transparent
    //           alphaMap={tShadow}
    //           opacity={0.4}
    //           depthWrite={false}
    //         />
    //       </Plane>
    //     </group>
    //   </group>
    // </group>
  );
}

useGLTF.preload(assets.human.model);
