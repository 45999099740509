import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export default create(subscribeWithSelector((set: Function) => {
    return {
        evtBarrel: 0,
        evtDone: 0,

        fireEvent(evt: string) {
            set((state: any) => {

                if (evt === "barrel"){
                    state.evtBarrel++;
                }
                else if (evt === "done"){
                    state.evtDone++;
                }

                return {};
            });
        }
    };
}));
