import { Effects, Html, Sky, Stars, useProgress } from "@react-three/drei";
import { Canvas, extend, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import useDarkMode from "../stores/useDarkMode";
import SceneManager from "./SceneManager";
import CameraControl from "./CameraControl";
import Debug from "./Debug";
import gsap from "gsap";
import { useMediaQuery } from "react-responsive";
import useDebugMode from "../stores/useDebugMode";
import { Camera, Group, Object3D, Vector3 } from "three";
import { images } from "../components/Images";
import useExperienceState, { ExperienceStates } from "../stores/useExperienceState";
import PostProcess from "./PostProcess";
import { useLocation } from "react-router-dom";

class Tweens {
  div: any;
}

export default function Experience() {
  const darkModeEnabled = useDarkMode((s) => s.darkModeEnabled);
  const debugModeEnabled = useDebugMode((s) => s.debugModeEnabled);

  const [height, setheight] = useState<number>(0);
  const [bg, setbg] = useState<string>("bg-stone-200");

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const { progress } = useProgress();

  const divRef = useRef<HTMLDivElement>(null!);

  const [tweens] = useState<Tweens>(new Tweens());

  const changeExperienceState = useExperienceState(s => s.changeExperienceState);
  const experienceState = useExperienceState(s => s.experienceState);
  const setProgressCompleted = useExperienceState(s => s.setProgressCompleted);

  const computeOverlayPosition = (el: Object3D, camera: Camera, size: { width: number; height: number }) => {
    const dir = new Vector3();
    camera.getWorldDirection(dir);
    const p = camera.position.clone().add(new Vector3(size.width * 0.5, size.height * 0.5, 0)).add(dir);
    return [p.x, p.y, p.z];
  }

  useEffect(() => {
    setheight(window.innerHeight);
  }, [isPortrait]);

  // useEffect(() => {
  //   divRefSceneTransition.current.style.opa.opacity = 0;
  // }, [])
  


  useEffect(() => {
    setProgressCompleted(false);

    if (progress < 100) {
      return;
    }

    setProgressCompleted(true);

    if (tweens.div) {
      tweens.div.kill();
    }

    tweens.div = gsap.to(divRef.current, {
      duration: 1.2,
      opacity: 0,
      ease: "power2.in"
    });

    tweens.div.then(() => {
      changeExperienceState(ExperienceStates.Ready);
      setbg("bg-stone-700")
    })

  }, [progress]);

  return (
    <>
      <div className={"fixed top-0 left-0 w-full h-screen -z-20 " + bg}>
      </div>
      <div className={"fixed top-0 left-0 w-full -z-10 touch-none " + bg} style={{ height: `${height}px` }}>
        <Canvas
          //  flat
          resize={{ scroll: false }}
          camera={{
            fov: 45,
            near: 0.1,
            far: 200,
            position: new Vector3(18, 5, 18)
          }}
        >

          {
            debugModeEnabled && <Debug />
          }

          {/* <PostProcess/> */}
          {/* <OrbitControls /> */}
          <CameraControl />

          {/* 
          {!darkModeEnabled && <fog attach="fog" args={[0xfffded, 10, 80]} />}
          {darkModeEnabled && <fog attach="fog" args={[0x474c54, 10, 50]} />} */}

          {darkModeEnabled && (
            <>
              <Stars
                radius={10}
                depth={50}
                count={500}
                factor={3}
                saturation={5}
                fade
                speed={1}
              />
              <Stars
                radius={20}
                depth={40}
                count={3000}
                factor={2}
                saturation={5}
                fade
                speed={2}
              />
            </>
          )}
          <Sky
            distance={450000}
            sunPosition={[0, darkModeEnabled ? -1 : 1, 0]}
          />
          <SceneManager />
        </Canvas>
      </div>

      {
        experienceState === ExperienceStates.Loading &&
        <div ref={divRef} className="fixed top-0 left-0 w-full h-screen z-20 bg-stone-700">
          <div className="w-screen h-screen  flex flex-row items-center justify-center">
            <img src={images.logo.src} className="w-60" alt={images.logo.alt} />
            <div className="idx-relative">
              <div className="relative text-center text-stone-200 mb-1 font-light" style={{ width: "15rem" }}>Chargement {Math.ceil(progress)}%</div>
              <div className="idx-loader-bg">
                <div className="idx-loader"></div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
