// eslint-disable-next-line import/no-anonymous-default-export
export default /* glsl */`
#include <fog_pars_vertex>

uniform vec2 uFrequency;
uniform float uTime;

varying vec2 vUv;
varying float vElevation;
varying vec4 vPos;
varying float vY;

void main()
{
    #include <begin_vertex>
    #include <project_vertex>
    #include <fog_vertex>
    
    float f = 15.0;
    vec4 localPosition = vec4( position, 1.);
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);

    float elevation = sin(position.x * uFrequency.x  - uTime* f) * 0.1 * (1.0 -uv.y);
    elevation += sin(position.y * uFrequency.y  - uTime* f * 0.3) * 0.1 * (1.0 - uv.y);

    modelPosition.z += elevation;
    modelPosition.x += elevation;

    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    gl_Position = projectedPosition;

    vUv = uv;
    vPos = modelPosition;
    vY = modelPosition.y;
    // vElevation = elevation;
    vElevation = 0.0;
}`