import { useFrame, useThree } from "@react-three/fiber";
import gsap from "gsap";
import { useEffect, useRef, useState } from "react";
import { Group, Vector3 } from "three";
import useCameraTarget from "../stores/useCameraTarget"
import useDebugMode from "../stores/useDebugMode";
import useExploreMode, { ExploreModeState } from "../stores/useExploreMode";

export class CameraParameters {
    cameraPosition: Vector3 = new Vector3();
    cameraLookAt: Vector3 = new Vector3();
}

class Tweens {
    position: any;
    lootAt: any;
}

export default function CameraControl() {

    const transitionDuration = useCameraTarget(s => s.transitionDuration);
    const targetPosition = useCameraTarget(s => s.targetPosition);
    const targetLookAt = useCameraTarget(s => s.targetLookAt);
    const forcedPosition = useCameraTarget(s => s.forcedPosition);
    const forcedLookAt = useCameraTarget(s => s.forcedPosition);
    const exploreModeState = useExploreMode(s => s.exploreModeState);
    const exploreModeCompleteTransition = useExploreMode(s => s.completeTransition);

    const { camera } = useThree();
    const [parameters] = useState<CameraParameters>(new CameraParameters());
    const [tweens] = useState<Tweens>(new Tweens());

    const staticLog = useDebugMode(s => s.registerStaticLog);

    useEffect(() => {
        parameters.cameraPosition.copy(forcedPosition);
        camera.position.copy(forcedPosition);
    }, [forcedPosition]);

    useEffect(() => {
        parameters.cameraLookAt.copy(forcedLookAt);
        camera.lookAt(forcedLookAt);
    }, [forcedLookAt]);

    useFrame(() => {
        if (exploreModeState === ExploreModeState.Enabled) {
            parameters.cameraPosition.copy(camera.position);

            const dir = new Vector3();
            camera.getWorldDirection(dir);
            dir.multiplyScalar(camera.position.length());

            parameters.cameraLookAt.copy(camera.position.clone().add(dir))
        }
        else {
            camera.position.copy(parameters.cameraPosition);
            camera.lookAt(parameters.cameraLookAt);
        }

        const p = camera.position;
        staticLog("Camera Position", `[${p.x.toFixed(2)}, ${p.y.toFixed(2)}, ${p.z.toFixed(2)}]`);
    })

    // useEffect(() => {
    //     if (camera && pivot.current) {
    //         camera.parent = pivot.current
    //     }

    // }, [camera, pivot])

    useEffect(() => {
        const p = new Vector3().copy(targetPosition);

        if (tweens.position) {
            tweens.position.kill();
        }

        if (transitionDuration <= 0) {
            parameters.cameraPosition.copy(targetPosition);
        }
        else {
            tweens.position = gsap.to(parameters.cameraPosition, {
                duration: transitionDuration,
                x: p.x,
                y: p.y,
                z: p.z,
                ease: "power1.inOut"
            });

            tweens.position.then(() => {
                exploreModeCompleteTransition();
            });
        }

    }, [targetPosition])

    useEffect(() => {

        if (tweens.lootAt) {
            tweens.lootAt.kill();
        }

        if (transitionDuration <= 0) {
            parameters.cameraLookAt.copy(targetLookAt);
        }

        tweens.lootAt = gsap.to(parameters.cameraLookAt, {
            duration: transitionDuration,
            x: targetLookAt.x,
            y: targetLookAt.y,
            z: targetLookAt.z,
            ease: "power1.inOut"
        });


    }, [targetLookAt])

    return <>
        {/* <group ref={arm} >
            <group position={[0,0,parameters.armLength]}>
                <group ref={pivot}></group>
            </group>
        </group> */}
    </>
}