import { Suspense, useEffect, useRef, useState } from "react";
import { Plane, Html, Sphere, Line } from "@react-three/drei";
import { assets } from "./Assets";
import useSkinnedMesh from "./UseSkinnedMesh";
import useOcclusionStore from "../stores/useOcclusionStore";
import useLoadTexture from "./UseLoadTexture";
import { GroupProps, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { Bone, Color, Euler, Group, MathUtils, NormalAnimationBlendMode, Object3D, Vector3 } from "three";
import useGetLighting from "./UseGetLighting";
import useSyncTime from "../stores/useSyncTime";
import useFootSteps from "../stores/useFootSteps";
import useDustSteps from "../stores/useDustSteps";
import HorseFoot from "./HorseFoot";
import useSyncLocomotion from "../stores/useSyncLocomotion";
import { MathUtils2 } from "./MathUtils2";
import useDebugMode from "../stores/useDebugMode";
import { computeBlendTree, initBlendTree } from "./ComputeBlendTree";
import useAnimatedSkinnedMesh from "./UseAnimatedSkinnedMesh";

type HorseProps = GroupProps & {
  randomAnimPrewarm?: boolean;
  bodyTexture: string;
  hairTexture: string;
  animation?: string;
  animTimeScale?: number;
  idx: number;
  hair?: HorseHair;
}

type FootPos = {
  last: number;
  dir: number
}

export enum HorseHair {
  short,
  long,
  double
}

export function Horse({ hair, randomAnimPrewarm, bodyTexture, hairTexture, animation, animTimeScale, idx, ...props }: HorseProps) {
  const group = useRef<Group>(null!);
  const loadedHairTexture = useLoadTexture(hairTexture);
  const loadedBodyTexture = useLoadTexture(bodyTexture);
  const textureShadow = useLoadTexture(assets.horse.textures.shadow);

  const skinnedMeshMap = useAnimatedSkinnedMesh(assets.horse.model, "Horse", idx, animTimeScale, animation, assets.horse.locomotion, randomAnimPrewarm);

  const occlude = useOcclusionStore(s => s.occlude);
  const lightIntensity = useGetLighting(group);

  useEffect(() => {
  }, [lightIntensity])

  const root = useRef<Group>(null!);

  return (
    <>
      {!skinnedMeshMap ? (
        <Plane position={[0, 0.025, 0]} rotation={[0, 0, 0]} scale={2.5}>
          <meshBasicMaterial map={loadedBodyTexture} opacity={0} transparent />
          <meshBasicMaterial map={loadedHairTexture} opacity={0} transparent />
        </Plane>
      ) : (

        <group ref={group} {...props} dispose={null}>
          <group name="Scene">
            <group name="HorseArmature" ref={root}>
              <primitive object={skinnedMeshMap.objMap["def-horse-hips"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-spine-1"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-spine-2"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-neck-1"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-hair-1"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-hair-2"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-hair-3"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-neck2"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-head"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-earl"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-earr"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-front-leg-1l"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-front-leg-2l"]} />
              <HorseFoot object={skinnedMeshMap.objMap["def-horse-front-leg-3l"]} idx={idx} />
              <primitive object={skinnedMeshMap.objMap["def-horse-front-leg-1r"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-front-leg-2r"]} />
              <HorseFoot object={skinnedMeshMap.objMap["def-horse-front-leg-3r"]} idx={idx} />
              <primitive object={skinnedMeshMap.objMap["def-horse-tail-1"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-tail-2"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-tail-3"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-tail-4"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-back-leg-1l"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-back-leg-2l"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-back-leg-3l"]} />
              <HorseFoot object={skinnedMeshMap.objMap["def-horse-back-leg-4l"]} idx={idx} />
              <primitive object={skinnedMeshMap.objMap["def-horse-back-leg-1r"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-back-leg-2r"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-back-leg-3r"]} />
              <HorseFoot object={skinnedMeshMap.objMap["def-horse-back-leg-4r"]} idx={idx} />
              <primitive object={skinnedMeshMap.objMap["def-horse-shoulderl"]} />
              <primitive object={skinnedMeshMap.objMap["def-horse-shoulderr"]} />
              <primitive object={skinnedMeshMap.objMap["ctrl-horse-hips-rotator"]} />
              <skinnedMesh
                name="HorseMesh"
                geometry={skinnedMeshMap.skinnedMeshMap["HorseMesh"].geometry}
                material={skinnedMeshMap.skinnedMeshMap["HorseMesh"].material}
                skeleton={skinnedMeshMap.skinnedMeshMap["HorseMesh"].skeleton}
              >
                <meshBasicMaterial map={loadedBodyTexture} color={new Color(lightIntensity, lightIntensity, lightIntensity)} />
              </skinnedMesh>
              {/* 
              <Plane rotation={[0, -2,0]} scale={2} position={[0,2,-2]}>
              <meshBasicMaterial map={loadedHairTexture} color={new Color(lightIntensity, lightIntensity, lightIntensity)} />
              </Plane>
              <Plane rotation={[0, -2,0]} scale={2} position={[-2,2,0]}>
              <meshBasicMaterial map={loadedBodyTexture} color={new Color(lightIntensity, lightIntensity, lightIntensity)} />
              </Plane> */}
              {hair === HorseHair.short &&
                <skinnedMesh
                  name="HorseHairShort"
                  geometry={skinnedMeshMap.skinnedMeshMap["HorseHairShort"].geometry}
                  material={skinnedMeshMap.skinnedMeshMap["HorseHairShort"].material}
                  skeleton={skinnedMeshMap.skinnedMeshMap["HorseHairShort"].skeleton}
                >
                  <meshBasicMaterial map={loadedHairTexture} color={new Color(lightIntensity, lightIntensity, lightIntensity)} />
                </skinnedMesh>
              }

              {hair === HorseHair.double &&
                <skinnedMesh
                  name="HorseHairLongDouble"
                  geometry={skinnedMeshMap.skinnedMeshMap["HorseHairLongDouble"].geometry}
                  material={skinnedMeshMap.skinnedMeshMap["HorseHairLongDouble"].material}
                  skeleton={skinnedMeshMap.skinnedMeshMap["HorseHairLongDouble"].skeleton}
                >
                  <meshBasicMaterial map={loadedHairTexture} side={THREE.BackSide} color={new Color(lightIntensity, lightIntensity, lightIntensity)} />
                </skinnedMesh>
              }

              {hair === HorseHair.long &&
                <skinnedMesh
                  name="HorseHairLong"
                  geometry={skinnedMeshMap.skinnedMeshMap["HorseHairLong"].geometry}
                  material={skinnedMeshMap.skinnedMeshMap["HorseHairLong"].material}
                  skeleton={skinnedMeshMap.skinnedMeshMap["HorseHairLong"].skeleton}
                >
                  <meshBasicMaterial map={loadedHairTexture} color={new Color(lightIntensity, lightIntensity, lightIntensity)} />
                </skinnedMesh>
              }

              <group rotation={[0, MathUtils.DEG2RAD * 180, 0]}>
                <Plane
                  position={[0, 0.025, 0]}
                  rotation={[Math.PI * -0.5, 0, 0]}
                  scale={[1.2, 2.4, 1]}
                >
                  <meshBasicMaterial
                    color="black"
                    transparent
                    alphaMap={textureShadow}
                    opacity={0.3}
                    depthWrite={false}
                  />
                </Plane>
              </group>
              {/* <Html position={[0, 2.2, 0]} occlude={occlude}>
                <div className="px-3 text-lg text-center align-middle transition-all duration-75 cursor-pointer select-none bg-slate-500 bg-opacity-30 rounded-xl text-slate-50 hover:text-2xl outline-slate-200/50 outline-2 outline">
                  <p>?</p>
                </div>
              </Html> */}
            </group>
          </group>
        </group>


        // <group ref={group} {...props} dispose={null}>
        //   <group name="Scene">
        //     <group name="HorseArmature">
        //       <primitive object={skinnedMeshMap.objMap["horse-root"]} />
        //       <skinnedMesh
        //         name="HorseMesh"
        //         geometry={skinnedMeshMap.skinnedMeshMap["HorseMesh"].geometry}
        //         material={skinnedMeshMap.skinnedMeshMap["HorseMesh"].material}
        //         skeleton={skinnedMeshMap.skinnedMeshMap["HorseMesh"].skeleton}
        //       >
        //         <meshBasicMaterial map={texture} color={new Color(lightIntensity, lightIntensity, lightIntensity)} />
        //       </skinnedMesh>
        //       <group rotation={[0, MathUtils.DEG2RAD * 180, 0]}>
        //       <Plane
        //         position={[0, 0.025, 0]}
        //         rotation={[Math.PI * -0.5, 0, 0]}
        //         scale={2.5}
        //       >
        //         <meshBasicMaterial
        //           color="black"
        //           transparent
        //           alphaMap={textureShadow}
        //           opacity={0.5}
        //           depthWrite={false}
        //         />
        //       </Plane>
        //       </group>
        //       {/* <Html position={[0, 2.2, 0]} occlude={occlude}>
        //         <div className="px-3 text-lg text-center align-middle transition-all duration-75 cursor-pointer select-none bg-slate-500 bg-opacity-30 rounded-xl text-slate-50 hover:text-2xl outline-slate-200/50 outline-2 outline">
        //           <p>?</p>
        //         </div>
        //       </Html> */}
        //     </group>
        //   </group>
        // </group>
      )}
    </>
  );
}
