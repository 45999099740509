import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'
import { LoadedSkinnedMesh } from "../three/UseSkinnedMesh";

export default create(subscribeWithSelector((set: Function) => {
    return {
        available: [],

        push: (loadedSkinnedMesh: LoadedSkinnedMesh) => {
            set((state: any) => {
                if (loadedSkinnedMesh) {
                    state.available.push(loadedSkinnedMesh);
                }
                return {};
            });
        },

        pop: (customId: string): LoadedSkinnedMesh | undefined => {
            let found: LoadedSkinnedMesh | undefined = undefined;

            set((state: any) => {
                const localAvailable: LoadedSkinnedMesh[] = state.available;
                found = localAvailable.find(c => c.customId === customId);
                return {};
            });

            return found;
        },
    };
}));
