import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export type Log = {
  message: string;
  color?: string;
  time: number;
}

export type StaticLog = {
  key: string;
  value: string;
  color?: string;
  time: number;
} 

export default create(subscribeWithSelector((set: Function) => {
  return {
    debugModeEnabled: false,
    logs: [],
    staticLogs: [],
    logIdx: 0,
    clock: null,
    showMovementPathEnabled: false,

    toggle: () => {
      set((state: any) => {
        state.debugModeEnabled = !state.debugModeEnabled;
        return {};
      });
    },

    enableMovementPath: (enabled: boolean) => {
      set((state: any) => {
        console.log("allo")
        state.showMovementPathEnabled = enabled;
        return {};
      });
    },

    setClock: (clock: THREE.Clock) => {
      set((state: any) => {
        state.clock = clock;
        return {};
      });
    },

    registerLog: (message: string, color?: string) => {
      set((state: any) => {
        if (!state.debugModeEnabled) {
          return {};
        }
        const localLogs: Log[] = state.logs;
        const time = state.clock ? state.clock.elapsedTime: -1;
        localLogs.splice(0,0, {message, color, time});
        state.logIdx++;
        return {};
      });
    },

    registerStaticLog: (key: string, value: string, color?: string) => {
      set((state: any) => {
        if (!state.debugModeEnabled) {
          return {};
        }
        const localLogs: StaticLog[] = state.staticLogs;
        let existing = localLogs.find(x => x.key === key);
        const time = state.clock ? state.clock.elapsedTime: -1;
        if (!existing) {
          localLogs.push({key, value, color, time});
        } else {
          existing.value = value;
          existing.time = time;
          existing.color = color;
        }
        state.logIdx++;
        return {};
      });
    },
  };
}));
