import { reverse } from "dns";
import { useEffect } from "react";
import { images } from "./Images";

type Props = {
  img: any,
  label: React.ReactNode;
  animationDelay?: string;
}

export default function CourseThumbnail({ img, label, animationDelay }: Props) {
  return (
    <>
      <div className={"flex flex-col portrait:flex-row mx-5 lg:mx-10 mb-5 portrait:mb-3 place-items-center appearFromRight hover:scale-95 transition-all duration-1000 ease-in-out"}
        style={{ animationDelay: animationDelay }}>
        <div className="w-36 h-36 lg:w-64 lg:h-64 relative justify-center items-center justify-items-center flex  portrait:mr-5">
          <div className="rounded-full w-24 h-24 lg:w-36 lg:h-36 outline outline-offset-4 outline-2 lg:outline-2 outline-amber-600 absolute shadow-lg"></div>
          <img src={img.src} alt={img.alt} className=" w-max h-max object-contain absolute rounded-md" />
        </div>
        <div className="text-xl flex justify-center portrait:text-sm font-extralight">{label}</div>
      </div>
    </>
  )
}