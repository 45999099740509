import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export default create(subscribeWithSelector((set: Function) => {
  return {
    darkModeEnabled: false,
    forceDarkModeEnabled: false,

    toggle: () => {
      set((state: any) => {
        state.darkModeEnabled = !state.darkModeEnabled;
        return {};
      });
    },

    setDark: (isDark: Boolean) => {
      set((state: any) => {
        state.darkModeEnabled = isDark;
        return {};
      });
    },

    setForceDarkModeEnabled: (isDark: Boolean) => {
      set((state: any) => {
        state.forceDarkModeEnabled = isDark;
        return {};
      });
    },
  };
}));
