import { useFrame } from "@react-three/fiber";
import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { Group, MathUtils, Vector3 } from "three";
import { MathUtils2 } from "./MathUtils2";
import useSyncTime from "../stores/useSyncTime";
import useDustSteps from "../stores/useDustSteps";

type Props = {
  anglePerSec?: number;
  radius?: number;
  position?: Vector3;
  children: React.ReactNode
  idx: number;
  dustFactor?: number;
}

type Radius = {
  initial: number,
  current: number,
  target: number,
  duration: number,
  startTime: number
};

export default function MovementCircular({ idx, dustFactor, anglePerSec = -10, radius = 3, position = new Vector3(), children }: Props) {
  const groupRef = useRef<Group>(null!);

  const [angle, setangle] = useState(0);
  const [r] = useState<Radius>({ initial: radius, current: radius, target: radius, duration: 0, startTime: 0 });

  const syncDustFactor = useDustSteps(s => s.syncDustFactor);

  useEffect(() => {
    syncDustFactor(idx, dustFactor || 1);
  }, [idx, dustFactor]);


  useFrame((state, delta) => {
    setangle(angle + delta * (anglePerSec + Math.random() * 0.1) * MathUtils.DEG2RAD);
    if (state.clock.elapsedTime - r.startTime > r.duration) {
      r.startTime = state.clock.elapsedTime;
      r.duration = Math.random() * 5;
      r.target = Math.random() * (r.initial * 0.25) + r.initial;
    }

    r.current = MathUtils2.moveNumberToward(r.current, r.target, delta * 0.05);
  });

  return (
    <group position={position} rotation={[0, angle, 0]} ref={groupRef}>
      <group position={[r.current, 0, 0]}>{children}</group>
    </group>
  );
}
