import { useEffect } from "react";
import useDarkMode from "../stores/useDarkMode";
import useFootSteps from "../stores/useFootSteps";
import useScene from "./UseScene";
import useScrollCameraEffect from "./UseScrollCameraEffect";
import DustParticle from "./DustParticle";
import FootSteps from "./FootSteps";
import CameraManager from "./CameraManager";
import { Transform } from "./Transform";
import { Horse, HorseHair } from "./Horse";
import { assets } from "./Assets";
import { Gear } from "./Gear";
import { Human, HumanTypes } from "./Human";
import { SteBrigitte } from "./SteBrigitte";
import MovementPath from "./MovementPath";
import Stopwatch from "./Stopwatch";
import Tree from "./Tree";
import Herbs from "./Herbs";
import Grass from "./Grass";
import { MathUtils, Vector3 } from "three";
import { LightEffect } from "./LightEffect";
import LeafParticle from "./LeafParticle";
import useFakeLight from "./UseFakeLight";
import useExploreMode from "../stores/useExploreMode";

export default function CompetitionScene() {
    useScene("/competition");

    useScrollCameraEffect();

    const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);

    useFakeLight(new Vector3(1.17, 0.00, -11.95), 14, "competitionLight1");
    useFakeLight(new Vector3(-7.78, 0.00, 3.02), 14, "competitionLight2");
    useFakeLight(new Vector3(8.29, 0.00, 3.02), 14, "competitionLight3");
    useFakeLight(new Vector3(-0.48, 0.00, 14.38), 14, "competitionLight4");
    useFakeLight(new Vector3(-0.48, 0.00, -23.77), 5, "competitionLight4");

    useFakeLight(new Vector3(14.30, 2.1, -10.45), 14, "competitionLightpath1");
    useFakeLight(new Vector3(14.30, 0.00, 20.83), 14, "competitionLightpath2");
    useFakeLight(new Vector3(-14.30, 0.00, 20.83), 14, "competitionLightpath3");
    useFakeLight(new Vector3(-14.30, 0.00, -20.83), 14, "competitionLightpath4");

    useFakeLight(new Vector3( -17.1, 2.52, -3.55), 14, "competitionBleachers");

   

    const clearSteps = useFootSteps(s => s.clear);

    useEffect(() => {
        clearSteps();
    }, [])

    return (
        <>
            {!darkModeEnabled && <fog attach="fog" args={[0xc4ab8d, 35, 150]} />}
            {darkModeEnabled && <fog attach="fog" args={[0x474c54, 35, 130]} />}
            <DustParticle />
            <FootSteps />
            <CameraManager
                original={new Transform(18.92, 23.70, 42.73, 0, 12, 0)}
                portrait={new Transform(20.79, 30.14, 60.68, -3, 12, -4)}
                landscape={new Transform(20.89, 11.49, 24.82, -3, 9, -2.5)}
                explore={new Transform(14.14, 38.86, -55.41, 0, 0, 0)}
                // orbitCtrl
                makeDefault
                minPolarAngle={Math.PI * 0.1}
                maxPolarAngle={Math.PI * 0.49}
                minAzimuthAngle={-4*Math.PI }
                maxAzimuthAngle={4*Math.PI}
                maxDistance={80}
                minDistance={10}
                maxZoom={40}
                // minZoom={2}
                panSpeed={0.3}
                zoomSpeed={0.8}
                rotateSpeed={0.4}
                enableDamping
                enablePan={true}
                // enablePan
                dampingFactor={0.05}
            />
            <SteBrigitte />
            {/* <Human
                animTimeScale={0.5}
                humanType={HumanTypes.farmer}
                animation={assets.human.animations.IdleApple}
                idx={1}
                position={[-2, 6, -25]}
            /> */}
            <Stopwatch />
            {darkModeEnabled && <LightEffect />}
            {/* {
                [...Array(10)].map((x, i) =>
                    <Human
                        animTimeScale={0.5}
                        humanType={HumanTypes.farmer}
                        animation={assets.human.animations.IdleApple}
                        idx={1}
                        position={[-20,5,-i]}
                        rotation={[0, MathUtils.DEG2RAD * 90, 0]}
                    />
                )
            } */}
            <MovementPath idx={0} path={assets.path.competitionBarel} speed={14} steering={6} hasStopwatch>
                <Horse
                    animTimeScale={1.3}
                    idx={0}
                    hair={HorseHair.long}
                    bodyTexture={assets.horse.textures.pitaBody}
                    hairTexture={assets.horse.textures.pitaHair}
                />
                <Gear
                    position={[0, 0, 0]}
                    scale={1}
                    animTimeScale={1.3}
                    idx={0}
                />
                <Human
                    animTimeScale={1.3}
                    humanType={HumanTypes.youngWoman}
                    idx={0}
                    position={[0, 0, 0]} />
            </MovementPath>

            <MovementPath idx={1} path={assets.path.competitionBleachers} speed={3} steering={4}>
                <Human
                    animTimeScale={1.3}
                    humanType={HumanTypes.youngMan}
                    animation={assets.human.animations.Walk}
                    idx={1}
                    position={[0, 0, 0]} />
            </MovementPath>

            <Human
                animTimeScale={1.5}
                humanType={HumanTypes.farmer}
                animation={assets.human.animations.BrushHorse}
                idx={2}
                scale={1.2}
                position={[0.00, 6.27, -20.5]}
                rotation={[0, MathUtils.DEG2RAD * 90, 0]}
            />

            <Human
                animTimeScale={1.5}
                humanType={HumanTypes.farmer}
                animation={assets.human.animations.sit}
                idx={3}
                scale={1.2}
                position={[-17.1, 2.52, -8.77]}
                rotation={[0, MathUtils.DEG2RAD * 90, 0]}
            />
            <Human
                animTimeScale={1.5}
                humanType={HumanTypes.youngMan}
                animation={assets.human.animations.sit}
                idx={4}
                scale={1.2}
                position={[-17.1, 2.52, -7.55]}
                rotation={[0, MathUtils.DEG2RAD * 90, 0]}
            />
            <Human
                animTimeScale={1.5}
                humanType={HumanTypes.youngWoman}
                animation={assets.human.animations.sit}
                idx={5}
                scale={1.2}
                position={[-17.1, 2.52, -5.77]}
                rotation={[0, MathUtils.DEG2RAD * 90, 0]}
            />

            <Tree
                position={[-18.86, 0, -33.072]}
                rotation={[0, 0, 0]}
                scale={2.37926}
            />
            <Tree
                position={[-11.154, 0, -34.748]}
                rotation={[0, 0, 0]}
                scale={[4.056, 3.052, 3.052]}
            />
            <Tree
                position={[20.584, 0, -27.915]}
                rotation={[0, MathUtils.DEG2RAD * 127.73, 0]}
                scale={[4.056, 3.052, 3.052]}
            />
            {/* <Tree
                position={[-24, 0, -33]}
                rotation={[0, -0.523, 0]}
                scale={[5, 2.5, 3]}
            />
            <Tree
                position={[-5, 0, -36]}
                rotation={[0, -0.523, 0]}
                scale={3}
            /> */}
        </>
    )
}