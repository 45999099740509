import { PositionalAudio } from "@react-three/drei";
import { assets } from "./Assets";
import CameraManager from "./CameraManager";
import { FarmerDance } from "./FarmerDance";
import Grass from "./Grass";
import { Paddock } from "./Paddock";
import { Transform } from "./Transform";
import Tree from "./Tree";
import useScene from "./UseScene";

export default function DanceScene() {

    useScene("/dance");

    return <>
        <FarmerDance />

        <group
            position={[0, -.54, 0]}>

            <Grass model={assets.paddock.grassModel} wind={3} />
            <Paddock />

            <Tree position={[-4.58, 0.52, 9.51]} scale={1} />
            <Tree
                position={[11.69, 0.52, 4.28]}
                rotation={[0, -0.523, 0]}
                scale={0.82}
            />
            <Tree
                position={[11.43, 0.52, -2.3]}
                rotation={[0, 1.047, 0]}
                scale={0.95}
            />
        </group>
        <CameraManager
            original={new Transform(3, 2, 3, 0, 1, 0)}
            // original={new Transform(4.93, 2.74, 3.30, 0, 0, 0)}
            portrait={new Transform(5, 2, 5, 0, 1, 0)}
            landscape={new Transform(3, 2, 3, 0, 1, 0)}
            explore={new Transform(6, 2, 17, 0, 0, 0)}
            // orbitCtrl
            makeDefault
            minPolarAngle={Math.PI * 0.1}
            maxPolarAngle={Math.PI * 0.49}
            maxDistance={50}
            minDistance={10}
            maxZoom={10}
            minZoom={10}
            panSpeed={0.3}
            zoomSpeed={0.3}
            rotateSpeed={0.2}
            enableDamping
            enablePan={false}
            dampingFactor={0.05}
        />
    </>
}