/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useMemo, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { assets } from "./Assets";
import { Color, MathUtils, Mesh, ShaderMaterial, Vector2, Vector3 } from "three";
import useLoadTexture from "./UseLoadTexture";
import { GroupProps, useFrame } from "@react-three/fiber";
import Tree, { TreeTypes } from "./Tree";
import { Horse, HorseHair } from "./Horse";
import useDarkMode from "../stores/useDarkMode";
import scrollVert from "../shaders/scroll-vert";
import scrollFrag from "../shaders/scroll-frag";
import MovementScroll from "./MovementScroll";
import FootSteps from "./FootSteps";
import Grass from "./Grass";
import { Gear } from "./Gear";
import { Human, HumanTypes } from "./Human";
import DustParticle from "./DustParticle";
import LeafParticle from "./LeafParticle";
import SnowParticle from "./SnowParticle";
import { Sleigh } from "./Sleigh";
import { useMediaQuery } from "react-responsive";
import useFakeLight from "./UseFakeLight";

import * as THREE from "three";
import useDustSteps from "../stores/useDustSteps";

export function MiniScenes(props: GroupProps) {
    const { nodes } = useGLTF(assets.miniScenes.model);

    const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);

    const texture = useLoadTexture(darkModeEnabled ? assets.miniScenes.textures.miniscenes : assets.miniScenes.textures.miniscenes);
    const textureScroll = useLoadTexture(assets.miniScenes.textures.scrolling);
    const textureMask = useLoadTexture(assets.campGround.textures.mask);
    const textureDirt = useLoadTexture(assets.miniScenes.textures.snow);

    const scrollShader = useRef<ShaderMaterial>(null!);

    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    
    useFakeLight(new Vector3(10, 0.00, 0), 14, "minisceneLight");

    const syncDustFactor = useDustSteps(s => s.syncDustFactor);
        
    useEffect(() => {
        syncDustFactor(3, 0.4);
        syncDustFactor(2, 0.03);
    }, []);

    const uniforms = useMemo(
        () => {
          let obj = THREE.UniformsLib.fog;
          obj = Object.assign(
            {
                uTime: { value: 0 },
                uMap: { value: textureScroll },
                uMapDirt: { value: textureDirt },
                uMapMask: { value: textureMask },
                uLight: { value: 0.5 },
                uSpeed: { value: new Vector2(0, 0.21) },
                uScale: { value: 1 },
                uRange: { value: 20 },
                uSoftRange: { value: 0.4 },
            }, obj);
          return obj;
        },
        [textureScroll, textureDirt, textureMask]
      );

    useFrame((state, delta) => {
        if (darkModeEnabled) {
            scrollShader.current.uniforms.uLight.value = 0.1;
        } else {

            scrollShader.current.uniforms.uTime.value += delta;
            scrollShader.current.uniforms.uLight.value = 1;
        }
        // scrollShader.current.uniforms.uMap.value = darkModeEnabled ? tWaterNight : tWater;

    });

    const treeScrollRange = 6;

    return (
        <group {...props} dispose={null}>
            <group>
                <group
                    position={[0, 0, 0]}>
                    <mesh
                        name="Winter"
                        castShadow
                        receiveShadow
                        geometry={(nodes.Winter as Mesh).geometry}
                        material={(nodes.Winter as Mesh).material}
                    >
                        <meshBasicMaterial map={texture}
                            color={darkModeEnabled ? new Color(0.1, 0.1, 0.1) : new Color(1, 1, 1)} />
                    </mesh>
                    <mesh
                        name="WinterScroll"
                        castShadow
                        receiveShadow
                        geometry={(nodes.WinterScroll as Mesh).geometry}
                        material={(nodes.WinterScroll as Mesh).material}
                    >
                        <shaderMaterial
                            fog
                            ref={scrollShader}
                            vertexShader={scrollVert}
                            fragmentShader={scrollFrag}
                            toneMapped={false}
                            uniforms={uniforms}
                        />
                    </mesh>
                    {!darkModeEnabled && <>
                        <SnowParticle />
                        <group position={new Vector3(0, 0, -3)}>
                            <Horse
                                idx={3}
                                animTimeScale={1.4}
                                animation={assets.horse.animations.TrotCycle}
                                hair={HorseHair.short}
                                bodyTexture={assets.horse.textures.hopeBody}
                                hairTexture={assets.horse.textures.hopeHair}
                            />
                            <Gear
                                idx={3}
                                animTimeScale={1.4}
                                animation={assets.gear.animations.TrotCycle}
                            />
                            <Human
                                animTimeScale={1.4}
                                humanType={HumanTypes.youngMan}
                                animation={assets.human.animations.TrotCycle}
                                idx={3}
                            />
                        </group>
                        <group position={new Vector3(1.5, 0.05, 3)}>
                            <Horse
                                idx={2}
                                animTimeScale={1.4}
                                animation={assets.horse.animations.TrotCycle}
                                hair={HorseHair.double}
                                bodyTexture={assets.horse.textures.okelaBody}
                                hairTexture={assets.horse.textures.okelaHair}
                            />
                            {/* <Gear
                            idx={2}
                            animation="WalkCycle"
                        />
                        <Human
                            humanType={HumanTypes.farmer}
                            animation="WalkCycle"
                            idx={2}
                        /> */}
                            <Human

                                humanType={HumanTypes.farmer}
                                animation={assets.human.animations.DriveSleigh}
                                animTimeScale={1.4}
                                idx={2}
                            />
                            {/* <Human

                            position={[0.4, 0.01, -3.24]}
                            humanType={HumanTypes.farmer}
                            animation="DriveSleigh"
                            idx={4}
                        /> */}
                            <Sleigh
                                position={[0, 0, 0]}
                                idx={2}
                                animTimeScale={1.4}
                                animation="Sleigh_TrotCycle" />
                        </group>
                    </>
                    }
                    <MovementScroll p0={new Vector3(0, 0, 15)} p1={new Vector3(0, 0, -100)} speed={darkModeEnabled ? 0 : 2.2} rangeRespawn={8}>

                        <DustParticle scrolling range={6} />
                        <FootSteps scrolling range={6} />
                        <Tree
                            treeTypes={TreeTypes.PineWinter}
                            scrolling
                            position={[-2.2, 0, 18.5]}
                            rotation={[0, -0.523, 0]}
                            scrollRange={treeScrollRange}
                            scale={0.82} />
                        <Tree
                            treeTypes={TreeTypes.LeafWinter}
                            scrolling
                            position={[-2.5, 0, 2]}
                            rotation={[0, -0.523, 0]}
                            scrollRange={treeScrollRange}
                            scale={0.82} />
                        <Tree

                            treeTypes={TreeTypes.LeafWinter}
                            scrolling
                            position={[-2.5, 0, 5]}
                            rotation={[0, 3.14, 0]}
                            scrollRange={treeScrollRange}
                            scale={1.2} />
                        <Tree
                            treeTypes={TreeTypes.PineWinter}
                            scrolling
                            position={[3, 0, .3]}
                            rotation={[0, -0.523, 0]}
                            scrollRange={treeScrollRange}
                            scale={[.5, .5, .5]} />
                        <Tree
                            treeTypes={TreeTypes.PineWinter}
                            scrolling
                            position={[-2.8, 0, 12]}
                            rotation={[0, -0.23, 0]}
                            scrollRange={treeScrollRange}
                            scale={[.5, .8, .5]} />
                    </MovementScroll>

                </group>

                <group
                    position={isPortrait ? [-10, -3, 3.65] : [0, 0, 11]}>
                    <mesh
                        name="Autumn"
                        castShadow
                        receiveShadow
                        geometry={(nodes.Autumn as Mesh).geometry}
                        material={(nodes.Autumn as Mesh).material}
                    >
                        <meshBasicMaterial map={texture} color={darkModeEnabled ? new Color(0.1, 0.1, 0.1) : new Color(1, 1, 1)} />
                    </mesh>
                    {!darkModeEnabled &&
                        <group>
                            <LeafParticle position={[0, 0, 0]} />
                            <Human
                                humanType={HumanTypes.youngMan}
                                animation={assets.human.animations.IdleApple}
                                idx={0}
                            />
                            <Horse
                                idx={0}
                                animation={assets.horse.animations.IdleApple}
                                hair={HorseHair.long}
                                bodyTexture={assets.horse.textures.pitaBody}
                                hairTexture={assets.horse.textures.pitaHair}
                            />
                        </group>
                    }
                    <Tree
                        treeTypes={TreeTypes.LeafAutumn}
                        position={[-1.33, -0.05, -0.26]}
                        rotation={[Math.PI, -1.56, Math.PI]}
                        wind={5}
                        shaderYOffset={isPortrait ? 2: 0} />
                    <Tree
                        treeTypes={TreeTypes.LeafAutumn}
                        position={[0.99, -0.05, 1.85]}
                        rotation={[-Math.PI, 0.4, -Math.PI]}
                        scale={1.01}
                        shaderYOffset={isPortrait ? 2: 0}
                        wind={5} />
                </group>

                <group
                    position={isPortrait ? [-9, 1, -3.65] : [0, 0, -11]}>
                    <mesh
                        name="Summer"
                        castShadow
                        receiveShadow
                        geometry={(nodes.Summer as Mesh).geometry}
                        material={(nodes.Summer as Mesh).material}
                    >
                        <meshBasicMaterial map={texture} color={darkModeEnabled ? new Color(0.1, 0.1, 0.1) : new Color(1, 1, 1)} />
                    </mesh>
                    {!darkModeEnabled &&
                        <group>
                            <Human
                                humanType={HumanTypes.youngWoman}
                                animation={assets.human.animations.BrushHorse}
                                idx={1}
                            />
                            <Horse
                                idx={1}
                                animation={assets.horse.animations.Idle}
                                hair={HorseHair.double}
                                bodyTexture={assets.horse.textures.mooglyBody}
                                hairTexture={assets.horse.textures.mooglyHair}
                            />
                        </group>
                    }
                    <Tree
                        position={[-1.08, -0.05, 1.85]}
                        rotation={[Math.PI, -1.26, Math.PI]}
                        wind={5}
                        shaderYOffset={isPortrait ? -1: 0} />
                </group>
            </group>
        </group>
    );
}

useGLTF.preload(assets.miniScenes.model);
