import { useEffect, useRef } from "react";
import useDarkMode from "../stores/useDarkMode";

export default function AutoDarkDetection() {

    const div = useRef<HTMLDivElement>(null!);
    const setForceDarkModeEnabled = useDarkMode(s => s.setForceDarkModeEnabled);

    useEffect(() => {
        const isAutoDark = getComputedStyle(div.current).backgroundColor != 'rgb(255, 255, 255)';

        if (isAutoDark) {
            setForceDarkModeEnabled(true);
        }

    }, []);

    return (
        <>
            <div ref={div} style={{ display: "none", backgroundColor: "canvas", colorScheme: "light" }}>
            </div>
        </>
    );
}