import { Euler, MathUtils, Vector3 } from "three";
import { assets } from "./Assets";
import CameraManager from "./CameraManager";
import { Gear } from "./Gear";
import { Horse, HorseHair } from "./Horse";
import { Human, HumanTypes } from "./Human";
import MovementPath from "./MovementPath";
import { Stable } from "./Stable";
import { Transform } from "./Transform";
import useScrollCameraEffect from "./UseScrollCameraEffect";
import useScene from "./UseScene";

export default function StableScene() {

    useScene("/demi-pension");

    useScrollCameraEffect(new Vector3(4, -1, 8));

    return (
        <>
            <CameraManager
                original={new Transform(8, 3, 10, 0, 3, 0)}
                landscape={new Transform(8, 3, 10, 0, 3, 0)}
                portrait={new Transform(13, 8, 18, 0, 6, 0)}
                explore={new Transform(3.43, 4.27, 13.71, 0, 0, 0)}
                // orbitCtrl
                makeDefault
                minPolarAngle={Math.PI * 0.1}
                maxPolarAngle={Math.PI * 0.49}
                minAzimuthAngle={Math.PI * 0.1}
                maxAzimuthAngle={Math.PI * 0.4}
                maxDistance={50}
                minDistance={10}
                maxZoom={10}
                minZoom={10}
                panSpeed={0.3}
                zoomSpeed={0.3}
                rotateSpeed={0.4}
                enableDamping
                enablePan={false}
                // enablePan
                dampingFactor={0.05}
            />
            {/* <OrbitControls makeDefault /> */}
            <MovementPath idx={0} path={assets.path.stable} steering={2}>
                <Human
                    showFork
                    humanType={HumanTypes.farmer}
                    animTimeScale={.8}
                    animation={assets.human.animations.Walk}
                    idx={0}
                    position={[0,0,0]} />
            </MovementPath>
            <Horse
                position={new Vector3(1.9, 0.00, 0.25)}
                rotation={new Euler(0, MathUtils.DEG2RAD * 90, 0)}

                scale={.9}
                idx={0}
                animation={assets.horse.animations.Idle}
                hair={HorseHair.double}
                bodyTexture={assets.horse.textures.okelaBody}
                hairTexture={assets.horse.textures.okelaHair}
            />
            <Horse
                position={new Vector3(1.92, 0.01, -2.59)}
                rotation={new Euler(0, MathUtils.DEG2RAD * 90, 0)}
                scale={.9}
                idx={3}
                animation={assets.horse.animations.IdleEat}
                hair={HorseHair.double}
                bodyTexture={assets.horse.textures.mooglyBody}
                hairTexture={assets.horse.textures.mooglyHair}
            />
            <Horse
                position={new Vector3(-1.92, 0.01, 0.25)}
                rotation={new Euler(0, MathUtils.DEG2RAD * -65, 0)}
                idx={2}
                animation={assets.horse.animations.IdleApple}
                hair={HorseHair.long}
                bodyTexture={assets.horse.textures.pitaBody}
                hairTexture={assets.horse.textures.pitaHair}
            />
            <group
                position={new Vector3(-1.5, -0.22, 3.5)}
                rotation={new Euler(0, MathUtils.DEG2RAD * 90, 0)}>
                <Horse
                    idx={1}
                    animation={assets.horse.animations.Idle}
                    hair={HorseHair.short}
                    bodyTexture={assets.horse.textures.hopeBody}
                    hairTexture={assets.horse.textures.hopeHair}
                />
                <Gear
                    idx={1}
                    animation={assets.gear.animations.Idle}
                />
                <Human
                    position={new Vector3(0, 0.07, 0)}
                    animTimeScale={.2}
                    humanType={HumanTypes.youngMan}
                    animation={assets.human.animations.BrushHorse}
                    idx={1} />
            </group>
            <Stable position={[0, 0.8, 0]} scale={1.2} />
        </>
    );
}