type Props = {
    icon?: React.ReactNode,
    text: React.ReactNode;
}

export default function CampsItem({icon, text}: Props) {
    return (
        <>
            <div className="flex flex-row grow items-center justify-start my-2">
                {icon && <div className="text-xl lg:text-3xl w-12 mr-2">{icon}</div>}
                <div className="text-md lg:text-lg font-light">{text}</div>
            </div>
        </>
    )
}

