
// vertex shader
export const footScrollVertexShader = /* glsl */`
#include <fog_pars_vertex>

varying vec2 vUv;
varying vec4 vPos;

void main()
{
    #include <begin_vertex>
    #include <project_vertex>
    #include <fog_vertex>

    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    gl_Position = projectedPosition;

    vUv = uv;
    vPos = modelPosition;
}
`;

// fragment shader
export const footScrollFragmentShader = /* glsl */`

#include <fog_pars_fragment>

uniform sampler2D uMap;
uniform float uRange;
uniform float uSoftRange;

varying vec2 vUv;
varying vec4 vPos;

void main()
{
    vec4 color = texture2D(uMap, vUv);
    vec3 brown = vec3(0.435, 0.305, 0.082);

    float a =  1.0 - abs(vPos.z / uRange);
    float len = sqrt(vPos.z * vPos.z + vPos.x * vPos.x );
    float soft = (uRange - abs(len))  / uSoftRange;

    soft = clamp(soft, 0.0, 1.0);

    color.w = soft * color.w;

    color.x *= brown.x;
    color.y *= brown.y;
    color.z *= brown.z;
    color.w *= 0.5;

    gl_FragColor = color;
    #include <fog_fragment>
}
`