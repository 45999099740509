export default function GetFacebookLink(): string {
    const userAgent = {
        Android: function () {
          return navigator.userAgent.match(/Android/i);
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Default: function () {
          return !this.Android() && !this.iOS();
        }
      };
    
      let facebookLink = "https://www.facebook.com/%C3%89curie-chevalex-111574736992270";
    
      if (userAgent.Android() || userAgent.iOS()) {
        facebookLink = "fb://page/111574736992270";
      }
    
      if (userAgent.iOS()){
        facebookLink = "fb://profile/111574736992270";
      }

      return facebookLink;
}