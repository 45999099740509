
import { Html } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { button, useControls } from "leva";
import { Perf } from "r3f-perf";
import { useEffect } from "react";
import useDebugMode from "../stores/useDebugMode";
import useExploreMode from "../stores/useExploreMode";
import useFootSteps from "../stores/useFootSteps";
import useMenuState from "../stores/useMenuState";
import CameraPosition from "./CameraPosition";
import Target from "./Target";

export default function Debug() {

    const {  perfEnabled, targetEnabled, showExploreMode, showMovementPath } = useControls('Tools', {
        perfEnabled: localStorage.getItem("perfEnabled") === "true",
        targetEnabled: localStorage.getItem("targetEnabled") === "true",
        showExploreMode: localStorage.getItem("showExploreMode") === "true",
        showMovementPath: localStorage.getItem("showMovementPath") === "true",
    });

    const clearFootstep = useFootSteps(s => s.clear);

    const {camera} = useThree();

    const copyCameraPosition = () => {
        const p = camera.position;
    
        const result = `${p.x.toFixed(2)}, ${p.y.toFixed(2)}, ${p.z.toFixed(2)}`;
        navigator.clipboard.writeText(result);
      };

    useControls('Menu', {
        toggleMenu: button(() => toggleMenu()),
        clearFootstep: button(() => clearFootstep()),
        copyCameraPosition: button(() => copyCameraPosition()),
    });

    const toggleMenu = useMenuState(s => s.toggle);
    const enableMovementPath = useDebugMode(s => s.enableMovementPath);

    const exploreModeState = useExploreMode(s => s.exploreModeState);

    useEffect(() => {
        localStorage.setItem("perfEnabled", perfEnabled.toString());
        localStorage.setItem("targetEnabled", targetEnabled.toString());
        localStorage.setItem("showExploreMode", showExploreMode.toString());
        localStorage.setItem("showMovementPath", showMovementPath.toString());

        enableMovementPath(showMovementPath);
    }, [
        perfEnabled,
        targetEnabled,
        showExploreMode,
        showMovementPath
    ]);

    return <>
        {perfEnabled && <Perf position="bottom-left" />}
        {targetEnabled && <Target scale={0.4} />}
        {showExploreMode && <Html position={[0, 0, 0]}><div className="text-3xl text-red-500 font-bold w-72">{exploreModeState}</div></Html>}
    </>
}