import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export enum ExploreModeState {
  Disabled = "Disabled",
  TransitionToEnable = "TransitionToEnable",
  Enabled = "Enabled",
  TransitionToDisable = "TransitionToDisable"
}

export default create(subscribeWithSelector((set: Function) => {
  return {
    exploreModeState: ExploreModeState.Disabled,

    toggle: () => {
      set((state: any) => {
        switch (state.exploreModeState) {
          case ExploreModeState.Disabled:
          case ExploreModeState.TransitionToDisable:
            state.exploreModeState = ExploreModeState.TransitionToEnable;
            break;
          case ExploreModeState.Enabled:
          case ExploreModeState.TransitionToEnable:
            state.exploreModeState = ExploreModeState.TransitionToDisable;
            break;
        }

        return {};
      });
    },

    completeTransition: () => {
      set((state: any) => {
        switch (state.exploreModeState) {
          case ExploreModeState.TransitionToDisable:
            state.exploreModeState = ExploreModeState.Disabled;
            break;
          case ExploreModeState.TransitionToEnable:
            state.exploreModeState = ExploreModeState.Enabled;
            break;
        }
        return {};
      });
    },

    forceQuitExploreMode: () => {
      set((state: any) => {
        state.exploreModeState = ExploreModeState.Disabled;
        return {};
      });
    },
  };
}));
