import {
  Element} from "react-scroll";
import { MdEmail, MdFacebook, MdPhone, MdPlace } from "react-icons/md";
// import { Platform } from "react-native/types";
import CommonButtonOutline from "./CommonButtonOutline";
import GetFacebookLink from "./FacebookLink";

export default function Contacts() {


  return (
    <>
      <div className="flex flex-row portrait:flex-col grow w-screen overflow-auto">
        <div className="flex flex-1 grow flex-col justify-center items-center">

          <div className="flex flex-col grow justify-center items-center p-5 fadeIn group-forced-dark:bg-stone-900/80 group-forced-dark:rounded-lg">
            <Element name="intro" className="w-full snap-start">
              <div className="flex flex-col grow items-center text-stone-700 dark:text-stone-300 pt-10 pb-5 portrait:mt-16 outline-orange-600 outline-offset-2 outline-1">
                <h2 className="text-4xl font-bold fadeIn ">Contacts</h2>
                <p className=" lg:text-xl text-lg my-3 px-5 fadeIn text-center font-light">Pour plus d'information, n'hésitez pas à nous contacter</p>
                <div className="bg-amber-600 w-72 h-1 mt-2 mb-10"></div>

                <div className="flex flex-col items-center text-sm lg:text-lg font-normal">

                  <a href="mailto:info@ecuriechevalex.com" className="mb-5 appearFromRight lg:animation-delay-600 animation-delay-2100">
                    <CommonButtonOutline additionalClasses="w-72 lg:w-96">
                      <div className="flex flex-row items-center">
                        <span className="mr-5 text-2xl"><MdEmail></MdEmail></span>
                        <span className="text-center w-full pr-5">info@ecuriechevalex.com</span>
                      </div>
                    </CommonButtonOutline>
                  </a>

                  <a href="tel:+14505027814" className="mb-5  appearFromRight lg:animation-delay-900 animation-delay-2400">
                    <CommonButtonOutline additionalClasses="w-72 lg:w-96">
                      <div className="flex flex-row items-center">
                        <span className="mr-5 text-2xl"><MdPhone></MdPhone></span>
                        <span className="text-center w-full pr-5">Téléphone: (450) 502-7814</span>
                      </div>
                    </CommonButtonOutline>
                  </a>

                  <a href={GetFacebookLink()} target="_blank" rel="noreferrer" className="mb-5 appearFromRight lg:animation-delay-1200 animation-delay-2700">
                    <CommonButtonOutline additionalClasses="w-72 lg:w-96">
                      <div className="flex flex-row items-center">
                        <span className="mr-5 text-2xl"><MdFacebook></MdFacebook></span>
                        <span className="text-center w-full pr-5">Page Facebook</span>
                      </div>
                    </CommonButtonOutline>
                  </a>

                  <a href="http://maps.google.com/?q=777,%20rang%20Salvail%20sud,%20%20La%20Pr%C3%A9sentation,%20%20J0H%201B0" target="blank" className="mb-5 appearFromRight lg:animation-delay-1500 animation-delay-3000">
                    <CommonButtonOutline additionalClasses="w-72 lg:w-96">
                      <div className="flex flex-row items-center">
                        <span className="mr-5 text-2xl"><MdPlace></MdPlace></span>
                        <span className="text-center w-full pr-5">777 rang Salvail sud,<br /> La Présentation, QC, J0H 1B0</span>
                      </div>
                    </CommonButtonOutline>
                  </a>

                  <div className="italic text-sm mt-10 text-center">* La cours se trouve entre l'adresse 777 et le 783.<br /> Vous devez passer le petit pont en voiture.<br /> Merci de rouler à un maximum de 10 KM/H</div>
                </div>
              </div>
            </Element>
            {/* <Element name="section-01" className="w-full h-screen bg-stone-700 text-stone-300 relative snap-start p-5">
          <div className="flex flex-col grow items-center">
            <div className="text-2xl lg:text-4xl my-10 text-center">Informations pour les pensions</div>
            <div className="flex flex-col items-center pt-10 text-lg">
              <h3 className="text-2xl pb-10">Certifications Offertes</h3>
              <div>Montant payable le 1er du moins.</div>
              <div>Un essais à cheval est requis pour approuver le couple cavalier et cheval.</div>
              <div>Au minimum 1 cours par mois est exigé pour le couple cavalier et cheval afin de garder un bonne entraînement de la part des 2 parties.</div>
              <div>Instructeur en équitation western </div>
            </div>
          </div>
        </Element> */}
          </div>
        </div>
      </div >
    </>
  );
}
