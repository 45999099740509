/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GroupProps, useFrame } from "@react-three/fiber";
import { assets } from "./Assets";
import * as THREE from "three";
import { Mesh, ShaderMaterial } from "three";
import useLoadTexture from "./UseLoadTexture";
import grassVert from "../shaders/grass-vert";
import grassFrag from "../shaders/grass-frag";
import useDarkMode from "../stores/useDarkMode";

export function SteBrigitte(props: GroupProps) {
  const { nodes } = useGLTF(assets.steBrigitte.model);
  const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);

  const textureGround = useLoadTexture(darkModeEnabled ? assets.steBrigitte.textures.groundNight : assets.steBrigitte.textures.ground);
  const textureProps1 = useLoadTexture(darkModeEnabled ? assets.steBrigitte.textures.props1Night : assets.steBrigitte.textures.props1);
  const textureProps2 = useLoadTexture(darkModeEnabled ? assets.steBrigitte.textures.props2Night : assets.steBrigitte.textures.props2);
  const textureProps3 = useLoadTexture(darkModeEnabled ? assets.steBrigitte.textures.props3Night : assets.steBrigitte.textures.props3);
  const texturePubs = useLoadTexture(darkModeEnabled ? assets.steBrigitte.textures.pubsNight : assets.steBrigitte.textures.pubs);

  const textureGrassColor = useLoadTexture(assets.steBrigitte.textures.grassColor);
  const textureGrassAlpha = useLoadTexture(assets.steBrigitte.textures.grassAlpha);

  const herbsShader = useRef<ShaderMaterial>(null!);


  const uniforms = useMemo(
    () => {
      let obj = THREE.UniformsLib.fog;
      obj = Object.assign(
        {
          uFrequency: { value: new THREE.Vector2(20, 5) },
          uTime: { value: Math.random() * 10 },
          uColor: { value: new THREE.Color("red") },
          uMap: { value: textureGrassColor },
          uAlphaMap: { value: textureGrassAlpha },
          uBoundingHeight: { value: 28 },
          uRange: { value: 7.75 },
          uSoftRange: { value: 0.25 },
          uLight: { value: 1 },
          uBranchBackdrop: { value: 0.4 },
          uWind: { value: 10 }
        }, obj);
      return obj;
    },
    [textureGrassColor, textureGrassAlpha]
  );

  useFrame(
    (state, delta) => {
      herbsShader.current.uniforms.uTime.value += delta;
      herbsShader.current.uniforms.uLight.value = darkModeEnabled ? 0.1 : 1;
    }
  );

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.Props1 as Mesh).geometry}
        material={(nodes.Props1 as Mesh).material}
      >
        <meshBasicMaterial map={textureProps1} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.Props2 as Mesh).geometry}
        material={(nodes.Props2 as Mesh).material}
      >
        <meshBasicMaterial map={textureProps2} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.Props3 as Mesh).geometry}
        material={(nodes.Props3 as Mesh).material}
      >
        <meshBasicMaterial map={textureProps3} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.Pubs as Mesh).geometry}
        material={(nodes.Pubs as Mesh).material}
      >
        <meshBasicMaterial map={texturePubs}
          side={THREE.DoubleSide} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.MergedGround as Mesh).geometry}
        material={(nodes.MergedGround as Mesh).material}
      >
        <meshBasicMaterial map={textureGround} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.Windows as Mesh).geometry}
        material={(nodes.Windows as Mesh).material}
      >
        <meshBasicMaterial color="white" opacity={0.4} transparent />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.Grass as Mesh).geometry}
        material={(nodes.Grass as Mesh).material}
      >
        <shaderMaterial
          fog
          ref={herbsShader}
          vertexShader={grassVert}
          fragmentShader={grassFrag}
          transparent
          side={THREE.DoubleSide}
          depthWrite={false}
          toneMapped={false}
          uniforms={uniforms}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload(assets.steBrigitte.model);
