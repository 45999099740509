// eslint-disable-next-line import/no-anonymous-default-export
export default /* glsl */`
uniform float uTime;
uniform float uDeltaTime;
uniform float uPixelRatio;
uniform float uSize;

attribute float aScale;
attribute float alifetime;
attribute float aStartTime;

varying vec2 vUv;
varying float vScale;
varying vec4 vPosition;
varying vec4 vPos;
varying float vt;

void main()
{
    vt = fract((uTime - aStartTime) / alifetime);

    vec4 localPosition = vec4(position, 1.0);    
    localPosition.y = vt * -7.5 + 5.5;
    localPosition.x += vt * -0.4 * aScale * localPosition.z;
    localPosition.z += vt * 0.2 * aScale * localPosition.x;

    vec4 modelPosition = modelMatrix * localPosition;
    // float y = vt * 5.0;
   
    // modelPosition.y = mod(y, 5.0);
    float wind = 1.0;


    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;

    gl_Position = projectionPosition;
    
    gl_PointSize = uSize * aScale * uPixelRatio;
    gl_PointSize *= (1.0 / - viewPosition.z);
    
    vUv = uv;
    vScale = aScale;
    vPosition = gl_Position;
    vPos = localPosition;
}`
