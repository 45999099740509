import { Vector3 } from "three";
import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export type useDuststepType = {
    idx: number,
    positions: number[];
    scales: number[];
    lifetimes: number[];
    startTimes: number[];
    velocities: number[];
    burstStartTimes: number[];
    add: Function,
    clear: Function,
    addScroll: Function,
    scrollOffset: number,
    dustFactorSync: any,
    syncDustFactor: Function
    // vertices: number[];
    // uvs: number[];
}

export type ParticleChunk = {
    startTime: number;
    idx: number;
}

const particleCount: number = 10;

export default create(subscribeWithSelector((set: Function): useDuststepType => {
    return {
        idx: -1,

        // todo group
        positions: [],
        scales: [],
        lifetimes: [],
        velocities: [],
        startTimes: [],
        
        burstStartTimes: [],
        scrollOffset: 0,
        dustFactorSync: {},

        syncDustFactor: (idx: number, factor: number) => {
            set((state: any) => {
                state.dustFactorSync[idx.toString()] = factor;

                const tmp = state.dustFactorSync;
                state.dustFactorSync = {};
                Object.keys(tmp).forEach(k => state.dustFactorSync[k] = tmp[k]);

                return {};
            });
        },

        add: (position: Vector3, clock: THREE.Clock, scaleFactor: number = 1, lifetimeFactor: number = 1) => {
            set((state: any) => {
                const time = clock.elapsedTime;

                let spotIdx = state.burstStartTimes.length;
                
                for(let i = 0; i < state.burstStartTimes.length; i++){
                    if (time - state.burstStartTimes[i] > 10) {
                        spotIdx = i;
                        break;
                    }
                }

                if (spotIdx === state.burstStartTimes.length){
                    for(let i = 0; i < particleCount; i++){
                        state.positions.push(0);
                        state.positions.push(0);
                        state.positions.push(0);

                        state.velocities.push(0);
                        state.velocities.push(0);
                        state.velocities.push(0);

                        state.scales.push(0);

                        state.lifetimes.push(0);

                        state.startTimes.push(0);
                    }
                    state.burstStartTimes.push(0);
                }

                state.burstStartTimes[spotIdx] = time;

                for (let i = spotIdx * particleCount; i < (spotIdx * particleCount + particleCount); i++) {
                    const speed = 0.1 + Math.random() * 0.5;
                    state.positions[i * 3 + 0] = position.x + -0.1 + Math.random() * 0.2;
                    state.positions[i * 3 + 1] = position.y;
                    state.positions[i * 3 + 2] = position.z + -0.1 + Math.random() * 0.2 + state.scrollOffset;
        
                    state.velocities[i * 3 + 0] = (-0.5 + Math.random()) * 1.0 * speed + 2 * speed;
                    state.velocities[i * 3 + 1] = 1.0 * speed;
                    state.velocities[i * 3 + 2] = (-0.5 + Math.random()) * 1.0 * speed;
        
                    state.scales[i] = 5 + Math.random() * 60 * scaleFactor;
                    state.lifetimes[i] = 3 + Math.random() * 5 * lifetimeFactor;
                    state.startTimes[i] = time;
                }

                state.idx++;
                return {};
            });
        },

        clear: () => {
            set((state: any) => {
                state.idx = -1;
                state.positions = [];
                state.scales = [];
                state.lifetimes = [];
                state.velocities = [];
                state.startTimes = [];
                state.burstStartTimes = [];
                state.scrollOffset = 0;
                return {};
            });
        },

        addScroll: (offset: number) => {
            set((state: any) => {
                state.scrollOffset += offset;
                return {};
            });
        }
    };
}));