type Props = {
    img: any,
    title: React.ReactNode;
    description: React.ReactNode;
    link: string;
    animationDelay?: string;
}

export default function CourseFormation({ img, title, description, link }: Props) {
    return (
        <div className="flex flex-row md:flex-col items-starts mb-12">
            <img src={img.src} alt={img.alt} className="w-36 lg:w-48 mb-4 pr-10 md:pr-0" />
            <div className="flex flex-col w-72 ">
                <div className="text-2xl font-light">{title}</div>
                <div className="text-md font-light mb-2 ">{description}</div>
                <div className="text-sm underline text-blue-600 cursor-pointer"><a href={link} target="_blank" rel="noreferrer">Plus d'informations</a></div>
            </div>
        </div>
    );
}