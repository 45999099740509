import { useFrame } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { Group, Vector3 } from "three";
import useDustSteps from "../stores/useDustSteps";
import useFootSteps from "../stores/useFootSteps";
import { MathUtils2 } from "./MathUtils2";

type Props = {
    speed?: number;
    p0?: Vector3;
    p1?: Vector3;
    rangeRespawn?: number;
    children: React.ReactNode
}

export default function MovementScroll({ rangeRespawn = 15, speed = 10, p0 = new Vector3(), p1 = new Vector3(), children }: Props) {
    const [pos, setpos] = useState<Vector3>(new Vector3());

    const groupRef = useRef<Group>(null!);
    const addScrollOffset = useFootSteps(s => s.addScroll);
    const addDustScrollOffset = useDustSteps(s => s.addScroll);

    useEffect(() => {
        pos.copy(p0);
    }, [])

    useFrame((state, delta) => {
        // MathUtils2.moveTowards(pos, p1, delta * speed);
        // groupRef.current.position.copy(pos);
        addScrollOffset(delta * speed);
        addDustScrollOffset(delta * speed);

        groupRef.current.children.forEach(c => {
            c.position.z -= delta * speed;
            if (c.position.z < -rangeRespawn && c.name !== "footstep" && c.name !== "dust"){
                c.position.z = rangeRespawn;
            }
        });
    });

    return (
        <group ref={groupRef}>
            {children}
        </group>
    );
}
