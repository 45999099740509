export default function Chevalex() {
  return (
    <>
      <div className="flex flex-col grow justify-center items-center text-stone-700 dark:text-stone-100 group-forced-dark:stroke-text pt-10 pb-5 font-quicksand portrait:mt-16 mx-4 ">
        <div className="flex flex-col grow justify-center items-center p-5 fadeIn group-forced-dark:bg-stone-900/80 group-forced-dark:rounded-lg  outline-orange-600  outline-offset-1 outline-2 group-forced-dark:shadow-lg">
          <h1 className="flex text-4xl lg:landscape:text-6xl font-bold fadeIn animation-delay-900 lg:animation-delay-0 group-forced-dark:underline decoration-amber-500">Écurie Chevalex</h1>
          <p className="flex align-middle fadeIn animation-delay-2100 lg:animation-delay-600 mt-1 lg:mt-5 portrait:mt-5 text-center">Apprendre l'équitation Western, ici, à St&#8209;Hyacinthe.</p>
        </div>
      </div>
    </>
  )
}
