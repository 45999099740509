import { useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { BufferGeometry, Points, ShaderMaterial, Vector3 } from "three";
import leafVert from "../shaders/leaf-vert";
import leafFrag from "../shaders/leaf-frag";
import { PointsProps, useFrame, useThree } from "@react-three/fiber";
import useLoadTexture from "./UseLoadTexture";
import { assets } from "./Assets";

export default function LeafParticle({...props}: PointsProps) {

    const leafTexture = useLoadTexture(assets.leaf.textures.leaf);

    const buffer = useRef<BufferGeometry>(null!);
    const shader = useRef<ShaderMaterial>(null!);

    const [startTime, setstarttime] = useState(0);

    const uniforms = useMemo(
        () => ({
            uTime: { value: 0 },
            uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
            uSize: { value: 100 },
            uMap: {value: leafTexture},
            uRange: { value: 3 },
            uSoftRange: { value: 0.25 },
        }),
        [leafTexture]
    );

    const {clock} = useThree();

    useFrame((s, d) => {
        if (shader) {
            shader.current.uniforms.uTime.value = s.clock.getElapsedTime() + 1000;
        }
    })

    useEffect(() => {
        setstarttime(clock.elapsedTime);
        const leaveCount = 30
        const positionArray = new Float32Array(leaveCount * 3)
        const scaleArray = new Float32Array(leaveCount)
        const lifetimeArray = new Float32Array(leaveCount)
        const starttimeArray = new Float32Array(leaveCount)


        for (let i = 0; i < leaveCount; i++) {
            positionArray[i * 3 + 0] = -1.8 + Math.random() * 4.5;
            positionArray[i * 3 + 1] = Math.random() * 5.5
            positionArray[i * 3 + 2] =  + Math.random() * 4;

            scaleArray[i] = Math.random() * 5
            lifetimeArray[i] = 6 + Math.random() * 5
            starttimeArray[i] = 6 + Math.random() * 5
        }

        buffer.current.setAttribute('position', new THREE.BufferAttribute(positionArray, 3))
        buffer.current.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))
        buffer.current.setAttribute('alifetime', new THREE.BufferAttribute(lifetimeArray, 1))
        buffer.current.setAttribute('aStartTime', new THREE.BufferAttribute(starttimeArray, 1))

    }, [])

    return (
        <>
            <points  {...props} frustumCulled={false}>
                <bufferGeometry ref={buffer} />
                <shaderMaterial
                        ref={shader}
                        vertexShader={leafVert}
                        fragmentShader={leafFrag}
                        transparent
                        depthWrite={false}
                        uniforms={uniforms}
                    />
            </points>
        </>
    )
}