
// vertex shader
export const snowParticleVertexShader = /* glsl */`

uniform float uTime;
uniform float uDeltaTime;
uniform float uPixelRatio;
uniform float uSize;

attribute float aScale;
attribute float alifetime;
attribute float aStartTime;

varying vec2 vUv;
varying float vScale;
varying vec4 vPosition;
varying vec4 vPos;
varying float vt;

void main()
{
    vt = fract((uTime - aStartTime) / alifetime);

    vec4 localPosition = vec4(position, 1.0);    
    localPosition.y = -8.5 * vt + 7.5;
    // localPosition.x += vt * -0.4 * aScale * localPosition.z;
    localPosition.z += vt * -20.0;

    vec4 modelPosition = modelMatrix * localPosition;
    // float y = vt * 5.0;
   
    // modelPosition.y = mod(y, 5.0);
    float wind = 1.0;


    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;

    gl_Position = projectionPosition;
    
    gl_PointSize = uSize * aScale * uPixelRatio;
    gl_PointSize *= (1.0 / - viewPosition.z);
    
    vUv = uv;
    vScale = aScale;
    vPosition = gl_Position;
    vPos = localPosition;
}
`;

// fragment shader
export const snowParticleFragmentShader = /* glsl */`

uniform sampler2D uMap;
uniform float uTime;
uniform float uRange;
uniform float uSoftRange;

varying vec2 vUv;
varying float vScale;
varying vec4 vPosition;
varying vec4 vPos;
varying float vt;

void main()
{
    float angle = radians(vPosition.z * vPosition.x * 2.0) * vt;
    
    vec2 uv = gl_PointCoord - vec2(0.5,0.5);
    mat2 rotationMatrix = mat2(
        cos(angle), -sin(angle),
        sin(angle), cos(angle)
    );

    uv = rotationMatrix * uv + vec2(0.5,0.5);;
    vec4 color = texture2D(uMap, uv);

    float a =  1.0 - abs(vPos.z / uRange);
    float len = sqrt(vPos.z * vPos.z + vPos.x * vPos.x );
    float soft = (uRange - abs(len))  / uSoftRange;
    float factor = vPos.y < 0.0 ? 0.0: 1.0;
    // float scaleFactor = 1.0 - (vScale / 8.0);
    soft *= factor;


    soft = clamp(soft, 0.0, 1.0);
    color.w *= soft;

    vec3 c1 = vec3(1,1,1);
    vec3 c2 = vec3(0.858, 0.898, 0.921);
    float t= vScale / 5.0;
    vec3 variant = mix(c1, c2, t);
    color.xyz *= variant;

    gl_FragColor = color;
    // gl_FragColor = vec4(scaleFactor * scaleFactor, 0.0,0.0,0.5);
}
`