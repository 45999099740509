import { useEffect, useRef } from "react";
import useMenuState from "../stores/useMenuState";

type Props = {
    additionalClasses?: string
    onClick?: Function;
    children: React.ReactNode,
    active?: boolean
    noWhiteBg?: boolean;
}

export default function CommonButton({ additionalClasses, onClick, children, active, noWhiteBg }: Props) {

    const updateClass = useMenuState(s => s.updateClass);

    const buttonRef = useRef<HTMLButtonElement>(null!);

    const callback = () => {
        if (onClick) {
            onClick();
        }
    }

    useEffect(() => {
        if (buttonRef.current && active !== undefined) {
            updateClass(buttonRef.current, "opened", active);
        }
    }, [active, updateClass])

    return (
        <>
            <button className={
                "px-4 " +
                "py-2 " +
                "transition-all " +
                "duration-200 " +
                "active:scale-90 " +
                "select-none " +
                "highlight-transparent " +
                "shadow-xl " +
                "rounded-full " +
                "border " +
                "border-transparent " +
                "dark:border-stone-400 " +
                
                (!noWhiteBg && "bg-white ") +
                "opened:bg-stone-700 " +
                "dark:bg-stone-700 " +

                "text-stone-700 " +
                "opened:text-stone-100 " +
                "dark:text-stone-100 " +
                additionalClasses
            }
                onClick={callback}
                ref={buttonRef}>
                {children}
            </button>
        </>
    );
}