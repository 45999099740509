import { useFrame } from "@react-three/fiber";
import { MutableRefObject, useState } from "react";
import { MathUtils, Object3D, Vector3 } from "three";
import useLightSource from "../stores/useLightSource";

export default function useGetLighting(object3D: MutableRefObject<Object3D>): number {
    const [light, setLight] = useState(1);
    const fakeLights = useLightSource(s => s.lights);
  
    useFrame((state, delta) => {
      let lightIntensity = 0;
  
      if (fakeLights && fakeLights.length > 0 && object3D && object3D.current) {
        for (const light of fakeLights) {
          const p1 = new Vector3();
          object3D.current.getWorldPosition(p1);
          const distance = light.pos.distanceTo(p1);
          const lightRatio = 1 - MathUtils.clamp(distance / light.range, 0, 1);
  
          lightIntensity = MathUtils.clamp(lightIntensity + lightRatio, 0, 1);
        }
  
        const result = lightIntensity * lightIntensity * lightIntensity;
        setLight(result);
      }
      else {
        setLight(1);
      }
    })
  
    return light;
  }