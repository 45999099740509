export class ImageLink {
    src!: string;
    alt!: string;
}

export const images = {
    logo: {
        src: './img/Chevalex_transparent.png',
        alt: 'Logo Chevalex'
    },
    course_thumbail_01: {
        src: './img/d0.png',
        alt: 'Couse Thumbnail 01'
    },
    course_thumbail_02: {
        src: './img/d1.png',
        alt: 'Couse Thumbnail 02'
    },
    course_thumbail_03: {
        src: './img/g01.png',
        alt: 'Couse Thumbnail 03'
    },
    course_price_thumbail_01: {
        src: './img/mm0.png',
        alt: 'Couse Price Thumbnail 01'
    },
    course_price_thumbail_02: {
        src: './img/mm1.png',
        alt: 'Couse Price Thumbnail 02'
    },
    course_price_thumbail_03: {
        src: './img/mm2.png',
        alt: 'Couse Price Thumbnail 03'
    },
    course_book_01: {
        src: './img/cc0.jpg',
        alt: 'Ptit trot cheval quebec'
    },
    course_book_02: {
        src: './img/cc1.jpg',
        alt: 'Formation Cavalier'
    },
    course_book_03: {
        src: './img/cc3.jpg',
        alt: 'Formation Instructeur'
    },
    course_book_04: {
        src: './img/cc4.jpg',
        alt: 'Entraînement 1'
    },
    course_book_05: {
        src: './img/cc5.jpg',
        alt: 'Formation Instructeur 1'
    },
    course_association_0: {
        src: './img/cd0.png',
        alt: 'Cheval Quebec'
    },
    course_association_1: {
        src: './img/cd1.png',
        alt: 'Cheval Quebec'
    },
    course_association_2: {
        src: './img/cd2.png',
        alt: 'Cheval Quebec'
    },
    foliage_01: {
        src: './img/foliage.png',
        alt: 'Foliage Background'
    },
    camps_01: {
        src: './img/img01.jpg',
        alt: 'Camp Equestre 01'
    },
    camps_02: {
        src: './img/img02.jpg',
        alt: 'Camp Equestre 02'
    },
    camps_03: {
        src: './img/img03.jpg',
        alt: 'Camp Equestre 03'
    },
    camps_04: {
        src: './img/img04.jpg',
        alt: 'Camp Equestre 04'
    },
    camps_05: {
        src: './img/img05.jpg',
        alt: 'Camp Equestre 05'
    },
    camps_06: {
        src: './img/img06.jpg',
        alt: 'Camp Equestre 06'
    },
    camps_07: {
        src: './img/img07.jpg',
        alt: 'Camp Equestre 07'
    },
    jrchoiniere: {
        src: './img/jrchoiniere.jpg',
        alt: 'jrchoiniere'
    },
}