// eslint-disable-next-line import/no-anonymous-default-export
export default /* glsl */`
#include <tonemapping_fragment>
#include <fog_pars_fragment>

uniform sampler2D uMap;
uniform sampler2D uMapDirt;
uniform sampler2D uMapMask;
uniform float uLight;
uniform float uRange;
uniform float uSoftRange;

varying vec2 vUv;
varying vec2 vUv2;
varying vec4 vPos;

void main() {
    float y = vUv.y - floor(vUv.y / 1.0);
    float x = vUv.x - floor(vUv.x / 1.0);

    float y2 = vUv2.y - floor(vUv2.y / 1.0);
    float x2 = vUv2.x - floor(vUv2.x / 1.0);

    vec2 repeatUv = vec2(x, y);
    vec2 repeatUv2 = vec2(x2, y2);

    vec4 color = texture2D(uMap, repeatUv);
    vec4 colorDirt = texture2D(uMapDirt, repeatUv);
    vec4 colorMask = texture2D(uMapMask, repeatUv2);

    // color = LinearTosRGB(color);

    float a =  1.0 - abs(vPos.z / uRange);
    float len = sqrt(vPos.z * vPos.z + vPos.x * vPos.x);
    float soft = (uRange - abs(len))  / uSoftRange;

    soft = clamp(soft, 0.0, 1.0);

    color = mix(color, colorDirt, colorMask.x);

    color.xyz *= (uLight * soft);

    gl_FragColor = color;
    #include <fog_fragment>
}
`