import NavItem from "./NavItem";
import DarkModeSwitch from "./DarkModeSwitch";
import ExploreModeSwitch from "./ExploreModeSwitch";
import MenuButton from "./MenuButton";
import useMenuState, { MenuStates } from "../stores/useMenuState";
import { useMediaQuery } from "react-responsive";
import DebugSwitch from "./DebugSwitch";
import { useLocation } from "react-router-dom";
import AudioSwitch from "./AudioSwitch";

export default function Navigation() {

  const location = useLocation();

  type nav = {
    url: string;
    label: string;
  }

  const navs: nav[] = [
    { url: '/', label: 'Chevalex' },
    { url: '/cours', label: 'Cours' },
    { url: '/camps-de-jour', label: 'Camp Équestre' },
    { url: '/pedagogique', label: 'Journées Pédagogiques' },
    { url: '/demi-pension', label: 'Demi-Pension' },
    { url: '/competition', label: 'Compétition' },
    // { url: '/chevaux', label: 'Nos Chevaux' },
    { url: '/contacts', label: 'Contacts' },
  ]

  return (
    <>
      <nav className="flex flex-col lg:flex-row flex-wrap grow fadeIn">
        <div className="absolute right-5 top-5 flex flex-row">
          {/* <span className="mr-2"><DebugSwitch /></span> */}
          {location.pathname === "/dance" && <span className="mr-2"><AudioSwitch /></span>}
          <span className="mr-2"><ExploreModeSwitch /></span>
          <DarkModeSwitch />
        </div>
        <div className="absolute left-4 top-5">
          <MenuButton />
        </div>
        <div className="
          transition-all
          grow 
          flex 
          flex-wrap 
          justify-center 
          lg:flex-row 
          lg:justify-center 
          landscape:justify-center
          landscape:mx-24
          landscape:mt-12 
          landscape:lg:mt-4 
          lg:mx-0 
          portrait:mt-24
          ">
          {navs.map((v, i) => <NavItem url={v.url} label={v.label} key={`link${i}`} index={i} total={navs.length} />)}
        </div>
      </nav>
    </>
  );
}
