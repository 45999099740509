import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export default create(subscribeWithSelector((set: Function) => {
    return {
        time: 0,
        running: false,

        restart: () => {
            set((state: any) => {
                if (state.running) {
                    return {};
                }
                state.time = 0;
                state.running = true;
                return {};
            });
        },

        stop: () => {
            set((state: any) => {
                if (!state.running) {
                    return {};
                }
                state.running = false;
                return {};
            });
        },

        update: (delta: number) => {
            set((state: any) => {
                if (state.running) {
                    state.time += delta;
                }
                return {};
            });
        }
    };
}));
