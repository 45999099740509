// eslint-disable-next-line import/no-anonymous-default-export
export default /* glsl */`
#include <tonemapping_fragment>
#include <fog_pars_fragment>

uniform vec3 uColor;
uniform sampler2D uMap;
uniform sampler2D uAlphaMap;
uniform float uLight;
uniform float uRange;
uniform float uSoftRange;
uniform float uBranchBackdrop;
uniform float uBoundingHeight;

varying vec2 vUv;
varying float vY;
varying vec4 vPos;
varying float vElevation;

void main()
{
    vec4 color = texture2D(uMap, vUv);
    color = LinearTosRGB(color);
    vec4 alpha = texture2D(uAlphaMap, vUv);
    // color.rgb *= vElevation * 2.0 + 0.65;
    
    float a =  1.0 - abs(vPos.z / uRange);
    float len = sqrt(vPos.z * vPos.z + vPos.x * vPos.x );
    float soft = (uRange - abs(len))  / uSoftRange;

    soft = clamp(soft, 0.0, 1.0);

    color.w = soft * alpha.r;

    float branchRootDarken = (1.0 - vUv.y * vUv.y * vUv.y) * uBranchBackdrop;

    float h = vY / (uBoundingHeight + 0.0001);
    float topLighten = h * h * 0.9;

    float f = branchRootDarken + topLighten + (1.0 - uBranchBackdrop);
    color.xyz *= (f * uLight);

    gl_FragColor = color;
    #include <fog_fragment>
    // gl_FragColor = vec4(uRange, 0.0,0.0,1.0);
}`