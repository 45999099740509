import React, { useMemo, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GroupProps, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { Mesh, ShaderMaterial, Vector3 } from "three";
import { assets } from "./Assets";
import { lightRayVertexShader, lightRayFragmentShader } from "../shaders/light-ray-shader";
import { range } from "rxjs";

export function LightEffect(props: GroupProps) {

  const { nodes } = useGLTF(assets.steBrigitte.modelLight);

  const shader = useRef<ShaderMaterial>(null!);

  const uniforms = useMemo(
    () => {
      let obj = THREE.UniformsLib.fog;
      obj = Object.assign(
        {
          uTime: { value: 0 },
          uLightPosition: { value: new Vector3(1,1,1) },
        }, obj);
      return obj;
    },
    []
  );

  useFrame((s, d) => {
    if (shader) {
       shader.current.uniforms.uTime.value = s.clock.getElapsedTime();
    }
  })

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.LightEffect as Mesh).geometry}
        material={(nodes.LightEffect as Mesh).material}
        // position={[18.953, 0, 25.77]}
        // rotation={[Math.PI, -0.838, Math.PI]}
      >
        <shaderMaterial
          fog
          ref={shader}
          vertexShader={lightRayVertexShader}
          fragmentShader={lightRayFragmentShader}
          transparent
          depthWrite={false}
          uniforms={uniforms}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload(assets.steBrigitte.modelLight);

