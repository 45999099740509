// eslint-disable-next-line import/no-anonymous-default-export
export default /* glsl */`
#include <fog_pars_fragment>

uniform sampler2D uMap;

varying vec2 vUv;

void main()
{
    vec4 color = texture2D(uMap, vUv);
    vec3 brown = vec3(0.435, 0.305, 0.082);
    color.x *= brown.x;
    color.y *= brown.y;
    color.z *= brown.z;
    color.w *= 0.4;
    gl_FragColor = color;
    #include <fog_fragment>
}`