// eslint-disable-next-line import/no-anonymous-default-export
export default /* glsl */`
#include <tonemapping_fragment>
#include <fog_pars_fragment>

uniform vec3 uScale;
uniform vec3 uColor;
uniform sampler2D uMap;
uniform sampler2D uAlphaMap;
uniform float uLight;

varying vec2 vUv;
varying float vY;
varying float vElevation;

void main()
{
    vec4 color = texture2D(uMap, vUv);
    color = LinearTosRGB(color);
    vec4 alpha = texture2D(uAlphaMap, vUv);
    // color.rgb *= vElevation * 2.0 + 0.65;
    color.xyz *= uLight;
    color.a = alpha.r;
    float f = (1.0 - vUv.y) + (vY / 5.0 * uScale.y) * (vY / 8.0* uScale.y);
    color.xyz *= (1.0 - (pow(vUv.y, 2.0) + 0.1) * 0.6);
    gl_FragColor = color;
    #include <fog_fragment>
}`