import { useMediaQuery } from "react-responsive";
import { Vector3 } from "three";
import useDarkMode from "../stores/useDarkMode";
import CameraManager from "./CameraManager";
import { MiniScenes } from "./MiniScenes";
import { Transform } from "./Transform";
import useScrollCameraEffect from "./UseScrollCameraEffect";
import useScene from "./UseScene";

export default function PedagoScene() {

    useScene("/pedagogique");
    
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    useScrollCameraEffect(isPortrait ? new Vector3(-1,-1, 5): new Vector3(10,0,0));
    const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);

    return <>
        <CameraManager
            original={new Transform(20, 10, 0, 0, 4, 0)}
            // original={new Transform(4.93, 2.74, 3.30, 0, 0, 0)}
            portrait={new Transform(-30, 10, 35, 0, 8.5, -5)}
            landscape={new Transform(20, 5, 0, 0, 5, 0)}
            explore={new Transform(6,2,17,0,0,0)}
            // orbitCtrl
            makeDefault
            minPolarAngle={Math.PI * 0.1}
            maxPolarAngle={Math.PI * 0.49}
            maxDistance={50}
            minDistance={10}
            maxZoom={10}
            minZoom={10}
            panSpeed={0.3}
            zoomSpeed={0.3}
            rotateSpeed={0.2}
            enableDamping
            enablePan={false}
            dampingFactor={0.05}
        />

        <MiniScenes />

        {!darkModeEnabled && <fog attach="fog" args={[0xfca832, 40, 120]} />}
        {darkModeEnabled && <fog attach="fog" args={[0x474c54, 30, 80]} />}
        {/* <Clouds/> */}
    </>
}

