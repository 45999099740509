// eslint-disable-next-line import/no-anonymous-default-export
export default /* glsl */`
#include <fog_pars_vertex>

uniform float uTime;
uniform float uScale;
uniform vec2 uSpeed;

varying vec2 vUv;
varying vec2 vUv2;
varying vec4 vPos;

void main()
{
    #include <begin_vertex>
    #include <project_vertex>
    #include <fog_vertex>
    
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    gl_Position = projectedPosition;
    vPos = modelPosition;

    vUv2 = (uv + vec2(uSpeed.x * uTime, uSpeed.y * uTime));
    vUv = vUv2 * uScale;
}
`