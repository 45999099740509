import React, { useEffect, useMemo, useRef, useState } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GroupProps, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import grassVert from "../shaders/grass-vert";
import grassFrag from "../shaders/grass-frag";
import scrollGrassVert from "../shaders/scroll-grass-vert";
import scrollGrassFrag from "../shaders/scroll-grass-frag";
import { assets } from "./Assets";
import useLoadTexture from "./UseLoadTexture";
import { Mesh, ShaderMaterial } from "three";
import useDarkMode from "../stores/useDarkMode";

type HerbProps = GroupProps & {
  scrolling?: boolean;
  wind?: number;
}

export default function Herbs(props: HerbProps) {
  const { nodes } = useGLTF(assets.herb.model);

  const texture = useLoadTexture(assets.herb.textures.herb);
  const textureAlpha = useLoadTexture(assets.herb.textures.herbAlpha);

  const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);

  const herbsShader = useRef<ShaderMaterial>(null!);

  const uniforms = useMemo(
    () => {
      let obj = THREE.UniformsLib.fog;
      obj= Object.assign(
        {
          uFrequency: { value: new THREE.Vector2(20, 5) },
          uTime: { value: 0 },
          uColor: { value: new THREE.Color("red") },
          uMap: { value: texture },
          uAlphaMap: { value: textureAlpha },
          uHeight: { value: 1 },
          uRange: { value: 7.75 },
          uSoftRange: { value: 0.25 },
          uLight: { value: 1 },
          uWind: {value: props.wind || 15}
        }, obj);
      return obj;
    },
    [texture, textureAlpha]
  );

  useFrame(
    (state, delta) => {
      herbsShader.current.uniforms.uTime.value += delta;
      herbsShader.current.uniforms.uLight.value = darkModeEnabled ? 0.1 : 1;
    }
  );

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={(nodes.Herbs as Mesh).geometry}
        material={(nodes.Herbs as Mesh).material}
        position={[-14.49, 0.26, -19.98]}
        rotation={[1.49, -0.04, 1.06]}
        scale={0.86}
      >
        {
          props.scrolling
            ? <shaderMaterial
              ref={herbsShader}
              vertexShader={scrollGrassVert}
              fragmentShader={scrollGrassFrag}
              transparent
              side={THREE.DoubleSide}
              depthWrite={false}
              toneMapped={false}
              uniforms={uniforms}
            />
            : <shaderMaterial
             fog
              ref={herbsShader}
              vertexShader={grassVert}
              fragmentShader={grassFrag}
              transparent
              side={THREE.DoubleSide}
              depthWrite={false}
              toneMapped={false}
              uniforms={uniforms}
            />
        }
      </mesh>
    </group>
  );
}

useGLTF.preload("./assets/herbs/herb.glb");
