import useDarkMode from "../stores/useDarkMode";
import { MdAudiotrack, MdBugReport, MdOutlineBugReport, MdVolumeOff, MdVolumeUp } from "react-icons/md";
import CommonButton from "./CommonButton";
import useSong from "../stores/useSong";

export default function DebugSwitch() {
  const muted = useSong((s) => s.muted);
  const toggleMute = useSong((s) => s.toggleMute);

  return (
    <>
      <CommonButton onClick={toggleMute} active={false}>
        {!muted && <MdVolumeUp className="text-2xl" />}
        {muted && <MdVolumeOff className="text-2xl" />}
      </CommonButton>
    </>
  );
}
