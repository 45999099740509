import { useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { BufferGeometry, Matrix3, ShaderMaterial, Vector3 } from "three";
import footVert from "../shaders/foot-vert";
import footFrag from "../shaders/foot-frag";
import { GroupProps, useFrame, useThree } from "@react-three/fiber";
import useLoadTexture from "./UseLoadTexture";
import { assets } from "./Assets";
import useFootSteps from "../stores/useFootSteps";
import { footScrollFragmentShader, footScrollVertexShader } from "../shaders/shader-foot-scroll";

type FootstepsProps = GroupProps & {
    scrolling?: boolean;
    range?: number;
}

export default function FootSteps({ scrolling, range, ...props }: FootstepsProps) {

    const { idx, vertices, uvs } = useFootSteps();

    const footTexture = useLoadTexture(assets.horse.textures.footstep);
    const buffer = useRef<BufferGeometry>(null!);
    const shader = useRef<ShaderMaterial>(null!);

    const uniforms = useMemo(
        () => {
            let obj = THREE.UniformsLib.fog;
            obj = Object.assign(
                {
                    uTime: { value: 0 },
                    uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
                    uSize: { value: 100 },
                    uMap: { value: footTexture },
                    uRange: { value: (range || 7.5) },
                    uSoftRange: { value: 0.25 },
                }, obj);
            return obj;
        },
        [footTexture]
    );

    useEffect(() => {
        buffer.current.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices), 3))
        buffer.current.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uvs), 2))
    }, [idx])


    return (
        <>
            <mesh name="footstep" frustumCulled={false}>
                <bufferGeometry ref={buffer} />
                {/* <meshBasicMaterial color={"red"} side={THREE.DoubleSide} ></meshBasicMaterial> */}
                {scrolling
                    ? <shaderMaterial
                        fog
                        ref={shader}
                        vertexShader={footScrollVertexShader}
                        fragmentShader={footScrollFragmentShader}
                        transparent
                        depthWrite={false}
                        uniforms={uniforms}
                    />
                    : <shaderMaterial
                        fog
                        ref={shader}
                        vertexShader={footVert}
                        fragmentShader={footFrag}
                        transparent
                        depthWrite={false}
                        uniforms={uniforms}
                    />
                }
            </mesh>
        </>
    )
}