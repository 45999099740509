import { NormalAnimationBlendMode } from "three";
import { LocomotionData } from "../stores/useSyncLocomotion";
import { MathUtils2 } from "./MathUtils2";
import { LoadedSkinnedMesh } from "./UseSkinnedMesh";

export function computeBlendTree(
    skinnedMeshMap: LoadedSkinnedMesh,
    leftClip: string,
    forwardClip: string,
    rightClip: string,
    defaultTimeScale: number,
    locomotionData: LocomotionData,
    delta: number) {

    if (!skinnedMeshMap || !locomotionData) {
        console.warn("BlendTree can't be computed")
        return;
    }

    if (!skinnedMeshMap.actions[forwardClip]
        || !skinnedMeshMap.actions[rightClip]
        || !skinnedMeshMap.actions[leftClip]
    ) {
        console.warn("BlendTree can't be computed")
        return;
    }

    const lw = skinnedMeshMap.actions[leftClip].weight;
    const rw = skinnedMeshMap.actions[rightClip].weight;
    const fw = skinnedMeshMap.actions[forwardClip].weight;

    const blendPosition = locomotionData.direction;
    const lt = blendPosition > 0 ? Math.abs(blendPosition) : 0;
    const rt = blendPosition < 0 ? Math.abs(blendPosition) : 0;
    const ft = 1 - Math.abs(blendPosition);

    const factor = 1.5

    const lc = MathUtils2.moveNumberToward(lw, lt, delta * factor);
    const rc = MathUtils2.moveNumberToward(rw, rt, delta * factor);
    const fc = MathUtils2.moveNumberToward(fw, ft, delta * factor);

    skinnedMeshMap.actions[leftClip].weight = lc;
    skinnedMeshMap.actions[rightClip].weight = rc;
    skinnedMeshMap.actions[forwardClip].weight = fc;

    skinnedMeshMap.actions[leftClip].timeScale = defaultTimeScale * locomotionData.speedFactor;
    skinnedMeshMap.actions[rightClip].timeScale = defaultTimeScale * locomotionData.speedFactor;
    skinnedMeshMap.actions[forwardClip].timeScale = defaultTimeScale * locomotionData.speedFactor;

    skinnedMeshMap.actions[leftClip].time = skinnedMeshMap.actions[forwardClip].time;
    skinnedMeshMap.actions[rightClip].time = skinnedMeshMap.actions[forwardClip].time;
}

export function initBlendTree(
    skinnedMeshMap: LoadedSkinnedMesh,
    leftClip: string,
    forwardClip: string,
    rightClip: string,
    defaultTimeScale: number,
) {
    if (!skinnedMeshMap.actions[forwardClip]) {
        console.warn(`${forwardClip} not in model ${skinnedMeshMap.url}`);
        return;
    }

    if (!skinnedMeshMap.actions[leftClip]) {
        console.warn(`${leftClip} not in model ${skinnedMeshMap.url}`);
        return;
    }

    if (!skinnedMeshMap.actions[rightClip]) {
        console.warn(`${rightClip} not in model ${skinnedMeshMap.url}`);
        return;
    }

    skinnedMeshMap.actions[forwardClip].play();
    skinnedMeshMap.actions[leftClip].play();
    skinnedMeshMap.actions[rightClip].play();

    skinnedMeshMap.actions[forwardClip].weight = 1;
    skinnedMeshMap.actions[leftClip].weight = 0;
    skinnedMeshMap.actions[rightClip].weight = 0;

    skinnedMeshMap.actions[forwardClip].blendMode = NormalAnimationBlendMode
    skinnedMeshMap.actions[leftClip].blendMode = NormalAnimationBlendMode
    skinnedMeshMap.actions[rightClip].blendMode = NormalAnimationBlendMode

    skinnedMeshMap.actions[forwardClip].timeScale = defaultTimeScale || 1;
    skinnedMeshMap.actions[leftClip].timeScale = defaultTimeScale || 1;
    skinnedMeshMap.actions[rightClip].timeScale = defaultTimeScale || 1;

}

export function stopBlendTree(
    skinnedMeshMap: LoadedSkinnedMesh,
    leftClip: string,
    forwardClip: string,
    rightClip: string,
) {
    skinnedMeshMap.actions[forwardClip].weight = 0;
    skinnedMeshMap.actions[leftClip].weight = 0;
    skinnedMeshMap.actions[rightClip].weight = 0;

    skinnedMeshMap.actions[forwardClip].stop();
    skinnedMeshMap.actions[leftClip].stop();
    skinnedMeshMap.actions[rightClip].stop();
}