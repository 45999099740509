import { LoadingManager } from "three";
import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export default create(subscribeWithSelector((set: Function) => {

  const loadingManager = new LoadingManager();

  loadingManager.onStart = (url: string, loaded: number, total: number): void | undefined => {
    // console.log(`Start Loading: ${url}  [Loaded: ${loaded}/${total}]`)
  }

  loadingManager.onProgress = (url: string, loaded: number, total: number) => {
    // console.log(`Progress Loading: ${url}  [Loaded: ${loaded}/${total}]`)
  };

  return {
    loadingManager: loadingManager
  };
}));
