import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Experience from "./three/Experience";
import useDarkMode from "./stores/useDarkMode";
import Navigation from "./components/Navigation";
import useMenuState, { MenuStates } from "./stores/useMenuState";
import { timer } from "rxjs"
import useExploreMode, { ExploreModeState } from "./stores/useExploreMode";
import useExperienceState, { ExperienceStates } from "./stores/useExperienceState";
import SceneTransition from "./three/SceneTransition";
import AutoDarkDetection from "./components/AutoDarkDetection";
import PrintLog from "./three/PrintLog";
import useDebugMode from "./stores/useDebugMode";
import Song from "./components/Song";
import useSong from "./stores/useSong";

export default function Root() {
  let location = useLocation();

  const stopSong = useSong(s => s.stop);

  const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);
  const forceDarkModeEnabled = useDarkMode(s => s.forceDarkModeEnabled);
  const exploreModeState = useExploreMode(s => s.exploreModeState);
  const forceQuitExploreMode = useExploreMode(s => s.forceQuitExploreMode);
  const menuState = useMenuState(s => s.menuState);
  const closeMenu = useMenuState(s => s.close);
  const experienceState = useExperienceState(s => s.experienceState);
  const [showpage, setshowpage] = useState<boolean>(true);
  const debugEnabled = useDebugMode(s => s.debugModeEnabled);

  useEffect(() => {
    const emptyLoading = document.querySelector("#empty-loading");
    if (emptyLoading) {
      emptyLoading.remove();
    }

    timer(800).subscribe(() => {
      closeMenu();
    });
    forceQuitExploreMode();

    if (location.pathname !== "/dance"){
      stopSong();
    }
  }, [location]);

  useEffect(() => {
    timer(800).subscribe(() => {
      setshowpage(exploreModeState === ExploreModeState.Disabled);
    })
  }, [exploreModeState])

  const hideUi = () => {
    return menuState !== MenuStates.Closed
      || exploreModeState !== ExploreModeState.Disabled
      || experienceState !== ExperienceStates.Ready;
  }

  return (
    <>
      {/*     
    <ArScene></ArScene>
    <ARButton></ARButton> */}
      <AutoDarkDetection />
      <Experience />
      <SceneTransition />
      <Song />
      <div className={(forceDarkModeEnabled && !darkModeEnabled ? "forced-dark " : "") + (darkModeEnabled || forceDarkModeEnabled ? "dark " : "") + " group font-quicksand overflow-auto h-full "} id="scroll-anchor">
        <div className="flex grow absolute lg:static w-full z-20">
          {experienceState !== ExperienceStates.Loading && <Navigation />}
        </div>
        {
          showpage && experienceState !== ExperienceStates.TransitionOut && experienceState !== ExperienceStates.Loading &&
          <div className={(hideUi() ? "opacity-0 " : "opacity-100") + " transition-all duration-500 flex lg:my-0 landscape:my-0"} >
            <Outlet />
          </div>
        }
      </div>

      {debugEnabled &&
        <PrintLog />
      }
      {/* <LoadingPlane/> */}
    </>
  );
}
