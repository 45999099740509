/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations, Plane } from "@react-three/drei";
import useSkinnedMesh from "./UseSkinnedMesh";
import { assets } from "./Assets";
import { GroupProps, useFrame } from "@react-three/fiber";
import useLoadTexture from "./UseLoadTexture";
import useGetLighting from "./UseGetLighting";
import { Color, Group, SkinnedMesh } from "three";
import useSyncTime from "../stores/useSyncTime";
import { computeBlendTree, initBlendTree } from "./ComputeBlendTree";
import useSyncLocomotion from "../stores/useSyncLocomotion";
import useAnimatedSkinnedMesh from "./UseAnimatedSkinnedMesh";

type GearProps = GroupProps & {
    animation?: string;
    idx: number;
    animTimeScale?: number;
}

export function Gear({ animation, idx, animTimeScale, ...props }: GearProps) {
    const group = useRef<Group>(null!);

    const skinnedMeshMap = useAnimatedSkinnedMesh(assets.gear.model, "Gear", idx, animTimeScale, animation, assets.gear.locomotion, false);
    const texture = useLoadTexture(assets.gear.textures.gear);

    const light = useGetLighting(group);

    return (
        <>
            {!skinnedMeshMap ? (
                <Plane position={[0, 0.025, 0]} rotation={[0, 0, 0]} scale={2.5}>
                    <meshBasicMaterial map={texture} opacity={0} transparent />
                </Plane>
            ) : (
                <group ref={group} {...props} dispose={null}>
                    <group name="Scene">
                        <group name="GearArmature">
                            <primitive object={skinnedMeshMap.objMap["def-rein-02l"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-02r"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-hand-anchor"]} />
                            <primitive object={skinnedMeshMap.objMap["def-jockeyl"]} />
                            <primitive object={skinnedMeshMap.objMap["def-jockeyr"]} />
                            <primitive object={skinnedMeshMap.objMap["def-pad-fl"]} />
                            <primitive object={skinnedMeshMap.objMap["def-pad-fr"]} />
                            <primitive object={skinnedMeshMap.objMap["def-head-stall"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-01l"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-anchor-01l"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-01r"]} />
                            <primitive object={skinnedMeshMap.objMap["def-rein-anchor-01r"]} />
                            <primitive object={skinnedMeshMap.objMap["def-stirrupl"]} />
                            <primitive object={skinnedMeshMap.objMap["def-stirrupr"]} />
                            <primitive object={skinnedMeshMap.objMap["def-fenderl"]} />
                            <primitive object={skinnedMeshMap.objMap["def-fenderr"]} />
                            <primitive object={skinnedMeshMap.objMap["def-saddle"]} />
                            <primitive object={skinnedMeshMap.objMap["def-pad-bl"]} />
                            <primitive object={skinnedMeshMap.objMap["def-pad-br"]} />
                            {/* <primitive object={nodes.neutral_bone} /> */}

                            <skinnedMesh
                                name="Gear"
                                geometry={(skinnedMeshMap.skinnedMeshMap["Gear"] as SkinnedMesh).geometry}
                                material={(skinnedMeshMap.skinnedMeshMap["Gear"] as SkinnedMesh).material}
                                skeleton={(skinnedMeshMap.skinnedMeshMap["Gear"] as SkinnedMesh).skeleton}
                            >
                                <meshBasicMaterial map={texture} color={new Color(light, light, light)} />
                            </skinnedMesh>
                        </group>
                    </group>
                </group>
            )}
        </>
    )
}

useGLTF.preload(assets.gear.model);
