import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export enum ExperienceStates {
  Loading = "Loading",
  Ready = "Ready",
  TransitionIn = "TransitionIn",
  LoadScene = "LoadScene",
  TransitionOut = "TransitionOut"
}

export default create(subscribeWithSelector((set: Function) => {

  return {
    experienceState: ExperienceStates.Loading,
    progressCompleted: false,
    loadedScene: [],
    loadedIdx: 0,

    changeExperienceState: (newState: ExperienceStates) => {
      set((state: any) => {

        // console.log(`Change State [${state.experienceState}] -> [${newState}]`);

        state.experienceState = newState;

        return {};
      });
    },

    setProgressCompleted: (completed: boolean) => {
      set((state: any) => {

        state.progressCompleted = completed;

        return {};
      });
    },

    registerLoadedScene: (scene: string, loaded: boolean) => {
      set((state: any) => {

        if (loaded) {
          state.loadedIdx++;
        }
        state.loadedScene.push(scene);

        return {};
      });
    },
  };
}));