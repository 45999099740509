import create from "zustand";

export default create((set) => {
    return {
      occlude: [],
  
      register: (object3d) => {
        set((state) => {
          state.occlude.push(object3d);
          return {};
        });
      },

      unregister: (object3d) => {
        set((state) => {
            const i = state.occlude.indexOf(object3d);
            if (i >= 0){
                const array = [];
                state.occlude = array.splice(i, 1);
            }
            return {};
          });
      }
    };
  });
  