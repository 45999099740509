import { useMediaQuery } from "react-responsive";
import { Euler, MathUtils, Vector3 } from "three";
import { assets } from "./Assets";
import CameraManager from "./CameraManager";
import { Horse, HorseHair } from "./Horse";
import { Human, HumanTypes } from "./Human";
import LeafParticle from "./LeafParticle";
import { LivingRoom } from "./LivingRoom";
import { Transform } from "./Transform";
import Tree, { TreeTypes } from "./Tree";
import useFakeLight from "./UseFakeLight";
import useScene from "./UseScene";

export default function LivingRoomScene() {

    useScene("/contacts");

    useFakeLight(new Vector3(-0.25, 0.00, 2.25), 14, "paddockLight");

    return (
        <>
            <CameraManager
                original={new Transform(0, 2, 4, 0, 2, 0)}
                portrait={new Transform(0, 3, 15, 0, 6, 0)}
                explore={new Transform(-3.64, 2.76, 3.44, 0, 0, 0)}
                // orbitCtrl
                makeDefault
                maxDistance={50}
                enableDamping
                enablePan={false}
                zoomSpeed={1}
                rotateSpeed={0.25}
                dampingFactor={0.05}
            />
            {/* <LivingRoom /> */}
            <group position={[3.5, -1, -1]}>
                <Tree treeTypes={TreeTypes.LeafAutumn} />
                <LeafParticle />
            </group>
            <Human
                showFork
                humanType={HumanTypes.youngMan}
                position={[2.5, 0, 0]}
                rotation={[0, MathUtils.DEG2RAD * -60, 0]}
                animTimeScale={0.5}
                animation={assets.human.animations.IdleFork}
                scale={1}
                idx={0} />
            <Human
                showFork
                humanType={HumanTypes.farmer}
                position={[-2.8, 0, 0]}
                rotation={[0, MathUtils.DEG2RAD * 60, 0]}
                animTimeScale={0.5}
                animation={assets.human.animations.IdleFork}
                scale={1}
                idx={1} />
        </>
    );
}