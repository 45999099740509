import { useState } from "react";

type Props = {
    img: any,
    idx: number
}

export default function CampsImage({ img, idx }: Props) {
    const delay = 0.2;

    const [show, setshow] = useState<boolean>(false);

    return (
        <>
            {/* <img data-inviewport="scale-in" src={img.src} alt={img.alt} className=" shadow-md w-96 p-4" style={{transitionDelay: `${idx * delay}s`}}/> */}
            <div className="flex flex-col p-4 ">
                {/* <div className="w-full h-6 bg-stone-700"></div> */}
                {/* <div className="w-full h-1 bg-stone-700"></div> */}
                <img src={img.src} alt={img.alt} className=" shadow-md w-96 h-96 object-cover p-2" style={{ transitionDelay: `${idx * delay}s` }} />
                {/* <div className="w-full h-4 bg-stone-700"></div> */}
                {/* <div className="w-full h-1 bg-amber-600"></div> */}
            </div>
        </>
    );
}

