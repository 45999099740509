// eslint-disable-next-line import/no-anonymous-default-export
export default /* glsl */`
#include <tonemapping_fragment>
#include <fog_pars_fragment>

uniform sampler2D uMap;
uniform float uLight;

varying vec2 vUv;
varying vec2 vUv2;

void main() {
    vec4 color = texture2D(uMap, vUv);
    // color = LinearTosRGB(color);

    float y = vUv2.y - floor(vUv2.y / 1.0);
    vec2 repeatUv = vec2(vUv2.x, y);

    vec4 color2 = texture2D(uMap, repeatUv);
     color2.a = 0.3;
    vec4 additive = color + color2;
    additive.rgb *= 0.5;
    additive.rgb += vec3(0.1);
    additive = clamp(additive, vec4(0.0), vec4(1.0));
    additive.rgb *= uLight;
    additive.a = 1.0;
    gl_FragColor = additive;
    #include <fog_fragment>
}
`