import { PrimitiveProps, useFrame, useThree } from "@react-three/fiber";
import { useRef, useState } from "react";
import { Bone, Group, Vector3 } from "three";
import useDustSteps from "../stores/useDustSteps";
import useFootSteps from "../stores/useFootSteps";

type HorseFootProps = PrimitiveProps & {
    idx: number
}

enum FootDirection {
    unknown = "unknown",
    up = "up",
    inAir = "inAir",
    down = "down",
    onground = "onGround"
}

type FootState = {
    // last: number;
    // dir: number;

    lastDirection: FootDirection;
    lastY: number;
}

export default function HorseFoot({ idx, ...props }: HorseFootProps) {
    const foot = useRef<Bone>(null!);
    const [lastpos] = useState<FootState>({ lastDirection: FootDirection.unknown, lastY: 0 });
    const [c, sc] = useState<number>(0);

    const addFootstep = useFootSteps(s => s.add);
    const addDustStep = useDustSteps(s => s.add);

    const dustFactorSync: any = useDustSteps(s => s.dustFactorSync);

    const { clock } = useThree();

    const updateFoot = (foot: Bone, footState: FootState) => {
        const p = new Vector3();
        foot.getWorldPosition(p);
        p.y -= 0.15;

        const y0 = footState.lastY;
        const y1 = p.y;

        const s = y1 - y0;
        const threshold = 0.001;

        let footDirection = FootDirection.unknown;

        if (s > threshold) {
            footDirection = FootDirection.up;
        } else if (s < -threshold) {
            footDirection = FootDirection.down;
        } else {
            switch (footState.lastDirection) {
                case FootDirection.down:
                case FootDirection.onground:
                    footDirection = FootDirection.onground;
                    break;
                case FootDirection.up:
                case FootDirection.inAir:
                    footDirection = FootDirection.inAir;
                    break;
            }
        }

        if (footDirection !== footState.lastDirection && footState.lastDirection !== FootDirection.unknown) {

            const scaleFactor = dustFactorSync[idx.toString()] || 1;

            switch (footDirection) {
                case FootDirection.onground:
                    // addDustStep(p, clock);
                    break;
                case FootDirection.up:
                    addFootstep(p, 0);
                    addDustStep(p, clock, scaleFactor,1);
                    break;
            }
            sc(c+1);
        }

        footState.lastDirection = footDirection;
        footState.lastY = p.y;
    }

    useFrame(() => {
        updateFoot(foot.current, lastpos);
    });

    return <primitive ref={foot} {...props} />;
}