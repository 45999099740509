import { useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { BufferGeometry, Points, ShaderMaterial, Vector3 } from "three";
import { PointsProps, useFrame, useThree } from "@react-three/fiber";
import useLoadTexture from "./UseLoadTexture";
import { assets } from "./Assets";
import { snowParticleFragmentShader, snowParticleVertexShader } from "../shaders/snow-particle-shader";

export default function SnowParticle({...props}: PointsProps) {

    const leafTexture = useLoadTexture(assets.horse.textures.dust);

    const buffer = useRef<BufferGeometry>(null!);
    const shader = useRef<ShaderMaterial>(null!);

    const [startTime, setstarttime] = useState(0);

    const uniforms = useMemo(
        () => ({
            uTime: { value: 0 },
            uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
            uSize: { value: 100 },
            uMap: {value: leafTexture},
            uRange: { value: 7.5 },
            uSoftRange: { value: 0.25 },
        }),
        [leafTexture]
    );

    const {clock} = useThree();

    useFrame((s, d) => {
        if (shader) {
            shader.current.uniforms.uTime.value = s.clock.getElapsedTime() + 1000;
        }
    })

    useEffect(() => {
        setstarttime(clock.elapsedTime);
        const leaveCount = 3000
        const positionArray = new Float32Array(leaveCount * 3)
        const scaleArray = new Float32Array(leaveCount)
        const lifetimeArray = new Float32Array(leaveCount)
        const starttimeArray = new Float32Array(leaveCount)


        for (let i = 0; i < leaveCount; i++) {
            positionArray[i * 3 + 0] = -3.5 + Math.random() * 7;
            positionArray[i * 3 + 1] = Math.random() * 8.5
            positionArray[i * 3 + 2] = -3 + Math.random() * 26;

            scaleArray[i] = Math.random() * 2;
            lifetimeArray[i] = 1.5 + Math.random() * 1.5;
            starttimeArray[i] = Math.random() * 1.5;
        }

        buffer.current.setAttribute('position', new THREE.BufferAttribute(positionArray, 3))
        buffer.current.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))
        buffer.current.setAttribute('alifetime', new THREE.BufferAttribute(lifetimeArray, 1))
        buffer.current.setAttribute('aStartTime', new THREE.BufferAttribute(starttimeArray, 1))

    }, [])

    return (
        <>
            <points  {...props}>
                <bufferGeometry ref={buffer} />
                <shaderMaterial
                        ref={shader}
                        vertexShader={snowParticleVertexShader}
                        fragmentShader={snowParticleFragmentShader}
                        transparent
                        depthWrite={false}
                        uniforms={uniforms}
                    />
            </points>
        </>
    )
}