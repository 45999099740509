import { Vector3 } from "three";
import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export type useFootstepType = {
    idx: number,
    add: Function,
    clear: Function,
    addScroll: Function,
    scrollOffset: number,
    vertices: number[];
    uvs: number[];
    positions: Vector3[];
    z: number[];
}

export default create(subscribeWithSelector((set: Function): useFootstepType => {
    return {
        idx: -1,
        scrollOffset: 0,
        vertices: [],
        uvs: [],
        positions: [],
        z: [],

        add: (position: Vector3, rot: number) => {
            set((state: any) => {
                if (state.vertices.length > (100000 * 3)) {
                    if (state.scrollOffset > 0) {
                        state.clear();
                    }
                    return {};
                }

                state.positions.push(position);

                const s = 0.12;
                const o = state.scrollOffset;

                const sq = (x: number, y: number, z: number) => [
                    x + s, y, z + s + o,
                    x + s, y, z - s + o,
                    x - s, y, z - s + o,
                    x - s, y, z - s + o,
                    x - s, y, z + s + o,
                    x + s, y, z + s + o,
                ];

                const addSq = (x: number, y: number, z: number) => {
                    const v = sq(x, y, z);
                    for (const v1 of v) {
                        state.vertices.push(v1);
                    }
                }

                const addUv = () => {
                    const uv = [
                        1, 1,
                        1, 0,
                        0, 0,
                        0, 0,
                        0, 1,
                        1, 1
                    ]
                    for (const v1 of uv) {
                        state.uvs.push(v1);
                    }
                }

                const p = position;

                addSq(p.x, p.y, p.z);
                addUv();
                state.z.push(o)

                // let splice = -1;
                // if (state.scrollOffset > 0) {

                //     let range = -10;
                //     for(let i = 0; i < state.z.length; i++){
                //         const n = state.positions[i].z + state.z[i] - state.scrollOffset;
                //         if (n <range){
                //             splice = i;
                //         }
                //         if (n > range){
                //             break;
                //         }
                //     }
                //     if (splice !== -1) {
                //         state.vertices = state.vertices.splice(splice * 3 * 6);
                //         state.uvs = state.uvs.splice(splice * 2 * 6);
                //         state.positions = state.positions.splice(splice);
                //         state.z = state.z.splice(splice);
                //     }
                // }

                state.idx++;
                return {};
            });
        },

        clear: () => {
            set((state: any) => {
                state.footsteps = [];
                state.vertices = [];
                state.uvs = [];
                state.footsteps = [];
                state.idx = -1;
                state.scrollOffset = 0;
                return {};
            });
        },

        addScroll: (offset: number) => {
            set((state: any) => {
                state.scrollOffset += offset;
                return {};
            });
        }
    };
}));