import { useEffect } from "react";
import { Vector3 } from "three";
import useDarkMode from "../stores/useDarkMode";
import useFootSteps from "../stores/useFootSteps";
import { assets } from "./Assets";
import CameraManager from "./CameraManager";
import { CampsGround } from "./CampsGround";
import FootSteps from "./FootSteps";
import { Gear } from "./Gear";
import Grass from "./Grass";
import { Horse, HorseHair } from "./Horse";
import { Human, HumanTypes } from "./Human";
import MovementScroll from "./MovementScroll";
import { Transform } from "./Transform";
import Tree, { TreeTypes } from "./Tree";
import useFakeLight from "./UseFakeLight";
import useScrollCameraEffect from "./UseScrollCameraEffect";
import useScene from "./UseScene";

export default function CampScene() {

    useScene("/camps-de-jour");
    
    useFakeLight(new Vector3(0, 0, 20), 22, "camp");
    useFakeLight(new Vector3(0, 0, -20), 22, "camp2");
    useFakeLight(new Vector3(20, 0, 0), 22, "camp3");
    useFakeLight(new Vector3(-20, 0, 0), 22, "camp4");

    const clearFootstep = useFootSteps(s => s.clear);

    const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);

    useScrollCameraEffect();

    useEffect(() => {
        clearFootstep();
    }, []);

    return <>
        {/* position: [10.03, 9.44, 24.41], rotation: [-0.10, 0.38, 0.04] */}
        <CampsGround />
        {!darkModeEnabled && <fog attach="fog" args={[0xfca832, 15, 60]} />}
        {darkModeEnabled && <fog attach="fog" args={[0x474c54, 5, 60]} />}
        <CameraManager
            original={new Transform(6.81, 6.64, 16.07, 0, 4, 0)}
            portrait={new Transform(10, 8, 20, 0, 7, 0)}
            landscape={new Transform(0, 1, 11, 0, 4.6, 0)}
            explore={new Transform(-6, 3, 9, 0, 0, 0)}
            // orbitCtrl
            makeDefault
            minPolarAngle={Math.PI * 0.1}
            maxPolarAngle={Math.PI * 0.49}
            maxDistance={40}
            minDistance={10}
            maxZoom={10}
            minZoom={10}
            zoomSpeed={1}
            rotateSpeed={0.25}
            enableDamping
            enablePan={false}
            dampingFactor={0.05}
        />

        {
            !darkModeEnabled &&
            <>
                <group position={new Vector3(0, 0, 6)}>
                    <Horse
                        idx={0}
                        animation={assets.horse.animations.WalkCycle}
                        hair={HorseHair.double}
                        bodyTexture={assets.horse.textures.okelaBody}
                        hairTexture={assets.horse.textures.okelaHair}
                    />
                    <Gear
                        idx={0}
                        animation={assets.gear.animations.WalkCycle}
                    />
                    <Human
                        humanType={HumanTypes.farmer}
                        animation={assets.human.animations.WalkCycle}
                        idx={0}
                    />
                </group>

                <group position={new Vector3(1, 0, 0)}>
                    <Horse
                        idx={1}
                        animation={assets.horse.animations.WalkCycle}
                        hair={HorseHair.double}
                        bodyTexture={assets.horse.textures.mooglyBody}
                        hairTexture={assets.horse.textures.mooglyHair}
                    />
                    <Gear
                        idx={1}
                        animation={assets.gear.animations.WalkCycle}
                    />
                    <Human
                        humanType={HumanTypes.youngMan}
                        animation={assets.human.animations.WalkCycle}
                        idx={1}
                    />
                </group>

                <group position={new Vector3(1.2, 0, 4)}>
                    <Horse
                        idx={3}
                        animation={assets.horse.animations.WalkCycle}
                        hair={HorseHair.long}
                        bodyTexture={assets.horse.textures.pitaBody}
                        hairTexture={assets.horse.textures.pitaHair}
                    />
                    <Gear
                        idx={3}
                        animation={assets.gear.animations.WalkCycle}
                    />
                    <Human
                        humanType={HumanTypes.youngWoman}
                        animation={assets.human.animations.WalkCycle}
                        idx={3}
                    />
                </group>


            </>
        }

        {/* <FootSteps /> */}
        {/* speed=0.8 */}
        <MovementScroll p0={new Vector3(0, 0, 15)} p1={new Vector3(0, 0, -100)} speed={darkModeEnabled ? 0 : 0.8}>

            <FootSteps scrolling />
            {/* <DustParticle scrolling /> */}
            {/* <TestSphere></TestSphere> */}
            <Tree
                scrolling
                position={[5, 0, 5.5]}
                rotation={[0, -0.523, 0]}
                scale={0.82} />
            <Tree
                scrolling
                position={[-7, 0, 2]}
                rotation={[0, -0.523, 0]}
                scale={0.82} />
            <Tree

                treeTypes={TreeTypes.Pine}
                scrolling
                position={[-4.5, 0, 5]}
                rotation={[0, 3.14, 0]}
                scale={1.2} />
            <Tree
                treeTypes={TreeTypes.Pine}
                scrolling
                position={[3, 0, .3]}
                rotation={[0, -0.523, 0]}
                scale={[.5, 1.5, .5]} />
            <Tree
                treeTypes={TreeTypes.Pine}
                scrolling
                position={[-5, 0, 1]}
                rotation={[0, -0.23, 0]}
                scale={[.5, 1, .5]} />

            <Grass scrolling position={[0, 0, 0]} />
            <Grass scrolling position={[0, 0, -8]} />
            <Grass scrolling position={[0, 0, -16]} />
            {/* <LeafParticle/> */}
        </MovementScroll>
    </>
}
