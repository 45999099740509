import { useEffect, useRef, useState } from "react";
import useSong from "../stores/useSong";
import { assets } from "../three/Assets";

export default function Song() {

    const audio = useRef<HTMLAudioElement>(null!);

    const muted = useSong(s => s.muted);
    const playing = useSong(s => s.playing);

    const [localPlaying, setlocalPlaying] = useState(false);

    useEffect(() => {
        if (audio.current && playing && !localPlaying) {
            audio.current.play();
            setlocalPlaying(true);
        }
        if (audio.current && !playing) {
            audio.current.pause();
            audio.current.currentTime = 0;
            setlocalPlaying(false);
        }
    }, [playing])

    useEffect(() => {
        if (audio.current){
            audio.current.muted = muted;
        }
    }, [muted])

    return <>
        <audio ref={audio} loop>
            <source src={assets.human.song.file} type="audio/mpeg" />
        </audio>
    </>
}