import { useTexture } from "@react-three/drei";
import { Texture } from "three";

export default function useLoadTexture(url: string): Texture {
    const texture = useTexture(url);
    texture.flipY = false;

    // useTexture.preload(te)

    return texture;
}
