import { Euler, Matrix4, Vector3 } from "three";
import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'
import { Transform } from "../three/Transform";

export default create(subscribeWithSelector((set: Function) => {
  return {
    transitionDuration: 0,
    targetPosition: new Vector3(18, 5, 18),
    targetLookAt: new Vector3(),
    forcedPosition: new Vector3(),
    forcedLookAt: new Vector3(),

    getTarget: () : Vector3 => {
      const pos = new Vector3();
      set((state: any) => {
        pos.copy(state.targetPosition);
        return {};
      });
      return pos;
    },

    setTarget: (transform: Transform, transitionDuration: number) => {
      set((state: any) => {
        state.transitionDuration = transitionDuration;
        state.targetPosition = transform.position;
        state.targetLookAt = transform.lookAt;
        return {};
      });
    },
  };
}));
