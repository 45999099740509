import { MdArrowCircleDown } from "react-icons/md";
import {
    Link,
    Element
  } from "react-scroll";
import CommonButton from "./CommonButton";

type Props = {
    title: React.ReactNode; 
    subtitle: React.ReactNode;
}

export default function PageHeader({title, subtitle}: Props) {
    return (
        <Element name="intro" className="h-screen w-screen snap-start">
            <div className="flex flex-col grow items-center text-stone-700 dark:text-stone-300 pt-3 lg:pt-10 portrait:pt-10 pb-5 portrait:mt-16 w-3/5 portrait:w-auto lg:w-auto mx-auto portrait:mx-4">
                <div className="flex flex-col grow justify-center items-center p-5 fadeIn group-forced-dark:bg-stone-900/80 group-forced-dark:rounded-lg group-forced-dark:shadow-lg">
                    <h2 className="text-2xl font-bold fadeIn group-forced-dark:underline decoration-orange-700">{title}</h2>
                    <p className=" lg:text-lg text-md my-1 portrait:my-5 lg:my-5 fadeIn text-center">{subtitle}</p>
                </div>
                <span className="appearFromRight lg:animation-delay-600 animation-delay-2100 ">
                    <Link to="section-01" smooth={true} spy={true} duration={500} >
                        <CommonButton active={true}>
                            <div className="flex items-center">
                                <MdArrowCircleDown className="text-2xl mr-2" />
                                <p>Plus d'informations</p>
                            </div>
                        </CommonButton>
                    </Link>
                </span>
            </div>
        </Element>
    );
}