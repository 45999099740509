import { Euler, Vector3 } from "three";
import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export type useLightSourceType = {
    lights: FakeLight[],
    add: Function,
    remove: Function
}

export class FakeLight {
    pos!: Vector3;
    range!: number;
    id!: string;
}

export default create(subscribeWithSelector((set: Function): useLightSourceType => {
    return {
        lights: [],

        add: (fakeLight: FakeLight) => {
            set((state: any) => {
                const fakeLights: FakeLight[] = state.lights;
                if (!fakeLights.find(s => s.id === fakeLight.id)) {
                    state.lights.push(fakeLight);
                }
                return {};
            });
        },

        remove: (fakeLight: FakeLight) => {
            set((state: any) => {
                const fakeLights: FakeLight[] = state.lights;
                const idx = fakeLights.findIndex(s => s.id === fakeLight.id);
                if (idx !== -1) {
                    fakeLights.splice(idx, 1);
                    state.lights = fakeLights;
                }
                return {};
            });
        }
    };
}));