import { Element } from "react-scroll";
import CampsItem from "./CampsItem";
import GetFacebookLink from "./FacebookLink";
import PageHeader from "./PageHeader";
import Underline from "./Underline";
import UnderlineSmall from "./UnderlineSmall";
import CourseFormation from "./CourseFormation";
import { images } from "./Images";

export default function Competition() {

  return <>
    <div className="flex flex-col">
      <PageHeader
        title={<>Compétitions</>}
        subtitle={<>Compétition de Gymkhana</>}
      />


      <Element name="section-01" className="w-screen min-h-screen bg-stone-700 text-stone-300 relative snap-start p-4 flex flex-row">
        <div className="w-full portrait:w-4"></div>
        <div className="flex flex-col grow mt-2 lg:mt-8 landscape:max-w-6xl w-full">
          <div className="text-3xl lg:text-6xl font-extrabold">Compétition de Gymkhana</div>
          <div className="text-md lg:text-2xl font-light pt-2">Chevalex participe aux compétitons de Gymkhana 2025 à Sainte-Brigide-d'Iberville avec sa super équipe!</div>
          <Underline />

          
          <div className="flex flex-col grow r h-full mb-20">
            <p className="text-lg font-light">
              Plus d'informations à venir
            </p>
          </div>

        </div>
        <div className="w-full portrait:w-4"></div>
      </Element>


      <Element name="section-02" className="w-screen min-h-screen-more bg-stone-100 relative snap-start p-4 flex flex-row">
        <div className="w-36 portrait:w-4"></div>
        <div className="flex flex-col grow mt-20">
          <div className="text-3xl lg:text-4xl font-extrabold">Nos Commanditaires</div>
          <div className="text-md lg:text-2xl font-light pt-2">Merci à tous nos commanditaires!</div>
          <Underline />
          <div className="flex flex-col flex-wrap lg:flex-row landscape:flex-row grow justify-between items-center">
            <CourseFormation
              img={images.jrchoiniere}
              title={"JR Choiniere"}
              description={"Développeur du site web."}
              link="https://jrchoiniere.com" />

          </div>
        </div>
        <div className="w-36 portrait:w-4"></div>
      </Element>

    </div>
  </>
}
