import { useFrame } from "@react-three/fiber";
import { useEffect } from "react";
import { computeBlendTree, initBlendTree, stopBlendTree } from "./ComputeBlendTree";
import useSkinnedMesh, { LoadedSkinnedMesh } from "./UseSkinnedMesh";
import useSyncTime from "../stores/useSyncTime";
import useSyncLocomotion from "../stores/useSyncLocomotion";

export default function useAnimatedSkinnedMesh(model: string, idPrefix: string, idx: number, animTimeScale?: number, animation?: string, locomotion?: any, randomAnimPrewarm?: boolean): LoadedSkinnedMesh {

  const skinnedMeshMap: LoadedSkinnedMesh = useSkinnedMesh(model, `${idPrefix}_${idx}`);

  const syncAnimation = useSyncTime(s => s.syncAnimation);
  const animSync: any = useSyncTime(s => s.animationsSync);

  const locomotionSync: any = useSyncLocomotion(s => s.locomotionsSync);

  // ------- SINGLE ANIMATION LOOP ------------ //
  useEffect(() => {
    if (!animation || !skinnedMeshMap) {
      return;
    }

    if (!skinnedMeshMap.actions[animation]) {
      console.warn('missing animation for horse: ' + animation);
      return;
    }

    if (randomAnimPrewarm) {
      skinnedMeshMap.actions[animation].time = skinnedMeshMap.actions[animation].getClip().duration * (idx / 4);
    }

    skinnedMeshMap.actions[animation].timeScale = animTimeScale || 1;
    skinnedMeshMap.actions[animation].weight = 1;

    syncAnimation(`Anim_${idx}`, skinnedMeshMap.actions[animation].getClip().duration);

    skinnedMeshMap.actions[animation].play();

    return () => {
      if (skinnedMeshMap && animation) {
        skinnedMeshMap.actions[animation].stop();
      }
    };

  }, [animation, animTimeScale, skinnedMeshMap, skinnedMeshMap?.animationMixer]);


  useEffect(() => {
    if (!animation || !skinnedMeshMap || !skinnedMeshMap.actions[animation]) {
      return;
    }

    skinnedMeshMap.actions[animation].time = animSync[`Anim_${idx}`];
    skinnedMeshMap.actions[animation].timeScale = animTimeScale || 1;

  }, [animSync, animTimeScale])

  // ------- SINGLE LOCOMOTION LOOP ------------ //
  useEffect(() => {
    if (!skinnedMeshMap || !locomotion || animation) {
      return;
    }

    initBlendTree(
      skinnedMeshMap,
      locomotion["left"],
      locomotion["forward"],
      locomotion["right"],
      animTimeScale || 1
    );

    return () => {
      if (skinnedMeshMap && locomotion) {
        stopBlendTree(
          skinnedMeshMap,
          locomotion["left"],
          locomotion["forward"],
          locomotion["right"]);
      }
    };

  }, [animation, skinnedMeshMap, skinnedMeshMap?.animationMixer, animTimeScale]);


  useFrame((state, delta) => {
    if (!skinnedMeshMap || !locomotion || animation) {
      return;
    }

    computeBlendTree(
      skinnedMeshMap,
      locomotion["left"],
      locomotion["forward"],
      locomotion["right"],
      animTimeScale || 1,
      locomotionSync[idx.toString()],
      delta
    );
  });

  return skinnedMeshMap;
}