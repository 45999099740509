/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations, Plane } from "@react-three/drei";
import { assets } from "./Assets";
import { GroupProps, useFrame } from "@react-three/fiber";
import { Color, Group, Object3D } from "three";
import useLoadTexture from "./UseLoadTexture";

type Props = GroupProps & {
}

export function FarmerDance({ ...props }: Props) {
    const group = useRef<Group>(null!);
    const test = useRef<Object3D>(null!);
    const shadow = useRef<Group>(null!);
    const { nodes, materials, animations } = useGLTF(assets.farmerDance.model);
    const { actions } = useAnimations(animations, group);
    const texture = useLoadTexture(assets.farmerDance.textures.farmer);
    const tShadow = useLoadTexture(assets.human.textures.shadow);

    useEffect(() => {
        console.log(actions);
        actions["Dance"]?.play();
    }, [actions])

    useFrame(() => {
        if (test.current && shadow.current){
            shadow.current.position.copy(test.current.position);
            shadow.current.position.y = 0;
        }
    })

    return (
        <group ref={group} {...props} dispose={null}>
            <group name="Scene">
                <group name="Armature">
                    <primitive ref={test} object={nodes.mixamorigHips} />
                    <skinnedMesh
                        name="Farmer"
                        geometry={(nodes as any).Farmer.geometry}
                        material={(nodes as any).Farmer.material}
                        skeleton={(nodes as any).Farmer.skeleton}
                    >
                        <meshBasicMaterial map={texture} />
                    </skinnedMesh>
                    <group ref={shadow}>
                    <Plane
                        position={[0, 0.001, 0]}
                        rotation={[Math.PI * -0.5, 0, 0]}
                        scale={1.8}
                    >
                        <meshBasicMaterial
                            color="black"
                            transparent
                            alphaMap={tShadow}
                            opacity={0.4}
                            depthWrite={false}
                        />
                    </Plane>
                    </group>
                </group>
            </group>
        </group>
    );
}

useGLTF.preload(assets.farmerDance.model);