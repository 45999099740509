
// vertex shader
export const dustParticleScrollVertexShader = /* glsl */`

uniform float uTime;
uniform float uDeltaTime;
uniform float uPixelRatio;
uniform float uSize;
uniform vec3 uLight;
uniform float uDarkMode;

attribute float aScale;
attribute float aLifetime;
attribute vec3 aVelocity;
attribute float aStartime;

varying vec2 vUv;
varying float vLifetime;
varying float vStarttime;
varying float vScale;
varying float vLightDistance;
varying vec4 vPos;

void main()
{
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    float t = (uTime - aStartime) / aLifetime;
    // t = 1.0;
    float s = (0.1 + pow(t,0.5)) * aScale;
    modelPosition += vec4(
        aVelocity.x * (1.0 - t),
        aVelocity.y,
        aVelocity.z,
        0.0
    ) * t;

    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;

    gl_Position = projectionPosition;
    
    gl_PointSize = uSize * s * uPixelRatio;
    gl_PointSize *= (1.0 / - viewPosition.z);
    
    vLightDistance = distance(vec3(modelPosition), uLight);
    vLifetime = aLifetime;
    vStarttime = aStartime;
    vScale = aScale;
    vUv = uv;
    vPos = modelPosition;
}
`;

// fragment shader
export const dustParticleScrollFragmentShader = /* glsl */`


uniform float uTime;
uniform sampler2D uMap;
uniform float uDarkMode;
uniform vec3 uColor;

varying vec2 vUv;
varying float vLifetime;
varying float vStarttime;
varying float vScale;
varying float vLightDistance;
varying vec4 vPos;
uniform float uRange;
uniform float uSoftRange;

void main()
{
    vec4 color = texture2D(uMap, gl_PointCoord);

    // float distanceToCenter = distance(gl_PointCoord, vec2(0.5));
    // float strength = 0.05 / distanceToCenter - 0.1;

    float t = 1.0 - ((uTime - vStarttime) / vLifetime);
    // t = 1.0;
    float r = ((vScale - 10.0) / 4.0) * 0.6;
    vec3 dustColor = uColor;// * (1.0-(r*r));

    float light = (vLightDistance / 8.0);
    float lightFactor = mix(1.0, 1.0-(light * light), uDarkMode);
    lightFactor = clamp(lightFactor, 0.1, 1.0);

    float a =  1.0 - abs(vPos.z / uRange);
    float len = sqrt(vPos.z * vPos.z + vPos.x * vPos.x );
    float soft = (uRange - abs(len))  / uSoftRange;

    soft = clamp(soft, 0.0, 1.0);

    color.x *= dustColor.x * lightFactor;
    color.y *= dustColor.y * lightFactor;
    color.z *= dustColor.z * lightFactor;
    color.w *= 0.8 * t * lightFactor * soft;

    float f = (vLightDistance / 8.0);
    // gl_FragColor = vec4(1.0-(r*r), 0.0,0.0,1.0);
    // gl_FragColor = vec4(1.0 - (f*f), 0.0,0.0,0.5);
    gl_FragColor = color;
}
`