// eslint-disable-next-line import/no-anonymous-default-export
export default /* glsl */`


uniform sampler2D uMap;
uniform float uTime;
uniform float uRange;
uniform float uSoftRange;

varying vec2 vUv;
varying float vScale;
varying vec4 vPosition;
varying vec4 vPos;
varying float vt;

void main()
{
    float angle = radians(vPosition.z * vPosition.x * 2.0) * vt;
    
    vec2 uv = gl_PointCoord - vec2(0.5,0.5);
    mat2 rotationMatrix = mat2(
        cos(angle), -sin(angle),
        sin(angle), cos(angle)
    );

    uv = rotationMatrix * uv + vec2(0.5,0.5);;
    vec4 color = texture2D(uMap, uv);

    float a =  1.0 - abs(vPos.z / uRange);
    float len = sqrt(vPos.z * vPos.z + vPos.x * vPos.x );
    float soft = (uRange - abs(len))  / uSoftRange;
    float factor = vPos.y < 0.0 ? 0.0: 1.0;
    soft *= factor;

    soft = clamp(soft, 0.0, 1.0);
    color.w *= soft;

    vec3 c1 = vec3(1, 0.823, 0.301);
    vec3 c2 = vec3(0.878, 0.219, 0);
    float t= vScale / 5.0;
    vec3 variant = mix(c1, c2, t);
    color.xyz *= variant;

    gl_FragColor = color;
    // gl_FragColor = vec4(soft, 0.0,0.0,1.0);
}`