/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { assets } from "./Assets";
import { GroupProps } from "@react-three/fiber";
import { Mesh } from "three";
import useLoadTexture from "./UseLoadTexture";
import MeshBasicMaterialLOD from "./MeshBasicMaterialLOD";

export function Stable(props: GroupProps) {
  const { nodes, materials } = useGLTF(assets.stable.model);

  const textureGround = useLoadTexture(assets.stable.textures.room);
  const textureProps = useLoadTexture(assets.stable.textures.props);

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.Room as Mesh).geometry}
        material={(nodes.Room as Mesh).material}
        position={[-5.52, -0.8, 0.02]}
        >
        <meshBasicMaterial map={textureGround} />
        {/* <MeshBasicMaterialLOD textureLods={assets.stable.textureLods.room} /> */}
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.Neon as Mesh).geometry}
        material={(nodes.Neon as Mesh).material}
        position={[0.31, 0.55, 1.66]}
        rotation={[0, 0, -Math.PI]}
        >
        <meshBasicMaterial color={"white"} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={(nodes.Props as Mesh).geometry}
        material={(nodes.Props as Mesh).material}
        position={[0.31, 0.55, 1.66]}
        rotation={[0, 0, -Math.PI]}
        >
        <meshBasicMaterial map={textureProps} />
        {/* <MeshBasicMaterialLOD textureLods={assets.stable.textureLods.props} /> */}
      </mesh>
    </group>
  );
}

useGLTF.preload(assets.stable.model);
