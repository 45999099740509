import { useGLTF, useTexture } from "@react-three/drei";
import { assets } from "./Assets";

export default function CampScenePreload(){
    // Camp
    useGLTF.preload(assets.campGround.model);
    useTexture.preload( assets.campGround.textures.grass);
    useTexture.preload(assets.campGround.textures.dirt);
    useTexture.preload(assets.paddock.textures.groundNight);
    useTexture.preload(assets.paddock.textures.groundNight);

    // Grass
    useGLTF.preload(assets.grass.model);
    useTexture.preload(assets.grass.textures.grass);
    useTexture.preload(assets.grass.textures.grassAlpha);

    // Pine
    useGLTF.preload(assets.pine.model);
    useTexture.preload(assets.pine.textures.branch);
    useTexture.preload(assets.pine.textures.branchAlpha);
    useTexture.preload(assets.pine.textures.trunk);

    return <></>
}