const v = "a17d1"

const superSlow = 4;
const slow = 8;
const fast = 14;
const superfast = 18;

export const assets = {
    tree: {
        model: `./assets${v}/tree/${v}tree.glb`,
        textures: {
            branch: `./assets${v}/tree/${v}branch.jpg`,
            branchAutumn: `./assets${v}/tree/${v}autumnbranch.jpg`,
            branchWinter: `./assets${v}/tree/${v}winterbranch2.jpg`,
            branchAlpha: `./assets${v}/tree/${v}branchAlpha.jpg`,
            branchAlphaWinter: `./assets${v}/tree/${v}branchWinterAlpha.jpg`,
            trunk: `./assets${v}/tree/${v}trunk.jpg`,
            cloud: `./assets${v}/tree/${v}cloud.jpg`,
        }
    },
    pine: {
        model: `./assets${v}/pine/${v}pine.glb`,
        textures: {
            branch: `./assets${v}/pine/${v}pineBranch.jpg`,
            branchWinter: `./assets${v}/pine/${v}pineBranchWinter.jpg`,
            branchAlpha: `./assets${v}/pine/${v}pineBranchAlpha.jpg`,
            trunk: `./assets${v}/pine/${v}pineTrunk.jpg`,
        }
    },
    cedar: {
        model: `./assets${v}/cedar/${v}cedar.glb`,
        textures: {
            branch: `./assets${v}/cedar/${v}cedar.jpg`,
            branchAlpha: `./assets${v}/cedar/${v}cedarAlpha.jpg`,
        }
    },
    herb: {
        model: `./assets${v}/herbs/${v}herb.glb`,
        textures: {
            herb: `./assets${v}/herbs/${v}herb.jpg`,
            herbAlpha: `./assets${v}/herbs/${v}herb-alpha.jpg`,
        }
    },
    grass: {
        model: `./assets${v}/grass/${v}Grass.glb`,
        textures: {
            grass: `./assets${v}/grass/${v}Grass01.jpg`,
            grassAlpha: `./assets${v}/grass/${v}Grass01Alpha.jpg`,
        }
    },
    farm: {
        model: `./assets${v}/farm/${v}farm.glb`,
        textures: {
            ground: `./assets${v}/farm/${v}farm-ground-1024.jpg`,
            groundNight: `./assets${v}/farm/${v}NightGround.jpg`,
            props: `./assets${v}/farm/${v}farm-props-1024.jpg`,
            propsNight: `./assets${v}/farm/${v}NightProps.jpg`,
            fence: `./assets${v}/farm/${v}farm-fence-1024.jpg`,
            fenceNight: `./assets${v}/farm/${v}NightFence.jpg`,
            fenceAlpha: `./assets${v}/farm/${v}farm-fence-alpha-1024.jpg`,
            frame: `./assets${v}/farm/${v}farm-frame-512.jpg`,
            frameNight: `./assets${v}/farm/${v}NightFrame.jpg`,
            water: `./assets${v}/farm/${v}farm-water.jpg`,
            waterNight: `./assets${v}/farm/${v}NightWater.jpg`
        },
        textureLods: {
            props: { resolutions: [256, 512, 1024, 4096], texture: "./assets${v}/farm/farm-props-$.jpg" },
            ground: { resolutions: [256, 512, 1024, 4096], texture: "./assets${v}/farm/farm-ground-$.jpg" }
        }
    },
    stable: {
        model: `./assets${v}/stable/${v}stable.glb`,
        textures: {
            room: `./assets${v}/stable/${v}room-1024.jpg`,
            props: `./assets${v}/stable/${v}props-1024.jpg`,
        },
        textureLods: {
            room: { resolutions: [256, 512, 1024, 4096], texture: "./assets${v}/stable/room-$.jpg" },
            props: { resolutions: [256, 512, 1024, 4096], texture: "./assets${v}/stable/props-$.jpg" }
        }

    },
    livingRoom: {
        model: `./assets${v}/living-room/${v}living-room.glb`,
        textures: {
        }
    },
    stable2: {
        model: `./assets${v}/stable/${v}Grid.glb`,
        textures: {
            gridAlpha: `./assets${v}/stable/${v}gridAlpha.jpg`,
            grid: `./assets${v}/stable/${v}grid.jpg`,
        }
    },
    paddock: {
        model: `./assets${v}/paddock/${v}Paddock.glb`,
        grassModel: `./assets${v}/paddock/${v}PaddockGrass.glb`,
        textures: {
            ground: `./assets${v}/paddock/${v}Paddock_Room-1024.jpg`,
            groundNight: `./assets${v}/paddock/${v}PaddockNight.jpg`,
            props: `./assets${v}/paddock/${v}Paddock_Props-1024.jpg`,
            propsNight: `./assets${v}/paddock/${v}PaddockPropsNight.jpg`,
        }
    },
    steBrigitte: {
        model: `./assets${v}/steBrigitte/${v}SteBrigitte.glb`,
        modelLight: `./assets${v}/steBrigitte/${v}LightEffect.glb`,
        textures: {
            ground: `./assets${v}/steBrigitte/${v}MergedGround.jpg`,
            groundNight: `./assets${v}/steBrigitte/${v}MergedGround_dark.jpg`,
            props1: `./assets${v}/steBrigitte/${v}Props1.jpg`,
            props1Night: `./assets${v}/steBrigitte/${v}Props1_dark.jpg`,
            props2: `./assets${v}/steBrigitte/${v}Props2.jpg`,
            props2Night: `./assets${v}/steBrigitte/${v}Props2_dark.jpg`,
            props3: `./assets${v}/steBrigitte/${v}Props3.jpg`,
            props3Night: `./assets${v}/steBrigitte/${v}Props3_dark.jpg`,
            pubs: `./assets${v}/steBrigitte/${v}pubs.jpg`,
            pubsNight: `./assets${v}/steBrigitte/${v}pubs_dark.jpg`,
            grassColor: `./assets${v}/steBrigitte/${v}grassColor.jpg`,
            grassAlpha: `./assets${v}/steBrigitte/${v}grassAlpha.jpg`,
        }
    },
    leaf: {
        textures: {
            leaf: `./assets${v}/tree/${v}leaf2.png`,
        }
    },
    campGround: {
        model: `./assets${v}/camps/${v}campsGround.glb`,
        textures: {
            grass: `./assets${v}/camps/${v}seamless-grass.jpg`,
            checker: `./assets${v}/camps/${v}checker.jpg`,
            dirt: `./assets${v}/camps/${v}dirt.jpg`,
            mask: `./assets${v}/camps/${v}PathMask.jpg`,
        }
    },
    miniScenes: {
        model: `./assets${v}/miniscenes/${v}MiniScenes.glb`,
        textures: {
            miniscenesNight: `./assets${v}/miniscenes/${v}miniscene_night.jpg`,
            miniscenes: `./assets${v}/miniscenes/${v}miniscene_day.jpg`,
            scrolling: `./assets${v}/miniscenes/${v}winterScroll.jpg`,
            snow: `./assets${v}/miniscenes/${v}snow.jpg`,
        }
    },
    farmerDance: {
        model: `./assets${v}/man/${v}dance.glb`,
        textures: {
            farmer: `./assets${v}/man/${v}man.jpg`,
        },
    },
    human: {
        model: `./assets${v}/man/${v}human3.glb`,
        textures: {
            farmer: `./assets${v}/man/${v}man.jpg`,
            manCloths: `./assets${v}/man/${v}ManCloths.jpg`,
            womanCloths: `./assets${v}/man/${v}WomanCloths.jpg`,
            fork: `./assets${v}/man/${v}fork.jpg`,
            shadow: `./assets${v}/man/${v}shadow.jpg`
        },
        song: {
            file: `./assets${v}/man/${v}song.mp3`
        },
        animations: {
            WalkCycle: "WalkCycle_Human",
            TrotCycle: "TrotCycle_Human",
            Walk: "WalkCycle_HumanGround",
            BrushHorse: "Brush_Human",
            DriveSleigh: "TrotCycle_HumanSleigh",
            IdleApple: "IdleApple_Human",
            IdleFork: "IdleFork_Human",
            IdleFence: "IdleFence_Human",
            sit: "Sit",
            sitCheers: "Sit_Cheer",
            sitClap: "Sit_Clap",
            standCheers: "Stand_Cheer",
        },
        locomotion: {
            forward: "Human_FastGallop_Forward",
            left: "Human_FastGallop_Left",
            right: "Human_FastGallop_Right",
        }
    },
    gear: {
        model: `./assets${v}/gear/${v}gear2.glb`,
        textures: {
            gear: `./assets${v}/gear/${v}gear.jpg`,
        },
        animations: {
            Idle: "Idle_Gear",
            WalkCycle: "WalkCycle_Gear",
            TrotCycle: "TrotCycle_Gear",
        },
        locomotion: {
            forward: "Gear_FastGallop_Forward",
            left: "Gear_FastGallop_Left",
            right: "Gear_FastGallop_Right",
        }
    },
    sleigh: {
        model: `./assets${v}/sleigh/${v}sleigh.glb`,
        textures: {
            sleigh: `./assets${v}/sleigh/${v}sleigh.jpg`,
            hitch: `./assets${v}/sleigh/${v}hitch.jpg`,
        }
    },
    horse: {
        model: `./assets${v}/horse/${v}horse2.glb`,
        textures: {
            horse: `./assets${v}/horse/${v}horse.png`,
            shadow: `./assets${v}/horse/${v}horse-shadow.jpg`,
            footstep: `./assets${v}/horse/${v}footstep.png`,
            dust: `./assets${v}/horse/${v}dust.png`,
            pita: `./assets${v}/horse/${v}pita-body.jpg`,
            pitaBody: `./assets${v}/horse/${v}pita-body.jpg`,
            pitaHair: `./assets${v}/horse/${v}pita-hair.jpg`,
            hope: `./assets${v}/horse/${v}hope.jpg`,
            hopeBody: `./assets${v}/horse/${v}hope-body.jpg`,
            hopeHair: `./assets${v}/horse/${v}hope-hair.jpg`,
            okela: `./assets${v}/horse/${v}okela.jpg`,
            okelaBody: `./assets${v}/horse/${v}okela-body.jpg`,
            okelaHair: `./assets${v}/horse/${v}okela-hair.jpg`,
            mooglyBody: `./assets${v}/horse/${v}moogly-body.jpg`,
            mooglyHair: `./assets${v}/horse/${v}moogly-hair.jpg`,
        },
        animations: {
            Idle: "Idle_Horse",
            IdleApple: "IdleApple_Horse",
            IdleEat: "IdleEat_Horse",
            TrotCycle: "TrotCycle_Horse",
            WalkCycle: "WalkCycle_Horse",
            FastGallopCycle: "FastGallopCycle"
        },
        locomotion: {
            forward: "Horse_FastGallopCycle_Forward",
            left: "Horse_FastGallopCycle_Left",
            right: "Horse_FastGallopCycle_Right",
        }

    },
    path: {
        farm: [
            { speed: 0.8, pos: [7.05, 0, -0.41] }, // 0
            { speed: 0.8, pos: [9.03, 0, 0.29] }, // 1
            { speed: 0.8, pos: [9.98, 0, 2.30] }, // 2
            { speed: 0.8, pos: [8.94, 0, 2.34] }, // 3
            { speed: 0.8, pos: [7.05, 0, 3.67] }, // 4
            { speed: 0.8, pos: [5.58, 0, 4.30] }, // 5
            { speed: 0.8, pos: [-1.15, 0, 3.79] }, // 6
            { speed: 0.8, pos: [-8.10, 0, 2.93] }, // 7
            { speed: 0.8, pos: [-8.39, 0, 0.82] }, // 8
            { speed: 0.8, pos: [-10.90, 0, -0.21] }, // 9
            { speed: 0.8, pos: [-11.24, 0, 2.24] }, // 10
            { speed: 0.8, pos: [-10.52, 0, 3.63] }, // 11
            { speed: 0.8, pos: [-9.25, 0, 2.42] }, // 12
            { speed: 0.8, pos: [-2.81, 0, 4.10] }, // 13
            { speed: 0.8, pos: [1.53, 0, 4.50] }, // 14
            { speed: 0.8, pos: [5.91, 0, 3.93] }, // 15
            { speed: 0.8, pos: [7.30, 0, 3.08] }, // 16
            { speed: 0.8, pos: [7.78, 0, 1.17] }, // 17
            
        ],
        stable: [
            { speed: 1.2, pos: [-3.19, 0, 4.66] }, // 0
            { speed: 1.2, pos: [0.29, 0, 4.68] }, // 1
            { speed: 1.2, pos: [5.63, 0, 4.47] }, // 2
            { speed: 1.2, pos: [6.28, 0, -5.54] }, // 3
            { speed: 1.2, pos: [-5.95, 0, -6.25] }, // 4
            { speed: 1.2, pos: [-5.78, 0, -1.52] }, // 5
            { speed: 1.2, pos: [-5.43, 0, 3.51] }, // 6
            
            
        ],
        competitionBarel: [
            { speed: 9, pos: [0.00, 0, -19.22] }, // 0
            { speed: 14, pos: [0.51, 0, -6.53] }, // 1
            { speed: 10, pos: [0.00, 0, -4.66] }, // 2
            { speed: 15, pos: [-3.56, 0, 3.07] }, // 3
            { speed: 10, pos: [-5.77, 0, 4.71] }, // 4
            { speed: 9, pos: [-8.99, 0, 4.57] }, // 5
            { speed: 10, pos: [-9.88, 0, 1.95], evt: "barrel" }, // 6
            { speed: 12, pos: [-8.47, 0, 0.48] }, // 7
            { speed: 12, pos: [-5.69, 0, 0.71] }, // 8
            { speed: 10, pos: [6.70, 0, 5.13] }, // 9
            { speed: 10, pos: [8.65, 0, 4.84] }, // 10
            { speed: 10, pos: [9.75, 0, 1.79], evt: "barrel" }, // 11
            { speed: 10, pos: [8.98, 0, 0.74] }, // 12
            { speed: 10, pos: [6.97, 0, 0.82] }, // 13
            { speed: 10, pos: [4.75, 0, 1.72] }, // 14
            { speed: 14, pos: [2.43, 0, 2.85] }, // 15
            { speed: 16, pos: [0.71, 0, 4.50] }, // 16
            { speed: 14, pos: [-1.56, 0, 6.91] }, // 17
            { speed: 14, pos: [-2.19, 0, 9.46]  }, // 18
            { speed: 10, pos: [-2.15, 0, 13.21], evt: "done" }, // 19
            { speed: 7, pos: [-1.91, 0, 15.50]}, // 20
            { speed: 10, pos: [0.19, 0, 16.31] }, // 21
            { speed: 14, pos: [1.50, 0, 14.51] }, // 22
            { speed: 14, pos: [0.44, 0, -18.35]  }, // 23
            { speed: 4, pos: [2.51, 0, -30.87] }, // 24
            { speed: 4, pos: [0.00, 0, -27.00] }, // 25
        ],
        competitionBleachers: [
            { speed: 1.5, pos: [14.54, 2.1, -13.73] }, // 0
            { speed: 1.5, pos: [14.29, 2.1, 21.78] }, // 1
            { speed: 1.5, pos: [-14.83, 2.1, 22.12] }, // 2
            { speed: 1.5, pos: [-14.67, 2.1, -12.41] }, // 3
            { speed: 1.5, pos: [-18.38, 0, -12.64] }, // 4
            { speed: 1.5, pos: [-21.36, 0, -12.68] }, // 5
            { speed: 1.5, pos: [-20.90, 0, -27.32] }, // 6
            { speed: 1.5, pos: [-10.03, 0, -31.13] }, // 7
            { speed: 1.5, pos: [-19.46, 0, -27.60] }, // 8
            { speed: 1.5, pos: [-19.88, 0, -12.15] }, // 9
            { speed: 1.5, pos: [-18.38, 2.1, -12.64] }, // 4
            { speed: 1.5, pos: [-14.67, 2.1, -12.41] }, // 3
            { speed: 1.5, pos: [-14.83, 2.1, 22.12] }, // 2
            { speed: 1.5, pos: [14.29, 2.1, 21.78] }, // 1
        ]
    }
}