import { MdMenu } from "react-icons/md";
import useMenuState from "../stores/useMenuState";
import CommonButton from "./CommonButton";

export default function MenuButton() {

    const toggleMenu = useMenuState(s => s.toggle);

    return (
        <div className="lg:hidden">
            <CommonButton onClick={toggleMenu}>Menu</CommonButton>
            {/* <button className="
                px-4 
                py-2 
                transition-all 
                duration-200 
                active:scale-105 
                select-none 
                highlight-transparent 
                shadow-xl 
                rounded-full 
                border-stone-500 
                bg-white"
                onClick={e => toggleMenu()}>Menu</button> */}
        </div>
    );
}