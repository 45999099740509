import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export default create(subscribeWithSelector((set: Function) => {
    return {
        animationsSync: {},

        syncAnimation(key: string, duration: number) {
            set((state: any) => {
                state.animationsSync[key] = Math.random() * duration;

                const tmp = state.animationsSync;
                state.animationsSync = {};
                Object.keys(tmp).forEach(k => state.animationsSync[k] = tmp[k]);

                return {};
            });
        }
    };
}));
