import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import waterFrag from "../shaders/water-frag";
import waterVert from "../shaders/water-vert";
import { GroupProps, useFrame, useThree } from "@react-three/fiber";
import { assets } from "./Assets";
import useDarkMode from "../stores/useDarkMode";
import useOcclusionStore from "../stores/useOcclusionStore";
import { Mesh, ShaderMaterial, Vector3, TextureLoader, Texture, sRGBEncoding, MeshBasicMaterial } from "three";
import useLoadTexture from "./UseLoadTexture";
import useFakeLight from "./UseFakeLight";
import MeshBasicMaterialLOD from "./MeshBasicMaterialLOD";

export default function Farm(props: GroupProps) {
  const { nodes } = useGLTF(assets.farm.model);
 
  const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);

  const tGround = useLoadTexture(darkModeEnabled ? assets.farm.textures.groundNight : assets.farm.textures.ground);
  const tProps = useLoadTexture(darkModeEnabled ? assets.farm.textures.propsNight : assets.farm.textures.props);
  const tFence = useLoadTexture(darkModeEnabled ? assets.farm.textures.fenceNight : assets.farm.textures.fence);
  const tFenceAlpha = useLoadTexture(assets.farm.textures.fenceAlpha);
  const tFrame = useLoadTexture(darkModeEnabled ? assets.farm.textures.frameNight : assets.farm.textures.frame);
  const tWater = useLoadTexture(assets.farm.textures.water);
  const tWaterNight = useLoadTexture(assets.farm.textures.waterNight);

  const propsMeshRef = useRef<Mesh>(null!);
  const waterShader = useRef<ShaderMaterial>(null!);

  const uniforms = useMemo(
    () => {
      let obj = THREE.UniformsLib.fog;
      obj = Object.assign(
        {
          uTime: { value: 0 },
          uMap: { value: tWater },
          uLight: { value: 1.0 }
        }, obj);
      return obj;
    },
    [tWater]
  );

  useFrame((state, delta) => {
    waterShader.current.uniforms.uTime.value += delta;
    waterShader.current.uniforms.uMap.value = darkModeEnabled ? tWaterNight : tWater;
    waterShader.current.uniforms.uLight.value = darkModeEnabled ? 0.2 : 1;
  });

  useFakeLight(new Vector3(-20.29, 0.00, -9.71), 14, "farmPaddockLight");

  const registerOcclusion = useOcclusionStore((s) => s.register);
  const unregisterOcclusion = useOcclusionStore((s) => s.unregister);

  useEffect(() => {
    registerOcclusion(propsMeshRef);

    return () => {
      unregisterOcclusion(propsMeshRef);
    };
  }, [registerOcclusion, tWater, unregisterOcclusion]);

  return (
    <>
      <group {...props} dispose={null}>
        <group name="Scene">
          <mesh
            name="Ground"
            geometry={(nodes.Ground as Mesh).geometry}
            material={(nodes.Ground as Mesh).material}
            position={[-4.68, 0, -8.71]}
          >
            <meshBasicMaterial map={tGround} />
            {/* <MeshBasicMaterialLOD textureLods={assets.farm.textureLods.ground} /> */}
          </mesh>
          <mesh
            name="Water"
            geometry={(nodes.Water as Mesh).geometry}
            material={(nodes.Water as Mesh).material}
            position={[3.2, -1.82, 12.6]}
          >
            <shaderMaterial
              fog
              ref={waterShader}
              vertexShader={waterVert}
              fragmentShader={waterFrag}
              toneMapped={false}
              uniforms={uniforms}
            />
          </mesh>
          <mesh
            name="Fence"
            geometry={(nodes.Fence as Mesh).geometry}
            material={(nodes.Fence as Mesh).material}
            position={[0.08, 0, 0]}
          >
            <meshBasicMaterial
              map={tFence}
              alphaMap={tFenceAlpha}
              transparent
              side={THREE.DoubleSide}
              depthWrite={false}
            />
          </mesh>
          <mesh
            name="Frame"
            geometry={(nodes.Frame as Mesh).geometry}
            material={(nodes.Frame as Mesh).material}
          >
            <meshBasicMaterial map={tFrame} />
          </mesh>
          <mesh
            ref={propsMeshRef}
            name="Props"
            geometry={(nodes.Props as Mesh).geometry}
            material={(nodes.Props as Mesh).material}
            position={[15.08, -0.29, 12.65]}
          >

            <meshBasicMaterial map={tProps} />
            {/* <MeshBasicMaterialLOD textureLods={assets.farm.textureLods.props} /> */}
          </mesh>
        </group>
      </group>
    </>
  );
}

useGLTF.preload(assets.farm.model);