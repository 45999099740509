import { Euler } from 'three';
import { Vector3 } from 'three';

export class Transform {
    position!: Vector3;
    lookAt!: Vector3;

    constructor(x: number, y: number, z: number, x2: number, y2: number, z2: number) {
        this.position = new Vector3(x,y,z);
        this.lookAt = new Vector3(x2, y2, z2);
    }
}