import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export enum MenuStates {
  Closed = "Closed",
  Closing = "Closing",
  Opened = "Opened",
  Opening = "Opening"
}

export default create(subscribeWithSelector((set: Function) => {

  return {
    openedClass: '',
    menuOpened: false,
    menuState: MenuStates.Closed,
    animationDelayPerNavItem: 30,

    close: () => {
      set((state: any) => {
        switch (state.menuState) {
          case MenuStates.Opening:
          case MenuStates.Opened:
            state.toggle();
            break;
        }

        return {};
      });
    },

    toggle: () => {
      set((state: any) => {
        state.menuOpened = !state.menuOpened;
        state.openedClass = state.menuOpened ? 'opened' : '';

        switch (state.menuState) {
          case MenuStates.Opening:
          case MenuStates.Opened:
            state.menuState = MenuStates.Closing;
            break;
          case MenuStates.Closing:
          case MenuStates.Closed:
            state.menuState = MenuStates.Opening;
            break;
        }

        return {};
      });
    },

    completeTransition: () => {
      set((state: any) => {
        switch (state.menuState) {
          case MenuStates.Opening:
            state.menuState = MenuStates.Opened;
            break;
          case MenuStates.Closing:
            state.menuState = MenuStates.Closed;
            break;
        }

        return {};
      });
    },

    updateClass: (html: HTMLElement, className: string, include: boolean) => {
      if (include) {
        html.classList.add(className);
      } else {
        html.classList.remove(className);
      }
    }
  };
}));