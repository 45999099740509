import create from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export default create(subscribeWithSelector((set: Function) => {
  return {
    playing: false,
    muted: false,

    play: () => {
      set((state: any) => {
        state.playing = true;
        return {};
      });
    },

    stop: () => {
      set((state: any) => {
        state.playing = false;
        return {};
      });
    },

    toggleMute: () => {
      set((state: any) => {
        state.muted = !state.muted;
        return {};
      });
    },
  };
}));
