import { useEffect } from "react";
import { assets } from "./Assets";
import Farm from "./Farm";
import Herbs from "./Herbs";
import { Horse, HorseHair } from "./Horse";
import { Human, HumanTypes } from "./Human";
import MovementCircular from "./MovementCircular";
import MovementPath from "./MovementPath";
import Tree, { TreeTypes } from "./Tree";
import useDarkMode from "../stores/useDarkMode";
import { Vector3 } from "three";
import useExploreMode from "../stores/useExploreMode";
import { Gear } from "./Gear";
import { Transform as CameraSettings } from "./Transform";
import CameraManager from "./CameraManager";
import FootSteps from "./FootSteps";
import useFootSteps from "../stores/useFootSteps";
import Grass from "./Grass";
import useDebugMode from "../stores/useDebugMode";
import useScene from "./UseScene";

export default function FarmScene() {

  useScene("/");
  // position: [-1.38, 1.42, 15.23], rotation: [-0.07, 0.16, 0.01]
  // const cameraTransform: CameraTransforms = {
  //   portrait: {
  //     position: new Vector3(21.60, 8.41, 24.37),
  //     rotation: new Euler(-0.11, 0.72, 0.07),
  //   },
  //   lg: {
  //     position: new Vector3(16.31, 3.83, 16.3),
  //     rotation: new Euler(-0.11, 0.78, 0.08),
  //   },
  //   landscape: {
  //     position: new Vector3(16.31, 3.83, 16.3),
  //     rotation: new Euler(-0.11, 0.78, 0.08),
  //   },
  // }position: [-29.16, 2.89, -17.73], rotation: [-2.98, -1.02, -3.00]

  const clearSteps = useFootSteps(s => s.clear);
  const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);
  const exploreModeEnabled = useExploreMode(s => s.exploreModeState);

  const registerlog = useDebugMode(s => s.registerLog);

  useEffect(() => {
    clearSteps();
    registerlog("Loaded Farm");
  }, [])
  // position: [15.40, 3.50, 19.61], rotation: [-0.18, 0.66, 0.11]
  return (
    <>
      <CameraManager
        original={new CameraSettings(16.31, 3.83, 16.3, 0, 3, 0)}
        explore={new CameraSettings(19, 11, 18, 0, 0, 0)}
        landscape={new CameraSettings(13.66, 2.42, 15.45, 0, 4, 0)}
        portrait={new CameraSettings(18, 3.5, 18, 0, 4, 1.5)}
        // orbitCtrl
        makeDefault
        rotateSpeed={0.25}
        minPolarAngle={Math.PI * 0.1}
        maxPolarAngle={Math.PI * 0.49}
        maxDistance={50}
        minDistance={10}
        zoomSpeed={1}
        panSpeed={1}
        maxZoom={10}
        minZoom={10}
        enableDamping
        enablePan={false}
        dampingFactor={0.05}

      />

      {/* {
        exploreModeEnabled &&
        <OrbitControls
          makeDefault
          // minAzimuthAngle={-Math.PI * 0.5}
          // maxAzimuthAngle={Math.PI * 0.5}
          minPolarAngle={Math.PI * 0.1}
          maxPolarAngle={Math.PI * 0.49}
          maxDistance={50}
          minDistance={10}
          maxZoom={10}
          minZoom={10}
          rotateSpeed={0.2}
          enableDamping
          dampingFactor={0.05}
        // enablePan={false}
        />
      } */}

      {/* )} */}
      <Farm />

      <FootSteps />
      {/* <DustParticle/> */}

      {!darkModeEnabled && <fog attach="fog" args={[0xfffded, 10, 80]} />}
      {darkModeEnabled && <fog attach="fog" args={[0x474c54, 10, 50]} />}

      <Herbs />

      {!darkModeEnabled && (
        <>
          <MovementPath idx={0} path={assets.path.farm} speed={.9} steering={2}>
            <Human
              showFork
              animTimeScale={.9}
              humanType={HumanTypes.farmer}
              animation={assets.human.animations.Walk}
              idx={0}
              scale={0.6}
              position={[0, 0.03, 0]} />
          </MovementPath>

          <Horse
            idx={0}
            position={new Vector3(2.53, 0.0, 11.02)}
            rotation={[0, .8, 0]}
            scale={.6}
            animation={assets.horse.animations.IdleEat}
            hair={HorseHair.long}
            bodyTexture={assets.horse.textures.pitaBody}
            hairTexture={assets.horse.textures.pitaHair}
          />
          <Horse
            idx={1}
            position={[8.53, 0.0, 10.02]}
            rotation={[0, -.8, 0]}
            scale={0.6}
            animation={assets.horse.animations.Idle}
            hair={HorseHair.double}
            bodyTexture={assets.horse.textures.mooglyBody}
            hairTexture={assets.horse.textures.mooglyHair}
          />
          <Horse
            idx={2}
            position={[10.53, 0.0, 9.02]}
            scale={0.6}
            animation={assets.horse.animations.IdleEat}
            hair={HorseHair.double}
            bodyTexture={assets.horse.textures.okelaBody}
            hairTexture={assets.horse.textures.okelaHair}
          />
          <Horse
            idx={4}
            position={[-11.49, 0.00, 10.50]}
            rotation={[0, 3, 0]}
            scale={0.6}
            animation={assets.horse.animations.IdleEat}
            hair={HorseHair.short}
            bodyTexture={assets.horse.textures.hopeBody}
            hairTexture={assets.horse.textures.hopeHair}
          />
        </>
      )}

      <MovementCircular
        idx={3}
        position={new Vector3(-22.01, 0, -11.74)}
        radius={2}
        anglePerSec={-12}
      >
        <Gear
          idx={3}
          animation={assets.gear.animations.WalkCycle}
          scale={0.6}
        />
        <Human
          humanType={HumanTypes.youngMan}
          animation={assets.human.animations.WalkCycle}
          scale={0.6}
          idx={3} />
        <Horse
          idx={3}
          scale={0.6}
          animation={assets.horse.animations.WalkCycle}
          bodyTexture={assets.horse.textures.pita}
          hairTexture={assets.horse.textures.okelaHair}
        />
      </MovementCircular>

      <Tree position={[-15.848, -0.237, -3.75]} scale={0.5} />
      <Tree position={[-15.939, 0, -9.4465]} scale={0.5} />
      <Tree
        position={[-15.939, 0, -13.477]}
        scale={0.5}
        rotation={[0, 53.1, 0]}
      />
      <Tree
        position={[-15.939, 0, -17.836]}
        scale={0.5}
        rotation={[0, -1.76, 0]}
      />
      <Tree
        position={[-15.939, 0, -22.031]}
        scale={0.5}
        rotation={[0, -60.7, 0]}
      />
      <Tree position={[-1.22, -1.142, 11.134]} scale={0.5} />
      <Tree position={[-0.5, -0.52, 9.57]} scale={[0.6, 0.5, 0.6]} />
      <Tree position={[6.14, 0.00, 15.6]} scale={0.5} treeTypes={TreeTypes.Pine} />
      <Tree position={[2.73, 0.00, 15.74]} scale={0.3} rotation={[0, 0.3, 0]} treeTypes={TreeTypes.Pine} />
      <Grass position={[3, 0, -25]} scale={[1, .5, 1]} />
      <Grass position={[-2, 0, -25]} scale={[1, .5, 1]} />
    </>
  );
}
