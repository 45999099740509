import { useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { BufferGeometry, ShaderMaterial, Vector3 } from "three";
import { PointsProps, useFrame, useThree } from "@react-three/fiber";
import useLoadTexture from "./UseLoadTexture";
import { assets } from "./Assets";
import { dustParticleVertexShader, dustPrticleFragmentShader } from "../shaders/dust-particle-shader";
import useDustSteps from "../stores/useDustSteps";
import useFakeLight from "./UseFakeLight";
import useLightSource from "../stores/useLightSource";
import useDarkMode from "../stores/useDarkMode";
import { dustParticleScrollFragmentShader, dustParticleScrollVertexShader } from "../shaders/dust-particle-scroll-shader";

type Props = PointsProps & {
    counter?: number;
    scrolling?: boolean;
    range?: number;
}

export default function DustParticle({ range, scrolling, counter, ...props }: Props) {

    const dustTexture = useLoadTexture(assets.horse.textures.dust);

    const buffer = useRef<BufferGeometry>(null!);
    const shader = useRef<ShaderMaterial>(null!);

    const dustIdx = useDustSteps(s => s.idx);
    const {positions, scales, lifetimes, velocities, startTimes} = useDustSteps();
    const lights = useLightSource(s => s.lights);
    const darkModeEnabled = useDarkMode(s => s.darkModeEnabled);

    useFrame(() => {
        if (shader) {
            shader.current.uniforms.uDarkMode.value = darkModeEnabled ? 1: 0;
            shader.current.uniforms.uLight.value = lights.length > 0 ? lights[0].pos: new Vector3();
        }
    })

    const uniforms = useMemo(
        () => {
          let obj = THREE.UniformsLib.fog;
          obj = Object.assign(
            {
                uTime: { value: 0 },
                uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
                uSize: { value: 100 },
                uMap: { value: dustTexture },
                uLight: {value: lights.length > 0 ? lights[0].pos: new Vector3() },
                uDarkMode: {value: darkModeEnabled ? 1 : 0},
                uRange: { value: (range || 6.5) },
                uSoftRange: { value: 0.25 },
                uColor: { value: new THREE.Color(0xffffff) },
            }, obj);
          return obj;
        },
        [dustTexture]
      );


    useFrame((s, d) => {
        if (shader) {
            shader.current.uniforms.uTime.value = s.clock.getElapsedTime();
        }
    })

    useEffect(() => {
        buffer.current.setAttribute('position', new THREE.BufferAttribute(new Float32Array(positions), 3))
        buffer.current.setAttribute('aVelocity', new THREE.BufferAttribute(new Float32Array(velocities), 3))
        buffer.current.setAttribute('aScale', new THREE.BufferAttribute(new Float32Array(scales), 1))
        buffer.current.setAttribute('aLifetime', new THREE.BufferAttribute(new Float32Array(lifetimes), 1))
        buffer.current.setAttribute('aStartime', new THREE.BufferAttribute(new Float32Array(startTimes), 1))

    }, [dustIdx])

    return (
        <>
            <points name="dust" frustumCulled={false}>
                <bufferGeometry ref={buffer} />

                {scrolling
                    ? <shaderMaterial
                        ref={shader}
                        vertexShader={dustParticleScrollVertexShader}
                        fragmentShader={dustParticleScrollFragmentShader}
                        transparent
                        depthWrite={false}
                        uniforms={uniforms}
                    />
                    : <shaderMaterial
                        fog
                        ref={shader}
                        vertexShader={dustParticleVertexShader}
                        fragmentShader={dustPrticleFragmentShader}
                        transparent
                        depthWrite={false}
                        uniforms={uniforms}
                    />
                }
            </points>
        </>
    )
}