import useDarkMode from "../stores/useDarkMode";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import CommonButton from "./CommonButton";

export default function DarkModeSwitch() {
  const toggleDarkMode = useDarkMode((s) => s.toggle);
  const darkModeEnabled = useDarkMode((s) => s.darkModeEnabled);

  return (
    <>
      <CommonButton onClick={toggleDarkMode} active={false}>
        {!darkModeEnabled && <MdDarkMode className="text-2xl" />}
        {darkModeEnabled && <MdLightMode className="text-2xl" />}
      </CommonButton>
    </>
  );
}
